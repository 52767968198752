import { setGetBalanceCorrectionAction } from '../action';
import { getBalanceCorrection } from '../api/getBalanceCorrection';

export const setGetBalanceCorrection = (params, callback) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await getBalanceCorrection(params);
            dispatch(setGetBalanceCorrectionAction({
                data: data.data,
                currentPage: data?.pagination?.currentPage,
                currentRowsPerpage: data?.pagination?.currentRowsPerPage,
                total: data?.pagination?.total,
                totalPage: data?.pagination?.totalPage
            }))

            if (callback) {
                callback();
            }
            
          
        } catch(error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}