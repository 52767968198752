import { types } from './types';

export const setGetInviteAction = (payload) => {
  return {
    type: types.SET_GET_INVITE,
    payload,
  }
}

export const setGetInviteByIdAction = (payload) => {
  return {
    type: types.SET_GET_INVITE_BY_ID,
    payload
  }
}