import { types } from './types';

export const setGetPartnersAction = (payload) => {
  return {
    type: types.SET_GET_PARTNERS,
    payload,
  }
}

export const setGetPartnersByIdAction = (payload) => {
  return {
    type: types.SET_GET_PARTNERS_BY_ID,
    payload
  }
}