import {types} from './types';

const initialState = {
    list: {
        data: [],
        current: 0,
        total: 0
    },
    view: {
        transaction: {},
        offer: {},
        promo: {},
        user: {}
    }
}

export function transactionsReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_GET_TRANSACTIONS_LIST:
            return { ...state, list: action.payload };
        
        case types.SET_GET_TRANSACTIONS_BY_ID:
            return { ...state, view: action.payload };
        default:
            return state
    }
}