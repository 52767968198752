import { types } from './types';

export const setGetOffersAction = (payload) => {
  return {
    type: types.SET_GET_OFFERS,
    payload,
  }
}

export const setGetOffersByIdAction = (payload) => {
  return {
    type: types.SET_GET_OFFERS_BY_ID,
    payload,
  }
}

export const setGetOffersByPartnerAction = (payload) => {
  return {
    type: types.SET_GET_OFFERS_BY_PARTNER,
    payload
  }
}

export const setGetGroupOffersAction = (payload) => {
  return {
    type: types.SET_GET_GROUP_OFFERS,
    payload
  }
}