import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { format } from 'date-fns';
import { TablePagination } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {isFunction} from 'lodash';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  root: {
    '&::-webkit-scrollbar': {
          width: 1,
      },

      '&::-webkit-scrollbar-track': {
          background: '#cacaca',
          border: '7px solid transparent',
          backgroundClip: 'content-box',
      },

      '&::-webkit-scrollbar-thumb': {
          background: '#e5e5e5',
          border: '0px solid #e5e5e5',
          borderRadius: '3px',
      },
      '&::-webkit-scrollbar-thumb:hover': {
          background: 'grey',
          borderColor: 'grey',
      },
  }
});

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: 'rgb(20, 27, 51)',

    },
    '&:nth-of-type(even)': {
      backgroundColor: 'rgb(27, 32, 57)',
    },
  },
}))(TableRow);

// const useStyles = makeStyles(() => ({
//   root: {
//       height: 467,
//       margin: '20px 12px 10px 20px',
//       overflowY: 'scroll',
//       background: '#fff',
      
//   }
// }))

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#1b2039',
    color: theme.palette.common.white,
    border: 'none'
  },
  body: {
    fontSize: 14,
    border: 'none'
  },
}))(TableCell);

const TableCustom = ({
  columns,
  data,
  handleEventDoubleClick,
  totalPage,
  ...props
}) => {
  const classes = useStyles();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    if (isFunction(props?.handleChangePage)) {
      props.handleChangePage({
        page: newPage,
        rowsPerPage
      })
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    if (isFunction(props?.handleChangeRowsPerPage)) {
      props.handleChangeRowsPerPage(parseInt(event.target.value, 10))
    }
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <TableContainer className={classes.root}> 
        <Table className={classes.table} size="small" aria-label="a dense table">
          <TableHead>
            <StyledTableRow >
              {
                columns.map((item) => (
                  <StyledTableCell align={item.align} >{item.label}</StyledTableCell>
                ))
              }
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {
              data.map((item) => (
                <StyledTableRow key={item.name} onDoubleClick={() => isFunction(handleEventDoubleClick) && handleEventDoubleClick(item)}>
                  {
                    columns.map((data) => {
        
                      return (
                        <StyledTableCell align={data.align} style={{ color: '#f3f3f4', width: 150, minWidth: 150 }}>{data?.selector ? data.selector(data?.multiple ? item : item[data.name]) : data?.multiple ? item : item[data.name]}</StyledTableCell>
                      )
                    })
                  }
                </StyledTableRow>
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 100, 500]}
        component="div"
        count={totalPage || data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        style={{ color: '#f3f3f4' }}
      />
    </>
  )
}

export default TableCustom;