import { combineReducers } from 'redux'
import { authReducer } from '../authorization/reducer';
import { roleReducer } from '../roles/reducer';
import { operatorReducer } from '../operators/reducer';
import { partnersReducer } from '../partners/reducer';
import { playersReducer } from '../players/reducer';
import { brandsReducer } from '../brands/reducer';
import { landingsReducer } from '../landings/reducer';
import { paymentMethodReducer } from '../payment-methods/reducer';
import { campaignReducer } from '../campaign/reducer';
import { promoReducer } from '../promo/reducer';
import { bonusCodeReducer } from '../bonuscode/reducer';
import { mediaReducer } from '../media/reducer';
import { inviteReducer } from '../invite/reducer';
import { eventsReducer } from '../events/reducer';
import { offersReducer } from '../offers/reducer';
import { transactionsReducer } from '../transaction/reducer';
import { paymentsReducer } from '../payments/reducer';
import { balanceCorrectionReducer } from '../balance-correction/reducer';
import { exchangeRatesReducer } from '../exchangeRates/reducer';
import { postbackReducer } from '../postback/reducer';
import { withdrawalsReducer } from '../withdrawals/reducer';
import { dashboardReducer } from '../dashboard/reducer';

export const rootReducer = (history) => {
  return combineReducers({
    auth: authReducer,
    role: roleReducer,
    operator: operatorReducer,
    partners: partnersReducer,
    players: playersReducer,
    brands: brandsReducer,
    landings: landingsReducer,
    payment_method: paymentMethodReducer,
    campaign: campaignReducer,
    promo: promoReducer,
    bonuscode: bonusCodeReducer,
    media: mediaReducer,
    invite: inviteReducer,
    events: eventsReducer,
    offers: offersReducer,
    transaction: transactionsReducer,
    payments: paymentsReducer,
    balanceCorrection: balanceCorrectionReducer,
    exchangeRates: exchangeRatesReducer,
    postback: postbackReducer,
    withdrawals: withdrawalsReducer,
    dashboard: dashboardReducer
  })
}