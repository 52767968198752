import React from 'react';
import Title from '../../../../../../@casino/ui/title';
import { useRouter } from '../../../../../../@casino/useRouter';
import { setGetPaymentsById } from '../../../../../store/payments/thunk/setGetPaymentsById';
import { useDispatch, useSelector } from 'react-redux';
import Label from './block/label';
import { format } from 'date-fns';
import { setPutStatusPayments } from '../../../../../store/payments/thunk/setPutStatusPayments';

const FinancePaymentsView = () => {
    const dispatch = useDispatch();
    const router = useRouter();

    React.useEffect(() => {
        dispatch(setGetPaymentsById({
            id: router.query.id
        }))
    }, []);

    const operation = useSelector((state) => state.payments.view);

    const handleClickCanceled = () => {
        dispatch(setPutStatusPayments({
            status: 'CANCELED',
            id: operation.id
        }, () => {
            dispatch(setGetPaymentsById({
                id: operation.id
            }))
        }))
    }

    const handleClickSuccess = () => {
        dispatch(setPutStatusPayments({
            status: 'SUCCESS',
            id: operation.id
        }, () => {
            dispatch(setGetPaymentsById({
                id: operation.id
            }))
        }))
    }

    return (
        <div style={{
            padding: '0 20px'
        }}>
            <Title
                label="Просмотр платежа"
                labelBtn="Назад"
                handleClick={() => router.history.push('/finance/payments/list')}
            />
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                padding: 20,
                background: '#1b2039',
                borderRadius: 8,
            }}>
                <Label
                    label="ID"
                    description={operation?.id}
                />
                <Label
                    label="Дата создания"
                    description={format(new Date(Number(operation?.created_at) || 0), 'dd.MM.yyyy HH:mm')}
                />
                <Label
                    label="Партнер"
                    description={operation?.partner_id}
                />
                <Label
                    label="Сумма"
                    description={operation?.amount}
                />
                <Label
                    label="Валюта"
                    description={operation?.currency}
                />
                <Label
                    label="Тип операции"
                    description={operation?.operation_type}
                />
                <Label
                    label="Описание"
                    description={operation?.description}
                />
                <Label
                    label="Статус"
                    description={operation?.status}
                />
                {
                    operation?.status === 'AWAIT' && (
                        <div style={{ display: 'flex', columnGap: 12, marginTop: 20 }}>
                            <button
                                type="button"
                                onClick={handleClickCanceled}
                                style={{
                                    color: '#141b33',
                                    cursor: 'pointer',
                                    padding: '10px',
                                    borderRadius: '4px',
                                    background: '#f0f0f0',
                                    fontWeight: 700,
                                    outline: 'none',
                                    border: 'none'
                                }}
                            >
                                Отмена
                            </button>
                            <button
                                type="button"
                                onClick={handleClickSuccess}
                                style={{
                                    color: '#141b33',
                                    cursor: 'pointer',
                                    padding: '10px',
                                    background: '#ffc324',
                                    borderRadius: '4px',
                                    border: 'none',
                                    outline: 'none'
                                }}
                            >
                                Выполнить
                            </button>
                        </div>
                    )
                }
            </div>

        </div>
    )
}

export default FinancePaymentsView;