import React from 'react';
import { useRouter } from '../../../@casino/useRouter';
import { useStyles } from './helpers';
import clsx from 'clsx';
import { isArray } from 'lodash';
import { navigationArray, allOpenedCategory } from '../../settings/navigation';
import { ReactComponent as ArrowRight } from '../../../@casino/img/right-arrow.svg';
import { ReactComponent as ArrowDown } from '../../../@casino/img/down-arrow.svg';

const Navigation = ({allows}) => {
    const router = useRouter();
    const { pathname } = router;

    const classes = useStyles();

    const [openedCategory, handleSetOpenedCategory] = React.useState(allOpenedCategory);

    const handleClickEvent = ({
        path,
        field,
        children,
    }) => {
        router.history.push(path);

        if (isArray(children)) {
            if (openedCategory.includes(field)) {
                const newOpenedCategory = [...openedCategory];
                const index = openedCategory.indexOf(field)

                newOpenedCategory.splice(index, 1)
                
                handleSetOpenedCategory([...newOpenedCategory])
            } else {
                const newOpenedCategory = [...openedCategory];

                newOpenedCategory.push(field);

                handleSetOpenedCategory([...newOpenedCategory]);
            }
        }
    };

    const navigation = navigationArray(allows);

    return (
        <nav className={classes.root}>
            {
                navigation.map(({
                    field, 
                    path, 
                    label, 
                    children,
                    allow
                }) => allow && (
                    <>
                        <span
                            className={classes.group}
                            onClick={() => handleClickEvent({
                                field,
                                path,
                                children
                            })}>
                            <div 
                                className={classes.wrapGroupFields}
                            >
                                <span 
                                    className={clsx(classes.pl12, pathname === path && classes.activeColor)} 
                                >
                                    {label}
                                </span>
                                <div>
                                    {
                                        isArray(children) && Boolean(children?.length) && openedCategory.includes(field) && (
                                            <ArrowDown 
                                                className={classes.arrowDown} 
                                            />
                                        )
                                    }
                                    {
                                        isArray(children) && Boolean(children?.length) && !openedCategory.includes(field) && (
                                            <ArrowRight 
                                                className={classes.arrowDown} 
                                            />
                                        )
                                    }
                                </div>
                            </div>
                        </span>
                        {
                            isArray(children) && openedCategory.includes(field) && (
                                children?.map(({
                       
                                    path, 
                                    label,
                                    allow
                                }) => allow && (
                                    <div
                                        className={clsx(classes.group, classes.itemGroup)}
                                        onClick={() => router.history.push(path)}
                                    >
                                        <div 
                                            className={classes.wrapField}
                                        >
                                            <span 
                                                className={clsx(classes.pl12, pathname === path && classes.activeColor)}
                                            >
                                                {label}
                                            </span>
                                        </div>
                                    </div>
                                ))
                            )
                        }
                    </>
                ))
            }
        </nav>
    )
}

export default Navigation;