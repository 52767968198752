import { types } from './types';

export const setGetBrandsAction = (payload) => {
  return {
    type: types.SET_GET_BRANDS,
    payload,
  }
}

export const setGetBrandsByIdAction = (payload) => {
  return {
    type: types.SET_GET_BRANDS_BY_ID,
    payload
  }
}