import React from 'react';
import Title from '../../../../../../@casino/ui/title';
import { format } from 'date-fns';
import { useRouter } from '../../../../../../@casino/useRouter';
import Table from '../../../../table';
import { useDispatch, useSelector } from 'react-redux';
import { setGetInvite } from '../../../../../store/invite/thunk/setGetInvite';

const AffiliateInviteList = () => {
  const dispatch = useDispatch();
  const router = useRouter();

  React.useEffect(() => {
    dispatch(setGetInvite());
  }, []);

  const invite = useSelector((state) => state.invite.list);

  const columns = [{
    label: 'Дата создания',
    name: 'created_at',
    selector: (value) => format(new Date(Number(value)), 'dd.MM.yyyy HH:mm')
  }, {
    label: 'Код',
    name: 'code',
    align: 'left',
  }, {
    label: 'Кол-во визитов',
    name: 'count_visits',
    align: 'left',
  }, {
    label: 'Кол-во регистраций',
    name: 'count_registration',
    align: 'left',
  }, {
    label: 'URL',
    name: 'url',
    align: 'left',
  }]

  return (
    <div style={{
      padding: '0 20px'
    }}>
      <Title 
        label="Инвайты"
        labelBtn="Добавить"
        handleClick={() => router.history.push('/affiliate/invite_affilates/create')}       
      />
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        padding: 20,
        background: '#1b2039',
        borderRadius: 8,
      }}>
        <Table
          columns={columns}
          data={invite.data}
          handleEventDoubleClick={(v) => {
            router.history.push(`/affiliate/invite_affilates/view/${v.id}`)
          }}
        />
      </div>
    </div>
  )
}

export default AffiliateInviteList;