import React from 'react';
import Title from '../../../../../../@casino/ui/title';
import { useForm } from "react-hook-form";
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from '../../../../../../@casino/useRouter';
import { setGetBrands } from '../../../../../store/brands/thunk/setGetBrands';
import { setPostLandings } from '../../../../../store/landings/thunk/setPostLandings';

const useStyles = makeStyles(() => ({
  helper: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    position: 'relative',
    display: 'inline-block',
    fontSize: '11px',
    color: 'hsla(0,0%,100%,.7)',
    verticalAlign: 'middle',
    fontFamily: "PT Sans, sans-serif",
    userSelect: 'none'
  },
  text: {
    position: 'relative',
    borderRadius: '4px',
    height: '32px',
    overflow: 'hidden',
    boxShadow: '0 1px 0 0 hsl(0deg 0% 100% / 15%), inset 0 1px 0 0 rgb(0 0 0 / 15%)',
    border: 0,
    background: '#1c2542',
    color: '#fff',
    outline: 'none',
    paddingLeft: 8,
  },
  wrapLabel: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 12,
  },
  beginBonusLabel: {
    width: '32%',
  },
}))

const AffiliateLandingCreate = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const classes = useStyles();

  React.useEffect(() => {
    dispatch(setGetBrands());
  }, [])

  const brands = useSelector((state) => state.brands.list);

  const { handleSubmit, register, control } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      name: "",
    }
  });

  const onSubmit = (store) => {
    dispatch(setPostLandings(store, () => {
      router.history.push('/affiliate/landings/list')
    }))
  }

  return (
    <div style={{
      padding: '0 20px'
    }}>
      <Title
        label="Создание лендинга"
        labelBtn="Назад"
        handleClick={() => router.history.push('/affiliate/landings/list')}
      />
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        padding: 20,
        background: '#1b2039',
        borderRadius: 8,
      }}>
        <form onSubmit={handleSubmit(onSubmit)} style={{display: 'flex', flexDirection: 'column'}}>
          <label htmlFor="name" className={clsx(classes.wrapLabel, classes.beginBonusLabel)}>
            <span className={classes.helper}>Название</span>
            <input
              {...register('title')}
              type="text"
              id="name"
              placeholder='Введите название'
              className={classes.text}
            />
          </label>
          <label htmlFor="url" className={clsx(classes.wrapLabel, classes.beginBonusLabel)}>
            <span className={classes.helper}>Путь к изображению</span>
            <input
              {...register('image')}
              type="text"
              id="name"
              placeholder='Введите путь к изображению'
              className={classes.text}
            />
          </label>

          <label htmlFor="description" className={clsx(classes.wrapLabel, classes.beginBonusLabel)}>
            <span className={classes.helper}>URL</span>
            <input
              {...register('url')}
              type="text"
              id="name"
              placeholder='Введите URL'
              className={classes.text}
            />
          </label>

          <label htmlFor="country" className={clsx(classes.wrapLabel, classes.beginBonusLabel)}>
            <span className={classes.helper}>Валюты</span>
            <input
              {...register('country')}
              type="text"
              id="name"
              placeholder='Введите страну'
              className={classes.text}
            />
          </label>
          <select
            {...register('offer')}
            style={{ height: '34px', width: '32%', marginTop: 12 }}
            className={classes.text}
          >
            <option value="" style={{ display: 'none' }}>Выбрать оффер</option>
            <option value="bet">bet</option>
            <option value="casino">casino</option>
            <option value="partners">partners</option>
          </select>

          <select
            {...register('brand')}
            style={{ height: '34px', width: '32%', marginTop: 12  }}
            className={classes.text}
          >
            <option value="" style={{ display: 'none' }}>Выбрать бренд</option>
            {
              brands.data.map((item) => (
                <option value={item.id}>{item.name}</option>
              ))
            }
          </select>
          <div style={{ marginTop: 12 }}>
            <button
              type="submit"
              style={{
                color: '#141b33',
                cursor: 'pointer',
                padding: '10px',
                background: '#ffc324',
                borderRadius: '4px',
                border: 'none',
                outline: 'none'
              }}
            >
              Сохранить
            </button>
          </div>
          
        </form>
      </div>
    </div>
  )
}

export default AffiliateLandingCreate;