import { setGetPromoByIdAction } from '../action';
import { getPromoById } from '../api/getPromoById';

export const setGetPromoById = (params, callback) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await getPromoById(params);
            console.log(data)
            dispatch(setGetPromoByIdAction(data.data))

            if (callback) {
                callback();
            }
        } catch(error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}