import React from 'react';
import Table from '../../../../../table';
import { format } from 'date-fns';
import { useRouter } from '../../../../../../../@casino/useRouter';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';
import { setGetPostbackLogsAction } from '../../../../../../store/postback/action';
import { setGetPostbackLogs } from '../../../../../../store/postback/thunk/setGetPostbackLogs';

const PostbackPartners = ({
    partner_id
}) => {
    const router = useRouter();
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(setGetPostbackLogsAction({
            data: [],
            currentPage: 0,
            currentRowsPerpage: 10,
            total: 0,
            totalPage: 0
        }))
        dispatch(setGetPostbackLogs({
            page: 0,
            rowsPerPage: 10,
            partner_id
        }))
    }, []);

    const list = useSelector(state => state.postback.list);

    const columns = [{
        label: 'Дата отправки',
        name: 'created_at',
        selector: (value) => format(Number(value), 'dd.MM.yyyy HH:mm')
    }, {
        label: 'Действие',
        name: 'type',
        align: 'left',
    }, {
        label: 'Статус',
        name: 'status',
        align: 'left',
        selector: (value) => <Typography style={{ color: value ? '#79CF5B' : '#FF403D' }}>{value ? 'Отправлен' : 'Ошибка'}</Typography>
    }, {
        label: 'Click ID',
        name: 'click_id',
        align: 'left',
    }, {
        label: 'Партнер',
        name: 'partner_id',
        align: 'left',
        multiple: true,
        selector: (value) => <Typography
            style={{ cursor: 'pointer', textDecoration: 'underline', color: '#fff' }}
            onClick={() => router.history.push(`/partners/view/${value.partner_id}`)}>{value.partner_login}</Typography>
    }, {
        label: 'Оффер',
        name: 'offer_id',
        align: 'left',
        multiple: true,
        selector: (value) => <Typography
            style={{ cursor: 'pointer', textDecoration: 'underline', color: '#fff' }}
            onClick={() => router.history.push(`/affiliate/comission/view/${value.offer_id}`)}>{value.offer_name}</Typography>
    }, {
        label: 'Пользователь',
        name: 'platform_id',
        align: 'left',
        multiple: true,
        selector: (value) => <Typography
            style={{ cursor: 'pointer', textDecoration: 'underline', color: '#fff' }}
            onClick={() => router.history.push(`/players/view/${value.platform_id}`)}>{value.platform_id}</Typography>
    }, {
        label: 'Промо',
        name: 'promo_id',
        align: 'left',
        multiple: true,
        selector: (value) => <Typography
            style={{ cursor: 'pointer', textDecoration: 'underline', color: '#fff' }}
            onClick={() => router.history.push(`/partners/promo/view/${value.promo_id}`)}>{value.promo_label}</Typography>
    }, {
        label: 'Запрос',
        name: 'params',
        align: 'left',
      },];

    const handleChangeRowsPerPage = (rowsPerPage) => {
        dispatch(setGetPostbackLogsAction({
            data: [],
            currentPage: 0,
            currentRowsPerpage: rowsPerPage,
            total: list?.total,
            totalPage: list?.totalPage
        }))
        dispatch(setGetPostbackLogs({
            page: 0,
            rowsPerPage,
            partner_id
        }));
    }

    const handleChangePage = ({
        rowsPerPage,
        page
    }) => {
        dispatch(setGetPostbackLogsAction({
            data: [],
            currentPage: page,
            currentRowsPerpage: rowsPerPage,
            total: list?.total,
            totalPage: list?.totalPage
        }))
        dispatch(setGetPostbackLogs({
            page,
            rowsPerPage,
            partner_id
        }));
    }

    return (
        <div>
            <Table
                columns={columns}
                data={list?.data || []}
                totalPage={list?.total}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleChangePage={handleChangePage}
            />
        </div>
    )
}

export default PostbackPartners;