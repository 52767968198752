import { setGetOffersByIdAction } from '../action';
import { getOffersById } from '../api/getOffersById';

export const setGetOffersByid = (params, callback) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await getOffersById(params);
            dispatch(setGetOffersByIdAction(data.data))

            if (callback) {
                callback();
            }
        } catch(error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}