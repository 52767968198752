import { putStatusPayments } from '../api/putStatusPayments';

export const setPutStatusPayments = (params, callback) => {
    return async (dispatch, getState) => {
        try {
            await putStatusPayments(params);

            if (callback) {
                callback();
            }
        } catch(error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}