import React from 'react';
import { useFieldArray } from 'react-hook-form';
import { ReactComponent as Plus } from '../icon/plus.svg';
import { ReactComponent as Minus } from '../icon/minus.svg';
import QualifierItem from './block/qualifierItem';
import Modifier from './modifier';

const Qualifiers =  ({
    idx,
    idxRules,
    register,
    control,
}) => {

    const { fields, append, remove } = useFieldArray({
        control,
        name: `ruleGroup[${idx}].rules[${idxRules}].qualifiers`
    })

    const handleAddRules = () => {
        append({
            rule: {
                type: "",
                value: ""
            },
            modifiers: [{

            }]
        })
    }

    return (
        <div>
            <div style={{ display: 'flex', columnGap: 10, alignItems: 'center' }}>
                <span style={{ color: '#fff' }}>Квалификаторы</span>
                <Plus style={{ fill: '#fff', width: 20, height: 20, cursor: 'pointer' }} onClick={handleAddRules} />
            </div>
            {
                fields?.map((qualifier, idxQualifiers) => (
                    <div style={{marginTop: 20, paddingBottom: 10, borderBottom: '1px solid #fff', marginLeft: 20}}>
                        <div style={{display: 'flex', alignItems: 'center', columnGap: 10}}>
                            <span style={{ color: '#fff' }}>#{idxQualifiers + 1}</span>
                            <Minus 
                                style={{ fill: '#fff', width: 20, height: 20, cursor: 'pointer' }} 
                                onClick={() => remove(idxQualifiers)}
                            />
                        </div>
                        
                        <QualifierItem
                            idx={idx}
                            idxRules={idxRules}
                            idxQualifiers={idxQualifiers}
                            register={register}
                            control={control}
                        />
                        <Modifier
                            idx={idx}
                            idxRules={idxRules}
                            idxQualifiers={idxQualifiers}
                            register={register}
                            control={control}
                        /> 
                    </div>
                ))
            }
        </div>
    )
}

export default Qualifiers;