import { Typography } from '@material-ui/core';
import React from 'react';

const Label = ({
  label,
  description,
  color,
  handleClick
}) => {
  return (
    <div style={{ display: 'flex' }}>
      <div style={{ width: 200 }}>
        <Typography style={{ color: 'hsla(0,0%,100%,.5)' }}>{label}</Typography>
      </div>
      <div>
        <Typography 
          style={{ color: color || '#FFFFFF', textDecoration: handleClick && description && 'underline', cursor: handleClick && description && 'pointer' }}
          onClick={handleClick}
        >
          {description || "-"}
        </Typography>
      </div>
    </div>
  )
}

export default Label;