import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {mapRoutes as mR} from '../../settings/mapRoutes';

const RoutesAll = ({
    allows
}) => {
    const mapRoutes = mR(allows);

    return (
        <Switch>
            {
                mapRoutes.map((item, key) => item.allow && <Route key={key} exact={item.exact} path={item.path} component={item.component} />)
            }
        </Switch>
    )
}

export default RoutesAll;