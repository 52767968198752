import {types} from './types';

const initialState = {
    list: {
        data: [],
        currentPage: 0,
        currentRowsPerpage: 0,
        total: 0,
        totalPage: 0
    },
    view: {},
    partner: {
        data: [],
        currentPage: 0,
        currentRowsPerpage: 0,
        total: 0,
        totalPage: 0
    },
    group: {
        data: [],
        currentPage: 0,
        currentRowsPerpage: 0,
        total: 0,
        totalPage: 0
    }
}

export function offersReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_GET_OFFERS:
            return { ...state, list: action.payload};
        case types.SET_GET_OFFERS_BY_ID:
            return { ...state, view: action.payload};
        case types.SET_GET_OFFERS_BY_PARTNER:
            return { ...state, partner: action.payload};
        case types.SET_GET_GROUP_OFFERS:
            return { ...state, group: action.payload };
        default:
            return state
    }
}