import { postPromoStatus } from '../api/postPromoStatus';
import { setGetPromoById } from './setGetPromoById';

export const setPostPromoStatus = (params, callback) => {
    return async (dispatch, getState) => {
        try {
            await postPromoStatus(params);
            dispatch(setGetPromoById(params, callback));
        } catch(error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}