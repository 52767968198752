import { putChangeStatusMedia } from '../api/putChangeStatusMedia';
import { setGetMediaById } from './setGetMediaById';

export const setPutChangeStatusMedia = (params, callback) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await putChangeStatusMedia(params);
            
            alert('Сохранено')
            dispatch(setGetMediaById(params));
        } catch(error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}