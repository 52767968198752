import { putChangeDeleteLandings } from '../api/putChangeDeleteLandings';
import { setGetLandingsById } from './setGetLandingsById';

export const setPutChangeDeleteLandings = (params, callback) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await putChangeDeleteLandings(params);
            
            alert('Сохранено')
          
            dispatch(setGetLandingsById(params));
        } catch(error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}