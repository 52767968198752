import { postOperatorsBlock } from '../api/postOperatorsBlock';
import { setGetOperatorsById } from './setGetOperatorsById';

export const setPostOperatorsBlock = (params) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await postOperatorsBlock(params);
            dispatch(setGetOperatorsById(params))
            alert('Сохранено')
          
        } catch(error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}