import { putOfferActive } from '../api/putOfferActive';
import { setGetOffersByid } from './setGetOffersById';

export const setPutOfferActive = (params) => {
    return async (dispatch, getState) => {
        try {
            await putOfferActive(params);
            dispatch(setGetOffersByid({ id: params?.id }))
            alert('Успешно')
        } catch(error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}