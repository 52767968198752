import React from 'react';
import Title from '../../../../../@casino/ui/title';
import { useDispatch, useSelector } from 'react-redux';
import { setGetPlayersById } from '../../../../store/players/thunk/setGetPlayersById';
import { useRouter } from '../../../../../@casino/useRouter';
import { Typography } from '@material-ui/core';
import Label from './block/label';
import { format } from 'date-fns';

const Players = () => {
  const dispatch = useDispatch();
  const router = useRouter();

  React.useEffect(() => {
    dispatch(setGetPlayersById({
      id: router.query.id
    }))
  }, []);

  const player = useSelector((state) => state.players.view);

  return (
    <div style={{
      padding: '0 20px'
    }}>
      <Title 
        label="Игрок" 
        labelBtn="Назад"
        handleClick={() => router.history.push('/players/list')}
      />
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        padding: 20,
        background: '#1b2039',
        borderRadius: 8,
        rowGap: 12
      }}>
        <Typography style={{ color: 'hsla(0,0%,100%,.8)', textTransform: 'uppercase', marginBottom: 20 }}>Информация</Typography>
        <Label
          label="Статус"
          description={player?.status}
        />
        <Label
          label="Логин"
          description={player?.login}
        />
        <Label
          label="Валюта"
          description={player?.currency}
        />
        <Label
          label="ID на платформе"
          description={player?.platform_id}
        />
        <Label
          label="Дата создания"
          description={format(new Date(Number(player?.created_at || 0)), 'dd.MM.yyyy HH:mm')}
        />
        <Label
          label="Последний визит"
          description={format(new Date(Number(player?.last_visite || 0)), 'dd.MM.yyyy HH:mm')}
        />
        <Label
          label="Партнер"
          description={player?.partner_id}
        />
        <Label
          label="ГЕО"
          description={player?.geo}
        />
        <Label
          label="Количество депозитов"
          description={player?.count_deposit}
        />
        <Label
          label="Количество выводов"
          description={player?.count_withdrawals}
        />
        <Label
          label="Сумма депозитов"
          description={player?.sum_deposit}
        />
        <Label
          label="Сумма выводов"
          description={player?.sum_withdrawals}
        />
        <Label
          label="Профит"
          description={player?.profit}
        />

        <Label
          label="Stream ID"
          description={player?.stream}
        />

        <Label
          label="utm_source"
          description={player?.utm_source}
        />
        <Label
          label="utm_medium"
          description={player?.utm_medium}
        />
        <Label
          label="utm_campaign"
          description={player?.utm_campaign}
        />
        <Label
          label="utm_content"
          description={player?.utm_content}
        />
        <Label
          label="utm_term"
          description={player?.utm_term}
        />

        <Label
          label="sub_1"
          description={player?.sub_1}
        />
        <Label
          label="sub_2"
          description={player?.sub_2}
        />
        <Label
          label="sub_3"
          description={player?.sub_3}
        />
        <Label
          label="sub_4"
          description={player?.sub_4}
        />
        <Label
          label="sub_5"
          description={player?.sub_5}
        />

      </div>
    </div>
  )
}

export default Players;