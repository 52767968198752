import { types } from './types';

export const setGetRoleAction = (payload) => {
  return {
    type: types.SET_GET_ROLE,
    payload,
  }
}

export const setGetRoleByIdAction = (payload) => {
  return {
    type: types.SET_GET_ROLE_BY_ID,
    payload
  }
}