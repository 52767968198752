import React from 'react';
import Title from '../../../../../../@casino/ui/title';
import Table from '../../../../table';
import { format } from 'date-fns';
import { useRouter } from '../../../../../../@casino/useRouter';
import { useDispatch, useSelector } from 'react-redux';
import { setGetOperators } from '../../../../../store/operators/thunk/setGetOperators';
import { setGetOperatorsAction } from '../../../../../store/operators/action';

const SettingsOperators = () => {
  const router = useRouter();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(setGetOperators({
      page: 0,
      rowsPerPage: 10
    }));
  }, [])

  const operators = useSelector(state => state.operator.list);

  const columns = [{
    label: 'Дата создания',
    name: 'created_at',
    selector: (value) => format(new Date(Number(value)), 'dd.MM.yyyy')
  }, {
    label: 'Блокировка',
    name: 'is_block',
    align: 'left',
    selector: (value) => value ? <span style={{color: 'red'}}>BLOCK</span> : <span style={{color: '#2fff00'}}>ACTIVE</span>
  }, {
    label: 'Имя',
    name: 'name',
    align: 'left',
  }, {
    label: 'Логин',
    name: 'login',
    align: 'left',
  }, {
    label: 'Роль',
    name: 'role',
    align: 'left',
  }, {
    label: 'Кол-во партнеров',
    name: 'count_partners',
    align: 'left',
  }, {
    label: 'Телеграм',
    name: 'telegram',
    align: 'left',
  }, {
    label: 'Skype',
    name: 'skype',
    align: 'left',
  }]

  const handleChangeRowsPerPage = (rowsPerPage) => {
    dispatch(setGetOperatorsAction({
      data: [],
      currentPage: 0,
      currentRowsPerpage: rowsPerPage,
      total: operators?.total,
      totalPage: operators?.totalPage
    }))
    dispatch(setGetOperators({
      page: 0,
      rowsPerPage
    }));
  }

  const handleChangePage = ({
    rowsPerPage,
    page
  }) => {
    dispatch(setGetOperatorsAction({
      data: [],
      currentPage: page,
      currentRowsPerpage: rowsPerPage,
      total: operators?.total,
      totalPage: operators?.totalPage
    }))
    dispatch(setGetOperators({
      page,
      rowsPerPage
    }));
  }

  return (
    <div style={{
      padding: '0 20px'
    }}>
      <Title 
        label="Операторы"
        labelBtn="Добавить"
        handleClick={() => router.history.push('/settings/operators/create')} 
      />
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        padding: 20,
        background: '#1b2039',
        borderRadius: 8,
      }}>
        <Table 
          columns={columns}
          data={operators.data}
          handleEventDoubleClick={(v) => {
            router.history.push(`/settings/operators/view/${v.id}`)
          }}
          totalPage={operators?.total}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleChangePage={handleChangePage}
        />
      </div>
    </div>
  )
}

export default SettingsOperators;