import React from 'react';
import Title from '../../../../../../@casino/ui/title';
import Table from '../../../../table';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from '../../../../../../@casino/useRouter';
import { setGetPaymentsMethod } from '../../../../../store/payment-methods/thunk/getPaymentsMethod';
import { format } from 'date-fns';
import { Typography } from '@material-ui/core';
import { setGetPaymentsMethodAction } from '../../../../../store/payment-methods/action';
import Tab from '../components/Tab';

const FinancePaymentsMethods = () => {
  const dispatch = useDispatch();
  const router = useRouter();

  const [tab, setTab] = React.useState('AWAIT');

  React.useEffect(() => {
    dispatch(setGetPaymentsMethod({
      status: tab,
      page: 0,
      rowsPerPage: 10
    }));
  }, [tab])

  const payments_methods = useSelector((state) => state.payment_method.list);

  const handleGetStatus = (value) => {
    const status = value?.toString()?.toLowerCase();
    switch(status) {
      case "verified": return "Подтверждено";
      case "canceled": return "Отменено";
      case "await": return "Ожидает";
    }
  }

  const columns = [{
    label: 'Дата создания',
    name: 'created_at',
    selector: (value) => format(new Date(Number(value)), 'dd.MM.yyyy')
  }, {
    label: 'Партнёр',
    name: 'user_id',
    align: 'left',
    multiple: true,
    selector: (value) => <Typography
      style={{ cursor: 'pointer', textDecoration: 'underline', color: '#fff' }}
      onClick={() => router.history.push(`/partners/view/${value.user_id}`)}>{value.login}</Typography> 
  }, {
    label: 'Платежная система',
    name: 'payment_system',
    align: 'left',
  }, {
    label: 'Кошелёк',
    name: 'wallet',
    align: 'left',
  }, {
    label: 'Статус',
    name: 'status',
    align: 'left',
    selector: handleGetStatus
  }];

  const handleChangeStatus = (event) => {
    const status = event.currentTarget.getAttribute('data-status');

    dispatch(setGetPaymentsMethodAction({
      data: [],
      currentPage: 0,
      currentRowsPerpage: 10,
      total: 0,
      totalPage: 0
    }))
    setTab(status);
  }

  return (
    <div style={{
      padding: '0 20px'
    }}>
      <Title label="Платежные методы" />
      <div style={{ display: 'flex', columnGap: 12, marginBottom: 12 }}>
        <Tab
          tab={tab}
          handleChangeStatus={handleChangeStatus}
          label="Ожидание"
          value="AWAIT"
        />
        <Tab
          tab={tab}
          handleChangeStatus={handleChangeStatus}
          label="Подтвержденные"
          value="VERIFIED"
        />
        <Tab
          tab={tab}
          handleChangeStatus={handleChangeStatus}
          label="Отмененные"
          value="CANCELED"
        />
      </div>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        padding: 20,
        background: '#1b2039',
        borderRadius: 8,
      }}>
        <Table
          columns={columns}
          data={payments_methods.data}
          handleEventDoubleClick={(v) => {
            router.history.push(`/finance/payments_methods/view/${v.id}`)
          }}
          totalPage={payments_methods?.total}
          handleChangeRowsPerPage={(rowsPerPage) => {
            dispatch(setGetPaymentsMethodAction({
              data: [],
              currentPage: 0,
              currentRowsPerpage: rowsPerPage,
              total: payments_methods?.total,
              totalPage: payments_methods?.totalPage
            }))
            dispatch(setGetPaymentsMethod({
              page: 0,
              rowsPerPage,
              status: tab,
            }));
          }}
          handleChangePage={({
            rowsPerPage,
            page
          }) => {
            dispatch(setGetPaymentsMethodAction({
              data: [],
              currentPage: page,
              currentRowsPerpage: rowsPerPage,
              total: payments_methods?.total,
              totalPage: payments_methods?.totalPage
            }))
            dispatch(setGetPaymentsMethod({
              page,
              rowsPerPage,
              status: tab,
            }));
          }}
        />
      </div>
    </div>
  )
}

export default FinancePaymentsMethods;