import React from 'react';
import Title from '../../../../../../@casino/ui/title';
import Label from './block/label';
import { useDispatch, useSelector } from 'react-redux';
import { setGetTransactionById } from '../../../../../store/transaction/thunk/setGetTransactionById';
import { useRouter } from '../../../../../../@casino/useRouter';
import { format } from 'date-fns';
import { setPutStatusTransactionById } from '../../../../../store/transaction/thunk/setPutStatusTransactionById';
import { setGetWithdrawalsById } from '../../../../../store/withdrawals/thunk/setGetWithdrawalsById';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import { useForm, useWatch } from 'react-hook-form';
import { setPutWithdrawalsStatus } from '../../../../../store/withdrawals/thunk/setPutWithdrawalsStatus';

const useStyles = makeStyles(() => ({
  helper: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    position: 'relative',
    display: 'inline-block',
    fontSize: '11px',
    color: 'hsla(0,0%,100%,.7)',
    verticalAlign: 'middle',
    fontFamily: "PT Sans, sans-serif",
    userSelect: 'none'
  },
  text: {
    position: 'relative',
    borderRadius: '4px',
    height: '32px',
    overflow: 'hidden',
    boxShadow: '0 1px 0 0 hsl(0deg 0% 100% / 15%), inset 0 1px 0 0 rgb(0 0 0 / 15%)',
    border: 0,
    background: '#1c2542',
    color: '#fff',
    outline: 'none',
    paddingLeft: 8,
  },
  wrapLabel: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 12,
    marginBottom: 24,
  },
  beginBonusLabel: {
    width: '100%',
  },
}))

const FinanceBillsView = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const classes = useStyles();

  React.useEffect(() => {
    dispatch(setGetWithdrawalsById({
      id: router.query.id
    }))
  }, [])

  const withdrawals = useSelector((state) => state.withdrawals.view);

  const { handleSubmit, register, control, reset } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {}
  });

  const currency_list = [{ name: 'EUR', value: 'EUR' }, { name: 'USD', value: 'USD' }, { name: "RUB", value: 'RUB' }, { name: "KZT", value: "KZT" }];

  const onSubmit = (store) => {
    store.id = withdrawals.id;
    dispatch(setPutWithdrawalsStatus(store))
  }

  const currency = useWatch({
    control,
    name: 'currency'
  })

  return (
    <div style={{
      padding: '0 20px'
    }}>
      <Title
        label="Просмотр вывода"
        labelBtn="Назад"
        handleClick={() => router.history.push('/finance/bills/list')}
      />
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        padding: 20,
        background: '#1b2039',
        borderRadius: 8,
      }}>
        <Label
          label="Дата создания"
          description={withdrawals?.created_at ? format(new Date(Number(withdrawals.created_at)), 'dd.MM.yyyy HH:mm') : '-'}
        />

        <Label
          label="Сумма к выводу"
          description={`${withdrawals?.sum} ${withdrawals?.currency}`}
        />
        <Label
          label="Обмен валюты"
          description={withdrawals?.spread && withdrawals?.spread_currency ? `${withdrawals?.spread} ${withdrawals?.spread_currency}` : '-'}
        />

        <Label
          label="Партнер"
          description={withdrawals?.login}
          handleClick={() => router.history.push(`/partners/view/${withdrawals?.partner_id}`)}
        />

        <Label
          label="Кошелек"
          description={withdrawals?.wallet}
        />
        <Label
          label="Статус"
          description={withdrawals?.status}
        />
        {
          withdrawals?.status?.toLowerCase() === 'await' && (
            <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'flex', alignItems: 'center', columnGap: 20 }}>
              <label htmlFor="currency" className={clsx(classes.wrapLabel, classes.beginBonusLabel)} style={{ width: 200 }}>
                <span className={classes.helper}>Валюта</span>
                <select
                  {...register('currency')}
                  style={{ height: '34px', width: '100%' }}
                  className={classes.text}
                >
                  <option value="" style={{ display: 'none' }}>Выбрать валюту</option>
                  {
                    currency_list.map((item) => <option value={item.value}>{item.name}</option>)
                  }
                </select>
              </label>
              <div>
                <button
                  type="submit"
                  style={{
                    color: '#141b33',
                    cursor: 'pointer',
                    padding: '10px',
                    background: '#ffc324',
                    borderRadius: '4px',
                    border: 'none',
                    outline: 'none'
                  }}
                  disabled={!currency}
                >
                  Выплатить
                </button>
              </div>
            </form>
          )
        }

      </div>
    </div>
  )
}

export default FinanceBillsView;