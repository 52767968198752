import { types } from './types';

export const setGetWithdrawalsListAction = (payload) => {
  return {
    type: types.SET_GET_WITHDRAWALS_LIST,
    payload,
  }
}

export const setGetWithdrawalsByIdAction = (payload) => {
  return {
    type: types.SET_GET_WITHDRAWALS_BY_ID,
    payload
  }
}