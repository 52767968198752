import React from 'react';
import Title from '../../../../../../@casino/ui/title';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from '../../../../../../@casino/useRouter';
import Table from '../../../../table';
import { setGetBonusCode } from '../../../../../store/bonuscode/thunk/setGetBonusCode';
import { Typography } from '@material-ui/core';
import { setGetBonusCodeAction } from '../../../../../store/bonuscode/action';

const PartnersBonusCode = () => {
  const dispatch = useDispatch();
  const router = useRouter();

  React.useEffect(() => {
    dispatch(setGetBonusCode({
      page: 0,
      rowsPerPage: 10
    }))
  }, []);

  const bonus_code = useSelector((state) => state.bonuscode.list);

  const columns = [{
    label: 'Дата создания',
    name: 'created_at',
    selector: (value) => format(new Date(Number(value)), 'dd.MM.yyyy')
  }, {
    label: 'Бонус код',
    name: 'bonus_code',
    align: 'left',
  }, {
    label: 'Промокод',
    name: 'promocode',
    align: 'left',
  }, {
    label: 'Партнер',
    name: 'partner_id',
    align: 'left',
    multiple: true,
    selector: (value) => (
      <Typography
        style={{ cursor: 'pointer', textDecoration: 'underline', color: '#fff' }}
        onClick={() => router.history.push(`/partners/view/${value.partner_id}`)}
      >
        {value.login}
      </Typography>
    )
  }, {
    label: 'Кол-во игроков',
    name: 'players_count',
    align: 'left',
  }, {
    label: 'Статус',
    name: 'is_active',
    align: 'left',
    selector: (value) => value ? 'Активна' : 'Неактивна' 
  }];
  
  const handleChangeRowsPerPage = (rowsPerPage) => {
    dispatch(setGetBonusCodeAction({
      data: [],
      currentPage: 0,
      currentRowsPerpage: rowsPerPage,
      total: bonus_code?.total,
      totalPage: bonus_code?.totalPage
    }))
    dispatch(setGetBonusCode({
      page: 0,
      rowsPerPage
    }));
  }

  const handleChangePage = ({
    rowsPerPage,
    page
  }) => {
    dispatch(setGetBonusCodeAction({
      data: [],
      currentPage: page,
      currentRowsPerpage: rowsPerPage,
      total: bonus_code?.total,
      totalPage: bonus_code?.totalPage
    }))
    dispatch(setGetBonusCode({
      page,
      rowsPerPage
    }));
  }

  return (
    <div style={{
      padding: '0 20px'
    }}>
      <Title 
        label="Бонус код" 
        labelBtn="Добавить"
        handleClick={() => router.history.push('/partners/bonus-code/create')} 
      />
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        padding: 20,
        background: '#1b2039',
        borderRadius: 8,
      }}>
        <Table
          columns={columns}
          data={bonus_code.data}
          handleEventDoubleClick={(v) => {
            router.history.push(`/partners/bonus-code/view/${v.id}`)
          }}
          totalPage={bonus_code?.total}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleChangePage={handleChangePage}
        />
      </div>
    </div>
  )
}

export default PartnersBonusCode;