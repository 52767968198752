import { setGetPaymentsByIdAction } from '../action';
import { getPaymentsById } from '../api/getPaymentsById';

export const setGetPaymentsById = (params, callback) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await getPaymentsById(params);
            dispatch(setGetPaymentsByIdAction(data.data))

            if (callback) {
                callback();
            }
        } catch(error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}