import { postBonusCode } from '../api/postBonusCode';

export const setPostBonusCode = (params, callback) => {
    return async (dispatch, getState) => {
        try {
            await postBonusCode(params);
            
            alert('Сохранено')

            if (callback) {
                callback();
            }
        } catch(error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}