import React from 'react';
import Title from '../../../../../../@casino/ui/title';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useForm, useWatch } from 'react-hook-form';
import { useRouter } from '../../../../../../@casino/useRouter';
import { setGetOperatorsById } from '../../../../../store/operators/thunk/setGetOperatorsById';
import { setGetRole } from '../../../../../store/roles/thunk/setGetRole';
import { setPutOperators } from '../../../../../store/operators/thunk/setPutOperators';
import { Typography } from '@material-ui/core';
import { format } from 'date-fns';
import { setPostOperatorsBlock } from '../../../../../store/operators/thunk/setPostOperatorsBlock';

const useStyles = makeStyles(() => ({
  helper: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    position: 'relative',
    display: 'inline-block',
    fontSize: '11px',
    color: 'hsla(0,0%,100%,.7)',
    verticalAlign: 'middle',
    fontFamily: "PT Sans, sans-serif",
    userSelect: 'none'
  },
  text: {
    position: 'relative',
    borderRadius: '4px',
    height: '32px',
    overflow: 'hidden',
    boxShadow: '0 1px 0 0 hsl(0deg 0% 100% / 15%), inset 0 1px 0 0 rgb(0 0 0 / 15%)',
    border: 0,
    background: '#1c2542',
    color: '#fff',
    outline: 'none',
    paddingLeft: 8,
  },
  wrapLabel: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 12,
    marginBottom: 24,
  },
  beginBonusLabel: {
    width: '32%',
  },
}))


  // функции: просмотр действий, просмотр партнеров, смена пароля, вход в аккаунт

const View = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const router = useRouter();

  const { handleSubmit, register, control, reset } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      name: "",
    }
  });

  React.useEffect(() => {
    dispatch(setGetRole({
      page: 0,
      rowsPerPage: 1000
    }))
    dispatch(setGetOperatorsById({
      id: router.query.id
    }))
  }, [])

  const view = useSelector((state) => state.operator.view);
  
  React.useEffect(() => {
    if (view.created_at) {
      reset({
        ...view,
        created_at: format(new Date(Number(view.created_at)), 'dd.MM.yyyy')
      })
    }
   
  }, [view])

  const onSubmit = (store) => {
    dispatch(setPutOperators(store))
  }

  const roles = useSelector(state => state.role.roles);


  const login = useWatch({
    control,
    name: 'login',
  });

  const name = useWatch({
    control,
    name: 'name',
  });

  const role = useWatch({
    control,
    name: 'role',
  });

  const skype = useWatch({
    control,
    name: 'skype',
  });

  const telegram = useWatch({
    control,
    name: 'telegram',
  });

  const isValid = !login
    || !role
    || !name
    || (!skype && !telegram);

  return (
    <div style={{
      padding: '0 20px'
    }}>
      <Title
        label={`Просмотр оператора`}
        labelBtn="Назад"
        handleClick={() => router.history.push('/settings/operators/list')}
      />
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        padding: 20,
        background: '#1b2039',
        borderRadius: 8,
      }}>
        <div>
          <button type="button" onClick={() => {
            dispatch(setPostOperatorsBlock({
              id: router.query.id
            }))
          }}>{view?.is_block ? 'Разблокировать' : 'Заблокировать'}</button>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="name" className={clsx(classes.wrapLabel, classes.beginBonusLabel)}>
            <span className={classes.helper}>Дата регистрации</span>
            <input
              {...register('created_at')}
              type="text"
              id="name"
              placeholder='Введите имя'
              className={classes.text}
              disabled
            />
          </label>
          <label htmlFor="name" className={clsx(classes.wrapLabel, classes.beginBonusLabel)}>
            <span className={classes.helper}>Количество партнеров</span>
            <input
              {...register('count_partners')}
              type="text"
              id="name"
              placeholder='Введите имя'
              className={classes.text}
              disabled
            />
          </label>

          <label htmlFor="name" className={clsx(classes.wrapLabel, classes.beginBonusLabel)}>
            <span className={classes.helper}>Имя</span>
            <input
              {...register('name')}
              type="text"
              id="name"
              placeholder='Введите имя'
              className={classes.text}
            />
          </label>
          <label htmlFor="login" className={clsx(classes.wrapLabel, classes.beginBonusLabel)}>
            <span className={classes.helper}>Логин</span>
            <input
              {...register('login')}
              type="text"
              id="name"
              placeholder='Введите логин'
              className={classes.text}
              disabled
            />
          </label>

          <select
            {...register('role')}
            style={{ height: '34px', width: '32%' }}
            className={classes.text}
            disabled
          >
            <option value="" style={{ display: 'none' }}>Выбрать роль</option>
            {
              roles.data.map((item) => <option value={item.name}>{item.name}</option>)
            }
          </select>

          <label htmlFor="telegram" className={clsx(classes.wrapLabel, classes.beginBonusLabel)}>
            <span className={classes.helper}>Телеграм</span>
            <input
              {...register('telegram')}
              type="text"
              id="name"
              placeholder='Введите телеграм'
              className={classes.text}
            />
          </label>

          <label htmlFor="skype" className={clsx(classes.wrapLabel, classes.beginBonusLabel)}>
            <span className={classes.helper}>Skype</span>
            <input
              {...register('skype')}
              type="text"
              id="name"
              placeholder='Введите skype'
              className={classes.text}
            />
          </label>

          <button
            type="submit"
            style={{
              color: '#141b33',
              cursor: 'pointer',
              padding: '10px',
              background: isValid ? 'grey' : '#ffc324',
              borderRadius: '4px',
              border: 'none',
              outline: 'none'
            }}
            disabled={isValid}
          >
            Сохранить
          </button>
        </form>
      </div>
    </div>
  )
}

export default View;