import { Typography } from '@material-ui/core';
import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        columnGap: 12
    },
    wrap_item: {
        display: 'flex',
        flexDirection: 'column',
        padding: '5px 10px',
        background: '#1b2039',
        borderRadius: 8,
        cursor: 'pointer'
    },
    active_item: {
        color: '#FFFFFF',
    },
    no_active_item: {
        color: '#ACACAC'
    }
}))

const Tabs = ({
    activeTab,
    setActiveTab
}) => {
    const classes = useStyles();

    const tabs = [{
        label: "Информация",
        value: "information"
    }, {
        label: "Промо",
        value: "promo"
    }, {
        label: "Финансовые показатели",
        value: "finance"
    }, 
    // {
    //     label: "Субаффилиаты",
    //     value: "subaffiliate"
    // },
    {
        label: "Постбеки",
        value: "postback"
    },
    {
        label: "Комиссии",
        value: "comission"
    }, 
    ]

    return (
        <div className={classes.root}>
            {
                tabs.map((item) => (
                    <div
                        className={classes.wrap_item}
                        onClick={() => setActiveTab(item.value)}
                    >
                        <Typography
                            className={activeTab === item.value ? classes.active_item : classes.no_active_item}
                        >
                            {item.label}
                        </Typography>
                    </div>
                ))
            }
        </div>
    )
}

export default Tabs;