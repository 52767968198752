import { types } from './types';

const initialState = {
    list: {
        data: [],
        currentPage: 0,
        currentRowsPerpage: 0,
        total: 0,
        totalPage: 0
    },
    view: {}
}

export function partnersReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_GET_PARTNERS:
            return { ...state, list: action.payload };
        case types.SET_GET_PARTNERS_BY_ID:
            return { ...state, view: action.payload };
        default:
            return state
    }
}