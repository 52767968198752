import { types } from './types';

const initialState = {
    roles: {
        data: [],
        currentPage: 0,
        currentRowsPerpage: 0,
        total: 0,
        totalPage: 0
    },
    view: {}
}

export function roleReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_GET_ROLE:
            return { ...state, roles: action.payload };
        case types.SET_GET_ROLE_BY_ID:
            return { ...state, view: action.payload };
        default:
            return state
    }
}