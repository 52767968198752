import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
    root: {
        padding: 20, 
        background: '#1b2039', 
        margin: '12px 0', 
        display: 'flex', 
        alignItems: 'center', 
        borderRadius: 8
    },
    wrap: {
        position: 'relative',
        width: 50, 
        height: 50
    },
    slide1: {
        top: 0,
        position: 'absolute',
        height: 50,
        width: 32,
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
        background: 'rgba(255,195,37)'
    },
    slide2: {
        position: 'absolute',
        left: 1,
        borderTopLeftRadius: 4,
        width: 20,
        border: '30px solid transparent',
        borderTop: '50px solid rgba(255,195,37, 1)',
    },
    slide3: {
        position: 'absolute',
        borderTopLeftRadius: 4,
        left: 1,
        width: 30,
        border: '30px solid transparent',
        borderTop: '50px solid rgba(255,195,37, .5)',
    },
    slide4: {
        left: 1,
        position: 'absolute',
        borderTopLeftRadius: 4,
        width: 40,
        border: '30px solid transparent',
        borderTop: '50px solid rgba(255,195,37, .2)',
    },
    label: {
        margin: 0, 
        padding: 0, 
        marginLeft: 50, 
        color: '#fff',
        userSelect: 'none'
    }
}));

export {
    useStyles
}