import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useWatch } from 'react-hook-form'
import FlatFee from '../flatFee';
import FixedPerPlayer from '../fixedPerPlayer';
import CasinoNgrPercent from '../casinoNgrPercent';
import CasinoNgrPercentPerPlayer from '../casinoNgrPercentPerPlayer';
import SbNgrPercent from '../sbNgrPercent';
import SbNgrPercentPerPlayer from '../sbNgrPercentPerPlayer';
import DepositsSumPercent from '../depositsSumPercent';
import DepositsSumPercentPerPlayer from '../depositsSumPercentPerPlayer';
import EffectiveDepositsSumPercent from '../effectiveDepositsSumPercent';
import EffectiveDepositsSumPercentPerPlayer from '../effectiveDepositsSumPercentPerPlayer';
import CasinoWagerPercent from '../casinoWagerPercent';
import CasinoWagerPercentPerPlayer from '../casinoWagerPercentPerPlayer';
import SubaffiliateEarningsPercent from '../subaffiliateEarningsPercent';
import SbBetsSumPercent from '../sbBetsSumPercent';
import SbBetsSumPercentPerPlayer from '../sbBetsSumPercentPerPlayer';

const useStyles = makeStyles(() => ({
    helper: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        position: 'relative',
        display: 'inline-block',
        fontSize: '11px',
        color: 'hsla(0,0%,100%,.7)',
        verticalAlign: 'middle',
        fontFamily: "PT Sans, sans-serif",
        userSelect: 'none'
    },
    text: {
        position: 'relative',
        borderRadius: '4px',
        height: '32px',
        overflow: 'hidden',
        boxShadow: '0 1px 0 0 hsl(0deg 0% 100% / 15%), inset 0 1px 0 0 rgb(0 0 0 / 15%)',
        border: 0,
        background: '#1c2542',
        color: '#fff',
        outline: 'none',
        paddingLeft: 8,
    },
    wrapLabel: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 12,
        marginBottom: 24,
    },
    beginBonusLabel: {
        width: '100%',
    },
}))
const RewardItem = ({
    idxReward,
    idxRules,
    idx,
    control,
    register
}) => {
    const classes = useStyles();

    const rule_types = [
        { name: 'flat_fee', value: 'flat_fee' },
        { name: 'casino_ngr_percent', value: 'casino_ngr_percent' },
        { name: 'subaffiliate_earnings_percent', value: 'subaffiliate_earnings_percent' }
    ];

    const type = useWatch({
        control,
        name: `ruleGroup[${idx}].rules[${idxRules}].rewards[${idxReward}].rule.type`
    })

    return (
        <div style={{ paddingLeft: 20 }}>
            <label htmlFor="title" className={clsx(classes.wrapLabel, classes.beginBonusLabel)}>
                <span className={classes.helper}>Тип</span>
                <select
                    {...register(`ruleGroup[${idx}].rules[${idxRules}].rewards[${idxReward}].rule.type`)}
                    style={{ height: '34px', width: '100%' }}
                    className={classes.text}
                >
                    <option value="" style={{ display: 'none' }}>Выбрать условие</option>
                    {
                        rule_types.map((item) => <option value={item.name}>{item.name}</option>)
                    }
                </select>
            </label>
            {
                type === 'flat_fee' && (
                    <FlatFee
                        idx={idx}
                        idxRules={idxRules}
                        idxReward={idxReward}
                        register={register}
                    />
                )
            }
            {
                type === 'fixed_per_player' && (
                    <FixedPerPlayer
                        idx={idx}
                        idxRules={idxRules}
                        idxReward={idxReward}
                        register={register}
                    />
                )
            }
            {
                type === 'casino_ngr_percent' && (
                    <CasinoNgrPercent
                        idx={idx}
                        idxRules={idxRules}
                        idxReward={idxReward}
                        register={register}
                    />
                )
            }
            {
                type === 'casino_ngr_percent_per_player' && (
                    <CasinoNgrPercentPerPlayer
                        idx={idx}
                        idxRules={idxRules}
                        idxReward={idxReward}
                        register={register}
                    />
                )
            }
            {
                type === 'sb_ngr_percent' && (
                    <SbNgrPercent
                        idx={idx}
                        idxRules={idxRules}
                        idxReward={idxReward}
                        register={register}
                    />
                )
            }
            {
                type === 'sb_ngr_percent_per_player' && (
                    <SbNgrPercentPerPlayer
                        idx={idx}
                        idxRules={idxRules}
                        idxReward={idxReward}
                        register={register}
                    />
                )
            }
            {
                type === 'deposits_sum_percent' && (
                    <DepositsSumPercent
                        idx={idx}
                        idxRules={idxRules}
                        idxReward={idxReward}
                        register={register}
                    />
                )
            }
            {
                type === 'deposits_sum_percent_per_player' && (
                    <DepositsSumPercentPerPlayer
                        idx={idx}
                        idxRules={idxRules}
                        idxReward={idxReward}
                        register={register}
                    />
                )
            }
            {
                type === 'effective_deposits_sum_percent' && (
                    <EffectiveDepositsSumPercent
                        idx={idx}
                        idxRules={idxRules}
                        idxReward={idxReward}
                        register={register}
                    />
                )
            }
            {
                type === 'effective_deposits_sum_percent_per_player' && (
                    <EffectiveDepositsSumPercentPerPlayer
                        idx={idx}
                        idxRules={idxRules}
                        idxReward={idxReward}
                        register={register}
                    />
                )
            }
            {
                type === 'casino_wager_percent' && (
                    <CasinoWagerPercent
                        idx={idx}
                        idxRules={idxRules}
                        idxReward={idxReward}
                        register={register}
                    />
                )
            }
            {
                type === 'casino_wager_percent_per_player' && (
                    <CasinoWagerPercentPerPlayer
                        idx={idx}
                        idxRules={idxRules}
                        idxReward={idxReward}
                        register={register}
                    />
                )
            }
            {
                type === 'subaffiliate_earnings_percent' && (
                    <SubaffiliateEarningsPercent
                        idx={idx}
                        idxRules={idxRules}
                        idxReward={idxReward}
                        register={register}
                    />
                )
            }
            {
                type === 'sb_bets_sum_percent' && (
                    <SbBetsSumPercent
                        idx={idx}
                        idxRules={idxRules}
                        idxReward={idxReward}
                        register={register}
                    />
                )
            }
            {
                type === 'sb_bets_sum_percent_per_player' && (
                    <SbBetsSumPercentPerPlayer
                        idx={idx}
                        idxRules={idxRules}
                        idxReward={idxReward}
                        register={register}
                    />
                )
            }
        </div>
    )
}

export default RewardItem;