import {types} from './types';

const initialState = {
    authorize: null,
    allows: []
}

export function authReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_AUTHORIZE:
            return { ...state, authorize: action.payload};
        case types.SET_ALLOWS:
            return { ...state, allows: action.payload };
        default:
            return state
    }
}