import React from 'react';
import Title from '../../../../../../@casino/ui/title';

import { useRouter } from '../../../../../../@casino/useRouter';
import Label from '../../../finance/payments/view/block/label';
import Tabs from '../components/Tabs';
import Information from '../components/information';
import { useSelector } from 'react-redux';
import makeStyles from "@material-ui/core/styles/makeStyles";
import PromoPartners from '../components/promo';
import PostbackPartners from '../components/postback';
import Comission from '../components/comission';

const useStyles = makeStyles(() => ({
  root: {
    padding: '0 20px',
    display: 'flex',
    flexDirection: 'column',
    rowGap: 12
  },
  wrapBlock: {
    display: 'flex',
    flexDirection: 'column',
    padding: 20,
    background: '#1b2039',
    borderRadius: 8
  },
  title: {
    fontWeight: 700,
    fontSize: 24,
    color: '#fff'
  },
  wrapBtn: {
    marginTop: 12
  },
  btn: {
    color: '#141b33',
    cursor: 'pointer',
    padding: '10px',
    background: '#ffc324',
    borderRadius: '4px',
    border: 'none',
    outline: 'none'
  }
}))

const ViewPartners = () => {
  const router = useRouter();
  const classes = useStyles();

  const {
    query: {
      id
    }
  } = router;

  const [activeTab, setActiveTab] = React.useState("information");

  const view = useSelector((state) => state.partners.view);

  const handleClickBtn = () => {
    router.history.push(`/partners/correction/${view.user_id}`)
  }

  return (
    <div className={classes.root}>
      <Title
        label="Просмотр партнера"
        labelBtn="Назад"
        handleClick={() => router.history.push('/partners/list')}
      />

      <div className={classes.wrapBlock}>
        <span className={classes.title}>Финансовые показатели</span>
        <div>
          <Label
            label="Баланс"
            description={view?.balance}
          />
          <Label
            label="Баланс HOLD"
            description={view?.balance_hold}
          />
        </div>

        <div className={classes.wrapBtn}>
          <button
            type="button"
            className={classes.btn}
            onClick={handleClickBtn}
          >
            Коррекция баланса
          </button>
        </div>
      </div>
      <Tabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <div className={classes.wrapBlock}>
        {
          activeTab === 'information' && (
            <Information />
          )
        }
        {
          activeTab === 'promo' && (
            <PromoPartners partner_id={id} />
          )
        }
        {
          activeTab === 'postback' && (
            <PostbackPartners partner_id={id}/>
          )
        }
        {
          activeTab === 'comission' && (
            <Comission partner_id={id}/>
          )
        }
      </div>
    </div>
  )
}

export default ViewPartners;