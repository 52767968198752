import { postGroupOffer } from '../api/postGroupOffer';

export const setPostGroupOffer = (params, callback) => {
    return async (dispatch, getState) => {
        try {
            await postGroupOffer(params);

            if (callback) {
                callback();
            }
            alert('Сохранено');
        } catch(error) {
            alert('Ошибка');
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}