import React from 'react';
import {useStyles} from './helper';

const Title = ({label, handleClick, labelBtn, checkLabel, check}) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.wrap}>
                <div className={classes.slide1}></div>
                <div className={classes.slide2}></div>
                <div className={classes.slide3}></div>
                <div className={classes.slide4}></div>
        </div>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
            <h3 className={classes.label}>{label}</h3>
            <div style={{display: 'flex', columnGap: 12}}>
                {
                    checkLabel && 
                    <span style={{
                        color: '#141b33',
                        cursor: 'pointer',
                        padding: '10px',
                        background: '#ffc324',
                        borderRadius: '4px',
                    }}
                        onClick={() => check()}
                    >{checkLabel}</span>
                }
                {
                    labelBtn && 
                    <span style={{
                        color: '#141b33',
                        cursor: 'pointer',
                        padding: '10px',
                        background: '#ffc324',
                        borderRadius: '4px',
                    }}
                        onClick={handleClick}
                    >{labelBtn}</span>
                }
            </div>
        </div>
        
    </div>
    )
}

export default Title