import React from 'react';
import Title from '../../../../../../@casino/ui/title';
import { useDispatch, useSelector } from 'react-redux';
import { setGetPromo } from '../../../../../store/promo/thunk/setGetPromo';
import { useRouter } from '../../../../../../@casino/useRouter';
import { format } from 'date-fns';
import Table from '../../../../table';
import { Typography } from 'antd';
import { setGetPromoAction } from '../../../../../store/promo/action';

const PartnersPromoList = () => {
  const dispatch = useDispatch();
  const router = useRouter();

  const [search, setSearch] = React.useState("");

  React.useEffect(() => {
    dispatch(setGetPromo({
      page: 0,
      rowsPerPage: 10,
      stream: search,
    }));
  }, [search]);

  const promo = useSelector((state) => state.promo.list);

  const columns = [{
    label: 'Дата создания',
    name: 'created_at',
    selector: (value) => format(new Date(Number(value)), 'dd.MM.yyyy HH:mm')
  }, {
    label: 'Дата окончания',
    name: 'execute_date',
    selector: (value) => format(new Date(Number(value)), 'dd.MM.yyyy HH:mm')
  }, {
    label: 'Партнер',
    name: 'partner_id',
    align: 'left',
    multiple: true,
    selector: (value) => <Typography
      style={{ cursor: 'pointer', textDecoration: 'underline', color: '#fff' }}
      onClick={() => router.history.push(`/partners/view/${value.partner_id}`)}>{value.login}</Typography>
  }, {
    label: 'Поток',
    name: 'stream',
    align: 'left',
    multiple: true,
    selector: (value) => <Typography
      style={{ cursor: 'pointer', textDecoration: 'underline', color: '#fff' }}
      onClick={() => router.history.push(`/partners/promo/view/${value.promo_id}`)}>{value.stream}</Typography>
  }, {
    label: 'Оффер',
    name: 'offer_id',
    align: 'left',
    multiple: true,
    selector: (value) => <Typography
      style={{ cursor: 'pointer', textDecoration: 'underline', color: '#fff' }}
      onClick={() => router.history.push(`/affiliate/comission/view/${value.offer_id}`)}>{value.name}</Typography>
  }];

  const handleEventDoubleClick = (v) => {
    router.history.push(`/partners/promo/view/${v.promo_id}`)
  }
  const handleChangeRowsPerPage = (rowsPerPage) => {
    dispatch(setGetPromoAction({
      data: [],
      currentPage: promo?.currentPage,
      currentRowsPerpage: promo?.currentRowsPerPage,
      total: promo?.total,
      totalPage: promo?.totalPage
    }))

    dispatch(setGetPromo({
      page: 1,
      rowsPerPage,
      stream: search
    }));
  }

  const handleChangePage = ({
    rowsPerPage,
    page
  }) => {
    dispatch(setGetPromoAction({
      data: [],
      currentPage: promo?.currentPage,
      currentRowsPerpage: promo?.currentRowsPerPage,
      total: promo?.total,
      totalPage: promo?.totalPage
    }))
    dispatch(setGetPromo({
      page,
      rowsPerPage,
      stream: search
    }));
  }

  return (
    <div style={{
      padding: '0 20px'
    }}>
      <Title label="Промо" />
      <div style={{ 
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        background: '#1b2039',
        borderRadius: 8,
        marginBottom: 8
      }}>
        <label htmlFor="search">
          <input
            type="text"
            name="search"
            id="search"
            value={search}
            onChange={(v) => setSearch(v.target.value)}
            placeholder="Поиск промо по потоку"
            style={{
              padding: 10,
              background: '#141b33',
              paddingLeft: 10,
              border: '1px solid #141b33',
              color: '#fff',
              outline: 'none',
              margin: 10,
              width: '97.3%'
            }}
          />
        </label>
      </div>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        padding: 20,
        background: '#1b2039',
        borderRadius: 8
      }}>
        <Table
          columns={columns}
          data={promo.data}
          handleEventDoubleClick={handleEventDoubleClick}
          totalPage={promo?.total}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleChangePage={handleChangePage}
        />
      </div>
    </div>
  )
}

export default PartnersPromoList;