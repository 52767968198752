import React from 'react';
import Title from '../../../../../../@casino/ui/title';
import { useRouter } from '../../../../../../@casino/useRouter';
import { useDispatch, useSelector } from 'react-redux';
import { setGetCampaignById } from '../../../../../store/campaign/thunk/setGetCampaignById';
import Label from './block/label';
import { format } from 'date-fns';

const View = () => {
  const dispatch = useDispatch();
  const router = useRouter();

  React.useEffect(() => {
    dispatch(setGetCampaignById({
      id: router.query.id
    }))
  }, []);

  const view = useSelector((state) => state.campaign.view);

  return (
    <div style={{
      padding: '0 20px'
    }}>
      <Title
        label="Кампания партнера"
        labelBtn="Назад"
        handleClick={() => router.history.push('/partners/campaigns/list')}
      />
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        padding: 20,
        background: '#1b2039',
        borderRadius: 8,
      }}>
        <Label
          label="Наименование"
          description={view?.name}
        />
        <Label
          label="Идентификатор"
          description={view?.id}
        />
        <Label
          label="Статус"
          description={view?.is_active ? 'Активна' : 'Неактивна'}
        />
        <Label
          label="Дата создания"
          description={format(new Date(Number(view?.created_at || 0)), 'dd.MM.yyyy HH:mm')}
        />
        <Label
          label="Оффер"
          description={view?.offer_id}
        />
        <Label
          label="Партнер"
          description={view?.partner_id}
        />
        <Label
          label="Количество пользователей"
          description={view?.players_count}
        />
        <Label
          label="Количество промо"
          description={view?.promos_count}
        />
      </div>
    </div>
  )
}

export default View;