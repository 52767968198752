import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useWatch } from 'react-hook-form';
import CasinoNgr from '../casino_ngr';
import SBNgr from '../sb_ngr';
import PokerNgr from '../poker_ngr';
import DepositingPlayersCount from '../depositing_players_count';
import DepositsSum from '../deposits_sum';
import EffectiveDepositsSum from '../effective_deposits_sum';
import FirstDepositsCount from '../first_deposits_count';
import CasinoWager from '../casino_wager';
import SubAffiliateEarnings from '../subaffiliate_earnings';
import RepeatsDepositCount from '../first_deposits_count';

const useStyles = makeStyles(() => ({
    helper: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        position: 'relative',
        display: 'inline-block',
        fontSize: '11px',
        color: 'hsla(0,0%,100%,.7)',
        verticalAlign: 'middle',
        fontFamily: "PT Sans, sans-serif",
        userSelect: 'none'
    },
    text: {
        position: 'relative',
        borderRadius: '4px',
        height: '32px',
        overflow: 'hidden',
        boxShadow: '0 1px 0 0 hsl(0deg 0% 100% / 15%), inset 0 1px 0 0 rgb(0 0 0 / 15%)',
        border: 0,
        background: '#1c2542',
        color: '#fff',
        outline: 'none',
        paddingLeft: 8,
    },
    wrapLabel: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 12,
        marginBottom: 24,
    },
    beginBonusLabel: {
        width: '100%',
    },
}))
const ConditionItem = ({
    idxCondition,
    idxRules,
    idx,
    control,
    register
}) => {
    const classes = useStyles();

    const conditions = [
        { name: 'casino_ngr', value: 'casino_ngr' },
        { name: 'first_deposits_count', value: 'first_deposits_count' },
        { name: 'subaffiliate_earnings', value: 'subaffiliate_earnings' },
        { name: 'count_repeats_deposit', value: 'count_repeats_deposit'},
        
    ];

    const type = useWatch({
        control,
        name: `ruleGroup[${idx}].rules[${idxRules}].conditions[${idxCondition}].rule.type`
    })

    return (
        <div style={{ paddingLeft: 20 }}>
            <label htmlFor="title" className={clsx(classes.wrapLabel, classes.beginBonusLabel)}>
                <span className={classes.helper}>Тип</span>
                <select
                    {...register(`ruleGroup[${idx}].rules[${idxRules}].conditions[${idxCondition}].rule.type`)}
                    style={{ height: '34px', width: '100%' }}
                    className={classes.text}
                >
                    <option value="" style={{ display: 'none' }}>Выбрать условие</option>
                    {
                        conditions.map((item) => <option value={item.name}>{item.name}</option>)
                    }
                </select>
            </label>
            {
                type === 'casino_ngr' && (
                    <CasinoNgr
                        idx={idx}
                        idxRules={idxRules}
                        idxCondition={idxCondition}
                        register={register}
                    />
                )
            }
            {
                type === 'sb_ngr' && (
                    <SBNgr
                        idx={idx}
                        idxRules={idxRules}
                        idxCondition={idxCondition}
                        register={register}
                    />
                )
            }
            {
                type === 'poker_ngr' && (
                    <PokerNgr
                        idx={idx}
                        idxRules={idxRules}
                        idxCondition={idxCondition}
                        register={register}
                    />
                )
            }
            {
                type === 'depositing_players_count' && (
                    <DepositingPlayersCount
                        idx={idx}
                        idxRules={idxRules}
                        idxCondition={idxCondition}
                        register={register}
                    />
                )
            }
            {
                type === 'deposits_sum' && (
                    <DepositsSum
                        idx={idx}
                        idxRules={idxRules}
                        idxCondition={idxCondition}
                        register={register}
                    />
                )
            }
            {
                type === 'effective_deposits_sum' && (
                    <EffectiveDepositsSum
                        idx={idx}
                        idxRules={idxRules}
                        idxCondition={idxCondition}
                        register={register}
                    />
                )
            }
            {
                type === 'first_deposits_count' && (
                    <FirstDepositsCount
                        idx={idx}
                        idxRules={idxRules}
                        idxCondition={idxCondition}
                        register={register}
                    />
                )
            }
            {
                type === 'count_repeats_deposit' && (
                    <RepeatsDepositCount
                        idx={idx}
                        idxRules={idxRules}
                        idxCondition={idxCondition}
                        register={register}
                    />
                )
            }
            {
                type === 'casino_wager' && (
                    <CasinoWager
                        idx={idx}
                        idxRules={idxRules}
                        idxCondition={idxCondition}
                        register={register}
                    />
                )
            }
            {
                type === 'subaffiliate_earnings' && (
                    <SubAffiliateEarnings
                        idx={idx}
                        idxRules={idxRules}
                        idxCondition={idxCondition}
                        register={register}
                    />
                )
            }
        </div>
    )
}

export default ConditionItem;