import React from 'react';
import Title from '../../../../../@casino/ui/title';

const ReportRequested = () => {

  return (
    <div>
      <Title label="Отчет запросов" />
    </div>
  )
}

export default ReportRequested;