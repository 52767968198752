import React from 'react';
import Title from '../../../../../../@casino/ui/title';
import { useForm, useWatch } from "react-hook-form";
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useRouter } from '../../../../../../@casino/useRouter';
import { useDispatch, useSelector } from 'react-redux';
import { setGetRole } from '../../../../../store/roles/thunk/setGetRole';
import { setPostOperator } from '../../../../../store/operators/thunk/setPostOperator';

const useStyles = makeStyles(() => ({
  helper: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    position: 'relative',
    display: 'inline-block',
    fontSize: '11px',
    color: 'hsla(0,0%,100%,.7)',
    verticalAlign: 'middle',
    fontFamily: "PT Sans, sans-serif",
    userSelect: 'none'
  },
  text: {
    position: 'relative',
    borderRadius: '4px',
    height: '32px',
    overflow: 'hidden',
    boxShadow: '0 1px 0 0 hsl(0deg 0% 100% / 15%), inset 0 1px 0 0 rgb(0 0 0 / 15%)',
    border: 0,
    background: '#1c2542',
    color: '#fff',
    outline: 'none',
    paddingLeft: 8,
  },
  wrapLabel: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 12,
    marginBottom: 24,
  },
  beginBonusLabel: {
    width: '32%',
  },
}))

const OperatorCreate = () => {
  const classes = useStyles();
  const router = useRouter();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(setGetRole({
      page: 0,
      rowsPerPage: 100000
    }))
  }, [])

  const { handleSubmit, register, control } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      name: "",
    }
  });

  const onSubmit = (store) => {
    dispatch(setPostOperator(store))
  }

  const roles = useSelector(state => state.role.roles);

  // Логин, Роль, Кол-во Партнеров, Телеграм, Пароль, Подтверждение пароля, Имя, created_at, updated_at, telegram, is_blocked, skype
  // функции: просмотр действий, просмотр партнеров, смена пароля, блокировка, вход в аккаунт

  //created_at, updated_at, is_blocked

  const login = useWatch({
    control,
    name: 'login',
  });

  const name = useWatch({
    control,
    name: 'name',
  });

  const password = useWatch({
    control,
    name: 'password',
  });

  const repeat_password = useWatch({
    control,
    name: 'repeat_password',
  });

  const role = useWatch({
    control,
    name: 'role',
  });

  const skype = useWatch({
    control,
    name: 'skype',
  });

  const telegram = useWatch({
    control,
    name: 'telegram',
  });

  const isValid = !login
    || !password
    || !repeat_password
    || !role
    || !name
    || (password !== repeat_password)
    || (!skype && !telegram)

  return (
    <div style={{
      padding: '0 20px'
    }}>
      <Title
        label="Создание Оператора"
        labelBtn="Назад"
        handleClick={() => router.history.push('/settings/operators/list')}
      />

      <div style={{
        display: 'flex',
        flexDirection: 'column',
        padding: 20,
        background: '#1b2039',
        borderRadius: 8,
      }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="name" className={clsx(classes.wrapLabel, classes.beginBonusLabel)}>
            <span className={classes.helper}>Имя</span>
            <input
              {...register('name')}
              type="text"
              id="name"
              placeholder='Введите имя'
              className={classes.text}
            />
          </label>
          <label htmlFor="login" className={clsx(classes.wrapLabel, classes.beginBonusLabel)}>
            <span className={classes.helper}>Логин</span>
            <input
              {...register('login')}
              type="text"
              id="name"
              placeholder='Введите логин'
              className={classes.text}
            />
          </label>

          <div style={{ display: 'flex', columnGap: 12 }}>
            <label htmlFor="password" className={clsx(classes.wrapLabel, classes.beginBonusLabel)}>
              <span className={classes.helper}>Пароль</span>
              <input
                {...register('password')}
                type="password"
                id="name"
                placeholder='Введите пароль'
                className={classes.text}
              />
            </label>
            <label htmlFor="repeat_password" className={clsx(classes.wrapLabel, classes.beginBonusLabel)}>
              <span className={classes.helper}>Повторите пароль</span>
              <input
                {...register('repeat_password')}
                type="password"
                id="name"
                placeholder='Повторите пароль'
                className={classes.text}
              />
            </label>
          </div>

          <select
            {...register('role')}
            style={{ height: '34px', width: '32%' }}
            className={classes.text}
          >
            <option value="" style={{ display: 'none' }}>Выбрать роль</option>
            {
              roles?.data?.map((item) => <option value={item.name}>{item.name}</option>)
            }
          </select>

          <label htmlFor="telegram" className={clsx(classes.wrapLabel, classes.beginBonusLabel)}>
            <span className={classes.helper}>Телеграм</span>
            <input
              {...register('telegram')}
              type="text"
              id="name"
              placeholder='Введите телеграм'
              className={classes.text}
            />
          </label>

          <label htmlFor="skype" className={clsx(classes.wrapLabel, classes.beginBonusLabel)}>
            <span className={classes.helper}>Skype</span>
            <input
              {...register('skype')}
              type="text"
              id="name"
              placeholder='Введите skype'
              className={classes.text}
            />
          </label>

          <button
            type="submit"
            style={{
              color: '#141b33',
              cursor: 'pointer',
              padding: '10px',
              background: isValid ? 'grey' : '#ffc324',
              borderRadius: '4px',
              border: 'none',
              outline: 'none'
            }}
            disabled={isValid}
          >
            Сохранить
          </button>
        </form>
      </div>
    </div>
  )
}

export default OperatorCreate;