import React from 'react';
import Title from '../../../../../../@casino/ui/title';
import { useForm } from "react-hook-form";
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useRouter } from '../../../../../../@casino/useRouter';
import { useDispatch } from 'react-redux';
import Rules from '../rules';
import { setPostOffers } from '../../../../../store/offers/thunk/setPostOffers';

const useStyles = makeStyles(() => ({
  helper: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    position: 'relative',
    display: 'inline-block',
    fontSize: '11px',
    color: 'hsla(0,0%,100%,.7)',
    verticalAlign: 'middle',
    fontFamily: "PT Sans, sans-serif",
    userSelect: 'none'
  },
  text: {
    position: 'relative',
    borderRadius: '4px',
    height: '32px',
    overflow: 'hidden',
    boxShadow: '0 1px 0 0 hsl(0deg 0% 100% / 15%), inset 0 1px 0 0 rgb(0 0 0 / 15%)',
    border: 0,
    background: '#1c2542',
    color: '#fff',
    outline: 'none',
    paddingLeft: 8,
  },
  wrapLabel: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 12,
    marginBottom: 24,
  },
  beginBonusLabel: {
    width: '100%',
  }
}))

const AffiliateComissionCreate = () => {
  const classes = useStyles();
  const router = useRouter();
  const dispatch = useDispatch();

  const { handleSubmit, register, control } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      name: "",
      ruleGroup: []
    }
  });

  const onSubmit = (store) => {
    const params = {
      allow_subaffiliates: store?.allow_subaffiliates,
      currency: store?.currency,
      forgive_debts: store?.forgive_debts,
      forgive_debts_in_cryptocurrency_up_to: store?.forgive_debts_in_cryptocurrency_up_to,
      forgive_debts_in_fiat_currency_up_to: store?.forgive_debts_in_fiat_currency_up_to,
      name: store?.name,
      strategy: store?.strategy,
      schedule_plan: store?.schedule_plan,
      rules: JSON.stringify(store.ruleGroup),
      admin_fee: store?.admin_fee,
      strategy: store?.strategy
    }
 
    dispatch(setPostOffers(params, () => {
      router.history.push('/affiliate/comission/list')
    }))
  }

  const plan = [{ name: 'day', value: 'day' }, { name: 'week', value: 'week' }, { name: 'monthly', value: 'monthly' }];
  const currency = [{ name: 'EUR', value: 'EUR' }, { name: 'USD', value: 'USD' }, { name: "RUB", value: 'RUB' }, { name: 'UZS', value: 'UZS'}, { name: 'KZT', value: 'KZT' }, { name: 'GEL', value: 'GEL'}, { name: 'TRY', value: 'TRY' }];

  return (
    <div style={{
      padding: '0 20px'
    }}>
      <Title
        label="Создание Комиссии"
        labelBtn="Назад"
        handleClick={() => router.history.push('/affiliate/comission/list')}
      />

      <div style={{
        display: 'flex',
        flexDirection: 'column',
        padding: 20,
        background: '#1b2039',
        borderRadius: 8,
      }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="name" className={clsx(classes.wrapLabel, classes.beginBonusLabel)}>
            <span className={classes.helper}>Название</span>
            <input
              {...register('name')}
              type="text"
              id="name"
              placeholder='Введите название'
              className={classes.text}
            />
          </label>
          <label htmlFor="strategy" className={clsx(classes.wrapLabel, classes.beginBonusLabel)}>
            <span className={classes.helper}>Стратегия</span>
            <input
              {...register('strategy')}
              type="text"
              id="name"
              placeholder='Введите стратегию'
              className={classes.text}
            />
          </label>
          <label htmlFor="currency" className={clsx(classes.wrapLabel, classes.beginBonusLabel)}>
            <span className={classes.helper}>Валюта</span>
            <select
              {...register('currency')}
              style={{ height: '34px', width: '100%' }}
              className={classes.text}
            >
              <option value="" style={{ display: 'none' }}>Выбрать валюту</option>
              {
                currency.map((item) => <option value={item.value}>{item.name}</option>)
              }
            </select>
          </label>
          <label htmlFor="forgive_debts" className={clsx(classes.wrapLabel, classes.beginBonusLabel)} style={{display: 'flex', flexDirection: 'row', columnGap: 12}}>
            <span className={classes.helper}>Прощать долги</span>
            <input
              {...register(`forgive_debts`)}
              type="checkbox"
              id={`forgive_debts`}
              style={{
                appearance: 'auto',
                '-webkit-appearance': 'auto',
                width: 20
              }}
            />
          </label>
          <label htmlFor="currency" className={clsx(classes.wrapLabel, classes.beginBonusLabel)}>
            <span className={classes.helper}>Прощать долги в фиатной валюте до</span>
            <input
              {...register('forgive_debts_in_fiat_currency_up_to')}
              type="text"
              id="name"
              placeholder='Прощать долги в фиатной валюте до'
              className={classes.text}
            />
          </label>
          {/* <label htmlFor="currency" className={clsx(classes.wrapLabel, classes.beginBonusLabel)}>
            <span className={classes.helper}>Прощать долги в криптовалюте до</span>
            <input
              {...register('forgive_debts_in_cryptocurrency_up_to')}
              type="text"
              id="name"
              placeholder='Прощать долги в криптовалюте до'
              className={classes.text}
            />
          </label> */}
          <label htmlFor="currency" className={clsx(classes.wrapLabel, classes.beginBonusLabel)}>
            <span className={classes.helper}>Административный сбор %</span>
            <input
              {...register('admin_fee')}
              type="text"
              id="name"
              placeholder='Введите %'
              className={classes.text}
            />
          </label>
          <label htmlFor="schedule_plan" className={clsx(classes.wrapLabel, classes.beginBonusLabel)}>
            <span className={classes.helper}>План</span>
            <select
              {...register('schedule_plan')}
              style={{ height: '34px', width: '100%' }}
              className={classes.text}
            >
              <option value="" style={{ display: 'none' }}>Выбрать План</option>
              {
                plan.map((item) => <option value={item.value}>{item.name}</option>)
              }
            </select>
          </label>
          {/* <label htmlFor="allow_subaffiliates" className={clsx(classes.wrapLabel, classes.beginBonusLabel)} style={{display: 'flex', flexDirection: 'row', columnGap: 12}}>
            <span className={classes.helper}>Разрешение субаффилейтам</span>
            <input
              {...register(`allow_subaffiliates`)}
              type="checkbox"
              id={`allow_subaffiliates`}
              style={{
                appearance: 'auto',
                '-webkit-appearance': 'auto',
                width: 20
              }}
            />
          </label> */}
          <div style={{ marginBottom: 50 }}>
            <Rules control={control} register={register} />
          </div>
          <button
            type="submit"
            style={{
              color: '#141b33',
              cursor: 'pointer',
              padding: '10px',
              background: '#ffc324',
              borderRadius: '4px',
              border: 'none',
              outline: 'none'
            }}
          >
            Сохранить
          </button>
        </form>
      </div>
    </div>
  )
}

export default AffiliateComissionCreate;