import React from 'react';
import Title from '../../../../../../@casino/ui/title';
import { format } from 'date-fns';
import Table from '../../../../table';
import { useRouter } from '../../../../../../@casino/useRouter';
import { useDispatch, useSelector } from 'react-redux';
import { setGetCampaign } from '../../../../../store/campaign/thunk/setGetCampaign';

const PartnersCampaigns = () => {
  const router = useRouter();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(setGetCampaign());
  }, [])

  const campaign = useSelector((state) => state.campaign.list);
  
  const columns = [{
    label: 'Дата создания',
    name: 'created_at',
    selector: (value) => format(new Date(Number(value)), 'dd.MM.yyyy')
  }, {
    label: 'Наименование',
    name: 'name',
    align: 'left',
  }, {
    label: 'Партнер',
    name: 'partner_id',
    align: 'left',
  }, {
    label: 'Оффер',
    name: 'offer_id',
    align: 'left',
  }, {
    label: 'Кол-во игроков',
    name: 'players_count',
    align: 'left',
  }, {
    label: 'Кол-во промо',
    name: 'promos_count',
    align: 'left',
  }, {
    label: 'Статус',
    name: 'is_active',
    align: 'left',
    selector: (value) => value ? 'Активна' : 'Неактивна' 
  }]

  return (
    <div style={{
      padding: '0 20px'
    }}>
      <Title label="Кампании" />
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        padding: 20,
        background: '#1b2039',
        borderRadius: 8,
      }}>
        <Table
          columns={columns}
          data={campaign.data}
          handleEventDoubleClick={(v) => {
            router.history.push(`/partners/campaigns/view/${v.id}`)
          }}
        />
      </div>
    </div>
  )
}

export default PartnersCampaigns;