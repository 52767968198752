import React from 'react';
import Title from '../../../../../@casino/ui/title';
import { useDispatch, useSelector } from 'react-redux';
import { setGetExchangeRates } from '../../../../store/exchangeRates/thunk/setGetExchangeRates';
import { format } from 'date-fns';
import Table from '../../../table';
import { setGetExchangeRatesAction } from '../../../../store/exchangeRates/action';

const FinanceExchangeRates = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(setGetExchangeRates({
      page: 0,
      rowsPerPage: 10
    }));
  }, []);

  const exchangeRates = useSelector((state) => state.exchangeRates.list);

  const columns = [{
    label: 'Дата обновления',
    name: 'updated_at',
    selector: (value) => format(new Date(Number(value)), 'dd.MM.yyyy HH:mm')
  },  {
    label: 'Наименование',
    name: 'name',
    align: 'left',
  }, {
    label: 'Номинал',
    name: 'stream',
    align: 'left',
    multiple: true,
    selector: (value) => `${value?.nominal} ${value?.char_code}`
  }, {
    label: 'Курс в рублях',
    name: 'value',
    align: 'left',
    multiple: true,
    selector: (value) => `${value?.value} ₽`
  }];


  return (
    <div style={{
      padding: '0 20px'
    }}>
      <Title label="Курс валют" />
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        padding: 20,
        background: '#1b2039',
        borderRadius: 8,
      }}>
        <Table
          columns={columns}
          data={exchangeRates?.data}
          totalPage={exchangeRates?.total}
          handleChangeRowsPerPage={(rowsPerPage) => {
            dispatch(setGetExchangeRatesAction({
              data: [],
              currentPage: 0,
              currentRowsPerpage: rowsPerPage,
              total: exchangeRates?.total,
              totalPage: exchangeRates?.totalPage
            }))
            dispatch(setGetExchangeRates({
              page: 0,
              rowsPerPage
            }));
          }}
          handleChangePage={({
            rowsPerPage,
            page
          }) => {
            dispatch(setGetExchangeRatesAction({
              data: [],
              currentPage: page,
              currentRowsPerpage: rowsPerPage,
              total: exchangeRates?.total,
              totalPage: exchangeRates?.totalPage
            }))
            dispatch(setGetExchangeRates({
              page,
              rowsPerPage
            }));
          }}
        />
      </div>
    </div>
  )
}

export default FinanceExchangeRates;