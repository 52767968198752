import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { store } from './store/store';
import { Provider } from 'react-redux'
import { HashRouter } from 'react-router-dom/cjs/react-router-dom.min';

export const renderClient = () => {
    ReactDOM.render(
        <Provider store={store}>
            <HashRouter>
                <App />
            </HashRouter>
        </Provider>,
        document.getElementById('root'));
};