import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles(() => ({
    helper: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        position: 'relative',
        display: 'inline-block',
        fontSize: '11px',
        color: 'hsla(0,0%,100%,.7)',
        verticalAlign: 'middle',
        fontFamily: "PT Sans, sans-serif",
        userSelect: 'none'
    },
    wrapLabel: {
        display: 'flex',
        marginTop: 12,
        marginBottom: 24,
    },
    beginBonusLabel: {
        width: '100%',
    },
}))

const NotQualifiedPlayersOnly = ({
    register,
    idx,
    idxRules,
    idxReward,
    idxModifier
}) => {
    const classes = useStyles();

    return (
        <div>
            <label htmlFor={`ruleGroup[${idx}].rules[${idxRules}].rewards[${idxReward}].modifiers[${idxModifier}].not_qualified_players_only`} className={clsx(classes.wrapLabel, classes.beginBonusLabel)}>
                <span className={classes.helper}>Not qualified players only</span>
                <input
                  {...register(`ruleGroup[${idx}].rules[${idxRules}].rewards[${idxReward}].modifiers[${idxModifier}].not_qualified_players_only`)}
                  type="checkbox"
                  id={`ruleGroup[${idx}].rules[${idxRules}].rewards[${idxReward}].modifiers[${idxModifier}].not_qualified_players_only`}
                  style={{
                    appearance: 'auto',
                    '-webkit-appearance': 'auto',
                    width: 20
                  }}
                />
            </label>
        </div>
    )
}

export default NotQualifiedPlayersOnly;