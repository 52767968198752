import { Typography } from '@material-ui/core';
import React from 'react';
import Label from '../../view/block/label';
import { ReactComponent as ArrowRight } from '../../../../../../../@casino/img/right-arrow.svg';
import { ReactComponent as ArrowDown } from '../../../../../../../@casino/img/down-arrow.svg';

const DepositsSum = ({
    isOpenRule,
    setIsOpenRule,
    ...item
}) => {
    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center', columnGap: 12 }}>
                <Typography style={{ color: 'hsla(0,0%,100%,.8)', textTransform: 'uppercase', fontSize: 14 }}>Сумма депозитов</Typography>
                {
                    isOpenRule && <ArrowDown style={{ height: 12, width: 12, fill: '#FFF', cursor: 'pointer' }} onClick={() => setIsOpenRule(false)} />
                }
                {
                    !isOpenRule && <ArrowRight style={{ height: 12, width: 12, fill: '#FFF', cursor: 'pointer' }} onClick={() => setIsOpenRule(true)} />
                }
            </div>
            {
                isOpenRule && (
                    <div style={{
                        margin: '0 0 0 12px'
                    }}>
                        <Label
                            label="Сумма юзера"
                            description={item.rule.current}
                            color={item.rule.validate ? "#79CF5B" : "#FF403D"}
                        />
                        <Label
                            label="Сумма по комиссии"
                            description={item.rule.value}
                            color={item.rule.validate ? "#79CF5B" : "#FF403D"}
                        />
                        <Label
                            label="Статус"
                            description={item.rule.validate ? "Валидация пройдена" : "Валидация не пройдена"}
                            color={item.rule.validate ? "#79CF5B" : "#FF403D"}
                        />
                    </div>
                )
            }
        </div>
    )
}

export default DepositsSum;