import { types } from './types';

export const setGetTransactionsListAction = (payload) => {
  return {
    type: types.SET_GET_TRANSACTIONS_LIST,
    payload,
  }
}

export const setGetTransactionByIdAction = (payload) => {
  return {
    type: types.SET_GET_TRANSACTIONS_BY_ID,
    payload
  }
}