import React from 'react';
import Title from '../../../../../../@casino/ui/title';
import Table from '../../../../table';
import { useRouter } from '../../../../../../@casino/useRouter';
import { useDispatch, useSelector } from 'react-redux';
import { setGetOffer } from '../../../../../store/offers/thunk/setGetOffers';
import { format } from 'date-fns';
import { setGetOffersAction } from '../../../../../store/offers/action';

const AffiliateComissionList = () => {
  const router = useRouter();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(setGetOffer({
      page: 0,
      rowsPerPage: 10
    }));
  }, [])

  const offers = useSelector((state) => state.offers.list);

  const columns = [{
    label: 'Дата создания',
    name: 'created_at',
    selector: (value) => format(new Date(Number(value)), 'dd.MM.yyyy')
  }, {
    label: 'Наименование',
    name: 'name',
    align: 'left',
  }, {
    label: 'Стратегия',
    name: 'strategy',
    align: 'left',
  }, {
    label: 'План',
    name: 'schedule_plan',
    align: 'left',
  }, {
    label: 'Партнер',
    name: 'partner_id',
    align: 'left',
    selector: (value) => value ? value : '-'
  },
  {
    label: 'Статус',
    name: 'is_active',
    align: 'right',
    selector: (value) => value ? 'Активен' : 'В Архиве'
  }];

  return (
    <div style={{
      padding: '0 20px'
    }}>
      <Title 
        label="Комиссии"
        labelBtn="Добавить"
        handleClick={() => router.history.push('/affiliate/comission/create')} 
      />
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        padding: 20,
        background: '#1b2039',
        borderRadius: 8,
      }}>
        <Table
          columns={columns}
          data={offers.data || []}
          handleEventDoubleClick={(v) => {
            router.history.push(`/affiliate/comission/view/${v.id}`)
          }}
          totalPage={offers?.total}
          handleChangeRowsPerPage={(rowsPerPage) => {
            dispatch(setGetOffersAction({
              data: [],
              currentPage: 0,
              currentRowsPerpage: rowsPerPage,
              total: offers?.total,
              totalPage: offers?.totalPage
            }))
            dispatch(setGetOffer({
              page: 0,
              rowsPerPage
            }));
          }}
          handleChangePage={({
            rowsPerPage,
            page
          }) => {
            dispatch(setGetOffersAction({
              data: [],
              currentPage: page,
              currentRowsPerpage: rowsPerPage,
              total: offers?.total,
              totalPage: offers?.totalPage
            }))
            dispatch(setGetOffer({
              page,
              rowsPerPage
            }));
          }}
        />
      </div>
    </div>
  )
}

export default AffiliateComissionList;