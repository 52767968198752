import {types} from './types';

const initialState = {
    list: {
        data: [],
        currentPage: 0,
        currentRowsPerpage: 0,
        total: 0,
        totalPage: 0
    },
    view: {}
}

export function operatorReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_GET_OPERATORS:
            return { ...state, list: action.payload};
        case types.SET_GET_OPERATORS_BY_ID:
            return { ...state, view: action.payload};
        default:
            return state
    }
}