import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { useWatch } from 'react-hook-form';

const useStyles = makeStyles(() => ({
    helper: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        position: 'relative',
        display: 'inline-block',
        fontSize: '11px',
        color: 'hsla(0,0%,100%,.7)',
        verticalAlign: 'middle',
        fontFamily: "PT Sans, sans-serif",
        userSelect: 'none'
    },
    text: {
        position: 'relative',
        borderRadius: '4px',
        height: '32px',
        overflow: 'hidden',
        boxShadow: '0 1px 0 0 hsl(0deg 0% 100% / 15%), inset 0 1px 0 0 rgb(0 0 0 / 15%)',
        border: 0,
        background: '#1c2542',
        color: '#fff',
        outline: 'none',
        paddingLeft: 8,
    },
    wrapLabel: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 12,
        marginBottom: 24,
    },
    beginBonusLabel: {
        width: '100%',
    },
}))

const ActivityFrom = ({
    register,
    idx,
    idxRules,
    idxCondition,
    idxModifier,
    control
}) => {
    const classes = useStyles();

    const period = [
        {label: 'Day', value: 'day'},
        {label: 'Month', value: 'month'},
        {label: 'Week', value: 'week'},
        {label: 'Days count', value: 'days_count'},
    ]

    const period_ago = useWatch({
        name: `ruleGroup[${idx}].rules[${idxRules}].conditions[${idxCondition}].modifiers[${idxModifier}].period_ago`,
        control,
    })

    return (
        <div>
            <label htmlFor="title" className={clsx(classes.wrapLabel, classes.beginBonusLabel)}>
                <span className={classes.helper}>Period ago</span>
                <select
                    {...register(`ruleGroup[${idx}].rules[${idxRules}].conditions[${idxCondition}].modifiers[${idxModifier}].period_ago`)}
                    style={{ height: '34px', width: '100%' }}
                    className={classes.text}
                >
                    <option value="" style={{ display: 'none' }}>Выбрать период</option>
                    {
                        period.map((item) => <option value={item.value}>{item.label}</option>)
                    }
                </select>
            </label>
            {
                period_ago === 'days_count' && (
                    <label htmlFor={`ruleGroup[${idx}].rules[${idxRules}].conditions[${idxCondition}].modifiers[${idxModifier}].days_count`} className={clsx(classes.wrapLabel, classes.beginBonusLabel)}>
                        <span className={classes.helper}>Days count</span>
                        <input
                            {...register(`ruleGroup[${idx}].rules[${idxRules}].conditions[${idxCondition}].modifiers[${idxModifier}].days_count`)}
                            type="text"
                            id={`ruleGroup[${idx}].rules[${idxRules}].conditions[${idxCondition}].modifiers[${idxModifier}].days_count`}
                            placeholder='Введите days count'
                            className={classes.text}
                        />
                    </label>
                )
            }
        </div>
    )
}

export default ActivityFrom;