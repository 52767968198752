import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
    root: {
        background: '#18203a', 
        padding: '5px 5px 5px 5px', 
        borderRadius: 4
    },
    wrap: {
        paddingLeft: 12, 
        minHeight: '100%'
    },
    label: {
        fontWeight: 700, 
        userSelect: 'none',
        color: '#FFFFFF'
    },
    children: {
        marginTop: 8
    }
}))

const InformationBlock = ({children, label, width, lineColor, height}) => {
    const classes = useStyles();
    return (
        <div 
            className={classes.root} 
            style={{width, height: height ? height : '100%'}}
        >
            <div 
                style={{borderLeft: `2px solid ${lineColor}`}} 
                className={classes.wrap}
            >
                <span 
                    className={classes.label}
                >
                    {label}
                </span>
                <div 
                    className={classes.children}
                >
                </div>
                {children}
            </div>
        </div>
    )
}

export default InformationBlock;