import React from 'react';
import Title from '../../../../../../@casino/ui/title';
import Table from '../../../../table';
import { useRouter } from '../../../../../../@casino/useRouter';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { setGetWithdrawalsList } from '../../../../../store/withdrawals/thunk/setGetWithdrawalsList';
import { Typography } from 'antd';
import { setGetWithdrawalsListAction } from '../../../../../store/withdrawals/action';

const FinanceBillsList = () => {
  const router = useRouter();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(setGetWithdrawalsList({
      page: 0,
      rowsPerPage: 10
    }))
  }, [])

  const withdrawals = useSelector((state) => state.withdrawals.list);

  const handleGetStatusText = (status) => {
    const value = status?.toString()?.toLowerCase();

    switch (value) {
      case "await": return "Ожидание";
      case "success": return "Выплачен";
      case "canceled": return "Отменен";
    }
  }

  const handleGetStatusColor = (status) => {
    const value = status?.toString()?.toLowerCase();

    switch (value) {
      case "await": return "#F9C800";
      case "success": return "#48BB78";
      case "canceled": return "#FF403D";
    }
  }

  const columns = [{
    label: 'Дата создания',
    name: 'created_at',
    selector: (value) => format(new Date(Number(value)), 'dd.MM.yyyy')
  }, {
    label: 'Партнер',
    name: 'wallet',
    align: 'left',
    multiple: true,
    selector: (value) => (
      <Typography
        style={{ cursor: 'pointer', textDecoration: 'underline', color: '#fff' }}
        onClick={() => router.history.push(`/partners/view/${value.partner_id}`)}
      >
        {value.login}
      </Typography>
    )
  },
  {
    label: 'Кошелек',
    name: 'wallet',
    align: 'left',
  }, {
    label: 'Сумма',
    name: 'sum',
    align: 'left',
    multiple: true,
    selector: (value) => (
      <Typography style={{ color: '#FFF' }}>{value.sum} {value.currency}</Typography>
    )
  }, {
    label: 'Обмен валюты',
    name: 'spread',
    align: 'left',
    multiple: true,
    selector: (value) => (
      <Typography style={{ color: '#FFF' }}>{value.spread && value.spread_currency ? `${value.spread} ${value.spread_currency}` : '-'}</Typography>
    )
  }, {
    label: 'Статус',
    name: 'status',
    align: 'left',
    selector: (value) => (
      <div style={{ display: 'flex' }}>
        <Typography
          style={{
            background: handleGetStatusColor(value),
            padding: '5px 10px',
            borderRadius: 5,
            color: '#000',
            fontFamily: 'Work Sans, sans-serif',
            fontSize: 14,
            fontWeight: 400,
            lineHeight: '16px'
          }}
        >
        {handleGetStatusText(value)}
      </Typography>
      </div >
      
    )
  }];

return (
  <div style={{
    padding: '0 20px'
  }}>
    <Title label="Вывод средств" />
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      padding: 20,
      background: '#1b2039',
      borderRadius: 8,
    }}>
      <Table
        columns={columns}
        data={withdrawals.data || []}
        handleEventDoubleClick={(v) => {
          router.history.push(`/finance/bills/view/${v.id}`)
        }}
        totalPage={withdrawals?.total}
          handleChangeRowsPerPage={(rowsPerPage) => {
            dispatch(setGetWithdrawalsListAction({
              data: [],
              currentPage: 0,
              currentRowsPerpage: rowsPerPage,
              total: withdrawals?.total,
              totalPage: withdrawals?.totalPage
            }))
            dispatch(setGetWithdrawalsList({
              page: 0,
              rowsPerPage
            }));
          }}
          handleChangePage={({
            rowsPerPage,
            page
          }) => {
            dispatch(setGetWithdrawalsListAction({
              data: [],
              currentPage: page,
              currentRowsPerpage: rowsPerPage,
              total: withdrawals?.total,
              totalPage: withdrawals?.totalPage
            }))
            dispatch(setGetWithdrawalsList({
              page,
              rowsPerPage
            }));
          }}
      />
    </div>
  </div>
)
}

export default FinanceBillsList;