import { types } from './types';

export const setGetLandingsAction = (payload) => {
  return {
    type: types.SET_GET_LANDINGS,
    payload,
  }
}

export const setGetLandingsByIdAction = (payload) => {
  return {
    type: types.SET_GET_LANDINGS_BY_ID,
    payload
  }
}