import { setGetTransactionByIdAction } from '../action';
import { getTransactionsById } from '../api/getTransactionsById';

export const setGetTransactionById = (params, callback) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await getTransactionsById(params);

            dispatch(setGetTransactionByIdAction(data.data))

            if (callback) {
                callback();
            }
        } catch(error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}