import { setGetDashboardReplenishmentAction } from '../action';
import { getDashboardReplenishment } from '../api/getDashboardReplenishment';

export const setGetDashboardReplenishment = (params, callback) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await getDashboardReplenishment(params);
            dispatch(setGetDashboardReplenishmentAction(data.data))
        } catch(error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}