import { putChangeStatusPaymentsMethod } from '../api/putChangeStatusPaymentsMethod';
import { setGetPaymentsMethodById } from './getPaymentsMethodById';

export const setPutChangeStatusPaymentsMethod = (params, callback) => {
    return async (dispatch, getState) => {
        try {
            await putChangeStatusPaymentsMethod(params);
            alert('Сохранено');
            dispatch(setGetPaymentsMethodById({
                id: params.id
            }))
        } catch(error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}