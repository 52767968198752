import { setGetBrandsByIdAction } from '../action';
import { getBrandsById } from '../api/getBrandsById';

export const setGetBrandsById = (params, callback) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await getBrandsById(params);
            dispatch(setGetBrandsByIdAction(data.data))

            if (callback) {
                callback();
            }
        } catch(error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}