import React from 'react';

const Mobile = () => {
    return (
        <div style={{background: '#141b33', width: '100%', height: '100%'}}>
    
        </div>
    )
}

export default Mobile;