import { ConfigComponents } from './configComponents';
import { pathRoutes as Routes } from './pathRoutes';

export const mapRoutes = (allows) => [
  {
    path: Routes.PARTNERS_LIST,
    allow: allows.includes('list_partners'),
    component: ConfigComponents.PartnersList,
    exact: true
  },
  {
    path: Routes.PARTNERS_VIEW,
    allow: allows.includes('list_partners'),
    component: ConfigComponents.PartnersView,
    exact: true
  },
  {
    path: Routes.CAMPAIGNS_LIST,
    allow: allows.includes('campaign'),
    component: ConfigComponents.PartnersCampaignsList,
    exact: true
  },
  {
    path: Routes.CAMPAIGNS_VIEW,
    allow: allows.includes('campaign'),
    component: ConfigComponents.PartnersCampaignsView,
    exact: true
  },
  {
    path: Routes.PROMO_LIST,
    allow: allows.includes('promo'),
    component: ConfigComponents.PartnersPromoList,
    exact: true
  },
  {
    path: Routes.PROMO_VIEW,
    allow: allows.includes('promo'),
    component: ConfigComponents.PartnersPromoView,
    exact: true
  },
  {
    path: Routes.POSTBACKS_LOG,
    allow: allows.includes('postback'),
    component: ConfigComponents.PartnersPostbackLogs,
    exact: true
  },
  {
    path: Routes.BONUS_CODE_LIST,
    allow: allows.includes('bonus_code'),
    component: ConfigComponents.PartnersBonusCodeList,
    exact: true
  },
  {
    path: Routes.BONUS_CODE_VIEW,
    allow: allows.includes('bonus_code'),
    component: ConfigComponents.PartnersBonusCodeView,
    exact: true
  },
  {
    path: Routes.BONUS_CODE_CREATE,
    allow: allows.includes('bonus_code'),
    component: ConfigComponents.PartnersBonusCodeCreate,
    exact: true
  },
  {
    path: Routes.PARTNERS_STATISTICS,
    allow: allows.includes('statistics_partners'),
    component: ConfigComponents.ReportPartnersStatistics,
    exact: true
  },
  {
    path: Routes.REQUESTED_REPORT,
    allow: allows.includes('report_request'),
    component: ConfigComponents.ReportRequested,
    exact: true
  },
  {
    path: Routes.TRAFFIC_REPORT,
    allow: allows.includes('report_traffic'),
    component: ConfigComponents.ReportTraffic,
    exact: true
  },
  {
    path: Routes.BALANCE_CORRENCTIONS_LIST,
    allow: allows.includes('correction_balance'),
    component: ConfigComponents.FinanceBalanceCorrenctionList,
    exact: true
  },
  {
    path: Routes.BALANCE_CORRENCTIONS_VIEW,
    allow: allows.includes('correction_balance'),
    component: ConfigComponents.FinanceBalanceCorrenctionView,
    exact: true
  },
  {
    path: Routes.BILLS_LIST,
    allow: allows.includes('bill'),
    component: ConfigComponents.FinanceBillsList,
    exact: true
  },
  {
    path: Routes.BILLS_VIEW,
    allow: allows.includes('bill'),
    component: ConfigComponents.FinanceBillsView,
    exact: true
  },
  {
    path: Routes.PAYMENTS_LIST,
    allow: allows.includes('payment'),
    component: ConfigComponents.FinancePaymentsList,
    exact: true
  },
  {
    path: Routes.PAYMENTS_VIEW,
    allow: allows.includes('payment'),
    component: ConfigComponents.FinancePaymentsView,
    exact: true
  },
  {
    path: Routes.PAYMENTS_METHODS_LIST,
    allow: allows.includes('payment_method'),
    component: ConfigComponents.FinancePaymentsMethodsList,
    exact: true
  },
  {
    path: Routes.PAYMENTS_METHODS_VIEW,
    allow: allows.includes('payment_method'),
    component: ConfigComponents.FinancePaymentsMethodsView,
    exact: true
  },
  {
    path: Routes.EXCHANGE_RATES,
    allow: allows.includes('exchange_rates'),
    component: ConfigComponents.FinanceExchangeRates,
    exact: true
  },
  {
    path: Routes.BRANDS_LIST,
    allow: allows.includes('brand'),
    component: ConfigComponents.AffiliateBrandsList,
    exact: true
  },
  {
    path: Routes.BRANDS_VIEW,
    allow: allows.includes('brand'),
    component: ConfigComponents.AffiliateBrandsView,
    exact: true
  },
  {
    path: Routes.BRANDS_CREATE,
    allow: allows.includes('brand'),
    component: ConfigComponents.AffiliateBrandsCreate,
    exact: true
  },
  {
    path: Routes.LANDINGS_LIST,
    allow: allows.includes('landing'),
    component: ConfigComponents.AffiliateLandingList,
    exact: true
  },
  {
    path: Routes.LANDINGS_VIEW,
    allow: allows.includes('landing'),
    component: ConfigComponents.AffiliateLandingView,
    exact: true
  },
  {
    path: Routes.LANDINGS_CREATE,
    allow: allows.includes('landing'),
    component: ConfigComponents.AffiliateLandingCreate,
    exact: true
  },
  {
    path: Routes.MEDIA_LIST,
    allow: allows.includes('media'),
    component: ConfigComponents.AffiliateMediaList,
    exact: true
  },
  {
    path: Routes.MEDIA_VIEW,
    allow: allows.includes('media'),
    component: ConfigComponents.AffiliateMediaView,
    exact: true
  },
  {
    path: Routes.MEDIA_CREATE,
    allow: allows.includes('media'),
    component: ConfigComponents.AffiliateMediaCreate,
    exact: true
  },
  {
    path: Routes.COMISSION_LIST,
    allow: allows.includes('comission'),
    component: ConfigComponents.AffiliateComissionList,
    exact: true
  },
  {
    path: Routes.COMISSION_VIEW,
    allow: allows.includes('comission'),
    component: ConfigComponents.AffiliateComissionView,
    exact: true
  },
  {
    path: Routes.COMISSION_CREATE,
    allow: allows.includes('comission'),
    component: ConfigComponents.AffiliateComissionCreate,
    exact: true
  },
  {
    path: Routes.INVITE_AFFILATES_LIST,
    allow: allows.includes('invite'),
    component: ConfigComponents.AffiliateInviteList,
    exact: true
  },
  {
    path: Routes.INVITE_AFFILATES_CREATE,
    allow: allows.includes('invite'),
    component: ConfigComponents.AffiliateInviteCreate,
    exact: true
  },
  {
    path: Routes.INVITE_AFFILATES_VIEW,
    allow: allows.includes('invite'),
    component: ConfigComponents.AffiliateInviteView,
    exact: true
  },
  {
    path: Routes.OPERATORS_LIST,
    allow: allows.includes('operators'),
    component: ConfigComponents.SettingsOperators,
    exact: true
  },
  {
    path: Routes.OPERATORS_CREATE,
    allow: allows.includes('operators'),
    component: ConfigComponents.SettingsOperatorsCreate,
    exact: true
  },
  {
    path: Routes.OPERATORS_VIEW,
    allow: allows.includes('operators'),
    component: ConfigComponents.SettingsOperatorsView,
    exact: true
  },
  {
    path: Routes.ROLES_LIST,
    allow: allows.includes('roles'),
    component: ConfigComponents.SettingsRoles,
    exact: true
  },
  {
    path: Routes.ROLES_CREATE,
    allow: allows.includes('roles'),
    component: ConfigComponents.SettingsRolesCreate,
    exact: true
  },
  {
    path: Routes.ROLES_VIEW,
    allow: allows.includes('roles'),
    component: ConfigComponents.SettingsRolesView,
    exact: true
  },
  {
    path: Routes.EVENTS_LIST,
    allow: allows.includes('event'),
    component: ConfigComponents.SettingsEvents,
    exact: true
  },
  {
    path: Routes.PLAYERS_LIST,
    allow: allows.includes('gamers'),
    component: ConfigComponents.PlayersList,
    exact: true
  },
  {
    path: Routes.PLAYERS_VIEW,
    allow: allows.includes('gamers'),
    component: ConfigComponents.PlayersView,
    exact: true
  },
  {
    path: Routes.PARTNERS_CORRECTION_BALANCE,
    allow: allows.includes('correction_balance'),
    component: ConfigComponents.CorrectionBalance,
    exact: true
  },
  {
    path: Routes.PROMO_TRANSACTION_VIEW,
    allow: allows.includes('promo'),
    component: ConfigComponents.PromoTransaction,
    exact: true
  },
  {
    path: Routes.DASHBOARD,
    allow: allows.includes('information_panel'),
    component: ConfigComponents.Dashboard,
    exact: true
  },
  {
    path: Routes.PARTNERS_CREATE_COMISSION,
    allow: allows.includes('list_partners'),
    component: ConfigComponents.PartnersCreateComission,
    exact: true
  }
];
