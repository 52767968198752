import { types } from './types';

export const setAuthorizeAction = (payload) => {
  return {
    type: types.SET_AUTHORIZE,
    payload,
  }
}

export const setAllowsAction = (payload) => {
  return {
    type: types.SET_ALLOWS,
    payload,
  }
}