import Title from '../../../../../../@casino/ui/title';
import React from 'react';
import { useRouter } from '../../../../../../@casino/useRouter';
import { useDispatch, useSelector } from 'react-redux';
import { setGetPromoTransactionById } from '../../../../../store/promo/thunk/setGetTransactionById';
import { Typography } from '@material-ui/core';
import Layout from './layout';
import { setGetTransactionById } from '../../../../../store/transaction/thunk/setGetTransactionById';
import Label from '../view/block/label';
import { format } from 'date-fns';

const PromoTransaction = () => {
    const router = useRouter();
    const dispatch = useDispatch();

    const { promo_id, transaction_id } = router.query;

    React.useEffect(() => {
        dispatch(setGetPromoTransactionById({
            id: transaction_id
        }))
        dispatch(setGetTransactionById({
            id: transaction_id
        }))
    }, []);

    const transaction_rules = useSelector((state) => state.promo.transaction);
    const view = useSelector((state) => state.transaction.view);
    console.log(view)
    return (
        <div style={{
            padding: '0 20px'
        }}>
            <Title
                label="Просмотр транзакции"
                labelBtn="Назад"
                handleClick={() => router.history.push(`/partners/promo/view/${promo_id}`)}
            />
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                padding: 20,
                background: '#1b2039',
                borderRadius: 8,
                rowGap: 12,
                width: '100%',
                marginBottom: 12
            }}>
                <Label
                    label="Дата создания"
                    description={format(new Date(Number(view?.transaction.created_at || 0)), 'dd.MM.yyyy HH:mm')}
                />

                <Label
                    label="Сумма к оплате"
                    description={`${view?.transaction.fiat_amount || " "} ${view?.transaction.fiat_currency || " "}`}
                />

                <Label
                    label="Оффер"
                    description={view?.offer.name}
                    handleClick={() => {
                        router.history.push(`/affiliate/comission/view/${view?.offer?.id}`)
                    }}
                />

                <Label
                    label="Партнер"
                    description={view?.partner?.login}
                    handleClick={() => {
                        router.history.push(`/partners/view/${view?.partner?.id}`)
                    }}
                />
                
                <Label
                    label="Пользователь"
                    description={view?.user?.id}
                    handleClick={() => {
                        router.history.push(`/players/view/${view?.partner?.id}`)
                    }}
                />

                <Label
                    label="Промо"
                    description={view?.promo?.label}
                    handleClick={() => {
                        router.history.push(`/partners/promo/view/${view?.partner?.id}`)
                    }}
                />

                <Label
                    label="Вид оплаты"
                    description={view?.transaction.reward_type}
                />

                <Label
                    label="Статус"
                    description={view?.transaction.status}
                />
            </div>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                padding: 20,
                background: '#1b2039',
                borderRadius: 8,
                rowGap: 12,
                width: '100%'
            }}>
                <Typography style={{ color: 'hsla(0,0%,100%,.8)', textTransform: 'uppercase', marginBottom: 20 }}>Квалификация</Typography>
                <div style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: 20
                }}>
                    {
                        transaction_rules.map((item) => item?.rule?.type && <Layout {...item} />)
                    }
                </div>
            </div>

        </div>
    )
}

export default PromoTransaction;