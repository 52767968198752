import React from 'react';
import Title from '../../../../../../@casino/ui/title';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from '../../../../../../@casino/useRouter';
import { setGetPaymentsMethodById } from '../../../../../store/payment-methods/thunk/getPaymentsMethodById';
import { setGetPartnersById } from '../../../../../store/partners/thunk/setGetPartnersById';
import { Typography } from '@material-ui/core';
import Label from './label';
import { format } from 'date-fns';
import { setPutChangeStatusPaymentsMethod } from '../../../../../store/payment-methods/thunk/setPutChangeStatusPaymentsMethod';

const FinancePaymentsMethodsView = () => {
  const dispatch = useDispatch();
  const router = useRouter();

  React.useEffect(() => {
    dispatch(setGetPaymentsMethodById({
      id: router.query.id
    }));
  }, []);

  const payment_method = useSelector((state) => state.payment_method.view);

  React.useEffect(() => {
    if (payment_method?.user_id) {
      dispatch(setGetPartnersById({
        id: payment_method.user_id
      }))
    }
  }, [payment_method])

  const partner = useSelector((state) => state.partners.view);

  const handleClickVerification = () => {
    dispatch(setPutChangeStatusPaymentsMethod({
      id: payment_method.id,
      status: 'VERIFIED'
    }))
  }

  const handleClickCanceled = () => {
    dispatch(setPutChangeStatusPaymentsMethod({
      id: payment_method.id,
      status: 'CANCELED'
    }))
  }

  const handleGetStatus = (value) => {
    const status = value?.toString()?.toLowerCase();
    switch(status) {
      case "verified": return "Подтверждено";
      case "canceled": return "Отменено";
      case "await": return "Ожидает";
    }
  }


  return (
    <div style={{
      padding: '0 20px'
    }}>
      <Title
        label="Платежный метод"
        labelBtn="Назад"
        handleClick={() => router.history.push('/finance/payments_methods/list')}  
      />
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        padding: 20,
        background: '#1b2039',
        borderRadius: 8,
        rowGap: 12
      }}>
        <Typography style={{ color: 'hsla(0,0%,100%,.8)', textTransform: 'uppercase', marginBottom: 20 }}>Информация о платежном методе</Typography>
        <Label
          label="Дата создания"
          description={format(new Date(Number(payment_method?.created_at || 0)), 'dd.MM.yyyy HH:mm')}
        />
        <Label
          label="Статус"
          description={handleGetStatus(payment_method?.status)}
        />
        <Label
          label="Метод"
          description={payment_method?.payment_system}
        />
        <Label
          label="Кошелек"
          description={payment_method?.wallet}
        />
        <Label
          label="ID партнёра"
          description={payment_method?.login}
          handleClick={() => router.history.push(`/partners/view/${payment_method?.partner_id}`)}
        />
        <Typography style={{ color: 'hsla(0,0%,100%,.8)', textTransform: 'uppercase', marginBottom: 20 }}>Связь</Typography>
        <Label
          label="Имя"
          description={partner?.name}
        />
        <Label
          label="Телеграм"
          description={partner?.telegram}
        />
        <Label
          label="Skype"
          description={partner?.skype}
        />
      </div>
      {
        payment_method.status === 'AWAIT' && (
          <div style={{ marginTop: 12, display: 'flex', columnGap: 12 }}>
            <button
              type="button"
              onClick={handleClickVerification}
              style={{
                color: '#141b33',
                cursor: 'pointer',
                padding: '10px',
                background: '#ffc324',
                borderRadius: '4px',
                border: 'none',
                outline: 'none'
              }}
            >
              Верифицировать
            </button>
            <button
              type="button"
              onClick={handleClickCanceled}
              style={{
                color: '#141b33',
                cursor: 'pointer',
                padding: '10px',
                background: '#ffc324',
                borderRadius: '4px',
                border: 'none',
                outline: 'none'
              }}
            >
              Отменить
            </button>
          </div>
        )
      }
    </div>
  )
}

export default FinancePaymentsMethodsView;