import { types } from './types';

export const setGetOperatorsAction = (payload) => {
  return {
    type: types.SET_GET_OPERATORS,
    payload,
  }
}

export const setGetOperatorsByIdAction = (payload) => {
  return {
    type: types.SET_GET_OPERATORS_BY_ID,
    payload
  }
}