import { setGetMediaByIdAction } from '../action';
import { getMediaById } from '../api/getMediaById';

export const setGetMediaById = (params, callback) => {
    return async (dispatch, getState) => {
        try {
            const { data } = await getMediaById(params);
            dispatch(setGetMediaByIdAction(data.data))

            if (callback) {
                callback();
            }
        } catch (error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}