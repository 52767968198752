import { types } from './types';

export const setGetMediaAction = (payload) => {
  return {
    type: types.SET_GET_MEDIA,
    payload,
  }
}

export const setGetMediaByIdAction = (payload) => {
  return {
    type: types.SET_GET_MEDIA_BY_ID,
    payload
  }
}