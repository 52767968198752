import PlayersList from "../desktop/documents/players/list";
import PlayersView from '../desktop/documents/players/view';
import AffiliateBrandsList from "../desktop/documents/affiliate/brands/list";
import AffiliateBrandsCreate from "../desktop/documents/affiliate/brands/create";
import AffiliateBrandsView from "../desktop/documents/affiliate/brands/view";
import AffiliateComissionList from "../desktop/documents/affiliate/comission/list";
import AffiliateComissionView from "../desktop/documents/affiliate/comission/view";
import AffiliateComissionCreate from "../desktop/documents/affiliate/comission/create";
import AffiliateInviteList from "../desktop/documents/affiliate/invite_affilates/list";
import AffiliateInviteCreate from "../desktop/documents/affiliate/invite_affilates/create";
import AffiliateInviteView from "../desktop/documents/affiliate/invite_affilates/view";
import AffiliateLandingList from "../desktop/documents/affiliate/landings/list";
import AffiliateLandingView from "../desktop/documents/affiliate/landings/view";
import AffiliateLandingCreate from "../desktop/documents/affiliate/landings/create";
import AffiliateMediaList from "../desktop/documents/affiliate/media/list";
import AffiliateMediaView from "../desktop/documents/affiliate/media/view";
import AffiliateMediaCreate from "../desktop/documents/affiliate/media/create";
import Dashboard from "../desktop/documents/dashboard";
import FinanceBalanceCorrenctionList from '../desktop/documents/finance/balance_correnctions/list';
import FinanceBalanceCorrenctionView from '../desktop/documents/finance/balance_correnctions/view';
import FinanceBillsList from '../desktop/documents/finance/bills/list';
import FinanceBillsView from '../desktop/documents/finance/bills/view';
import FinanceExchangeRates from '../desktop/documents/finance/exchange_rates';
import FinancePaymentsList from '../desktop/documents/finance/payments/list';
import FinancePaymentsView from '../desktop/documents/finance/payments/view';
import FinancePaymentsMethodsList from '../desktop/documents/finance/payments_methods/list';
import FinancePaymentsMethodsView from '../desktop/documents/finance/payments_methods/view';
import PartnersBonusCodeList from '../desktop/documents/partners/bonus_code/list';
import PartnersBonusCodeView from '../desktop/documents/partners/bonus_code/view';
import PartnersBonusCodeCreate from '../desktop/documents/partners/bonus_code/create';
import PartnersCampaignsList from '../desktop/documents/partners/campaigns/list';
import PartnersCampaignsView from '../desktop/documents/partners/campaigns/view';
import PartnersList from '../desktop/documents/partners/list/list';
import PartnersView from '../desktop/documents/partners/list/view';
import PartnersPostbackLogs from '../desktop/documents/partners/postbacks_log/list';
import PartnersPromoList from '../desktop/documents/partners/promo/list';
import PartnersPromoView from '../desktop/documents/partners/promo/view';
import ReportPartnersStatistics from '../desktop/documents/report/partners_statistics';
import ReportRequested from '../desktop/documents/report/requested_report';
import ReportTraffic from '../desktop/documents/report/traffic_report';
import SettingsEvents from '../desktop/documents/settings/events/list';
import SettingsOperators from '../desktop/documents/settings/operators/list';
import SettingsOperatorsCreate from '../desktop/documents/settings/operators/create';
import SettingsOperatorsView from '../desktop/documents/settings/operators/view';
import SettingsRoles from '../desktop/documents/settings/roles/list';
import SettingsRolesCreate from '../desktop/documents/settings/roles/create';
import SettingsRolesView from '../desktop/documents/settings/roles/view';
import CorrectionBalance from "../desktop/documents/partners/list/correction_balance";
import PromoTransaction from "../desktop/documents/partners/promo/transaction";
import PartnersCreateComission from '../desktop/documents/partners/list/components/comission_create';

export const ConfigComponents = {
    AffiliateBrandsList,
    AffiliateBrandsView,
    AffiliateBrandsCreate,
    AffiliateComissionList,
    AffiliateComissionView,
    AffiliateComissionCreate,
    AffiliateInviteList,
    AffiliateInviteCreate,
    AffiliateInviteView,
    AffiliateLandingList,
    AffiliateLandingView,
    AffiliateLandingCreate,
    AffiliateMediaList,
    AffiliateMediaView,
    AffiliateMediaCreate,
    Dashboard,
    FinanceBalanceCorrenctionList,
    FinanceBalanceCorrenctionView,
    FinanceBillsList,
    FinanceBillsView,
    FinanceExchangeRates,
    FinancePaymentsList,
    FinancePaymentsView,
    FinancePaymentsMethodsList,
    FinancePaymentsMethodsView,
    PartnersBonusCodeList,
    PartnersBonusCodeView,
    PartnersBonusCodeCreate,
    PartnersCampaignsList,
    PartnersCampaignsView,
    PartnersList,
    PartnersView,
    PartnersPostbackLogs,
    PartnersPromoList,
    PartnersPromoView,
    PlayersList,
    PlayersView,
    ReportPartnersStatistics,
    ReportRequested,
    ReportTraffic,
    SettingsEvents,
    SettingsOperators,
    SettingsOperatorsCreate,
    SettingsOperatorsView,
    SettingsRoles,
    SettingsRolesCreate,
    SettingsRolesView,
    CorrectionBalance,
    PromoTransaction,
    PartnersCreateComission
}