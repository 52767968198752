import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useRouter } from '../../../../../../../@casino/useRouter';
import { format } from 'date-fns';
import Table from '../../../../../table';
import { useDispatch, useSelector } from 'react-redux';
import { setGetOffersByPartner } from '../../../../../../store/offers/thunk/setGetOffersByPartner';
import { setGetOffersByPartnerAction } from '../../../../../../store/offers/action';
import { useForm } from 'react-hook-form';
import { setPostGroupOffer } from '../../../../../../store/offers/thunk/setGetPostGroupOffer';
import { setGetGroupOffers } from '../../../../../../store/offers/thunk/setGetGroupOffers';

const useStyles = makeStyles(() => ({
    wrapBtn: {
        marginTop: 12
    },
    btn: {
        color: '#141b33',
        cursor: 'pointer',
        padding: '10px',
        background: '#ffc324',
        borderRadius: '4px',
        border: 'none',
        outline: 'none'
    },
    select: {
        marginRight: 12,
        height: '32px',
        width: '100%',
        border: 0,
        background: '#293356',
        boxShadow: 'inset 0 1px 0 0 hsl(0deg 0% 100% / 20%)',
        color: '#fff',
        cursor: 'pointer',
        paddingLeft: 15,
        paddingRight: 0,
        overflow: 'hidden',
        textAlign: 'left',
        textDecoration: 'none',
        outline: 'none',
        lineHeight: '18px',
        position: 'relative',
        borderRadius: '4px',
    }
}))

const Comission = ({
    partner_id
}) => {
    const classes = useStyles();
    const router = useRouter();
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(setGetOffersByPartner());
        dispatch(setGetGroupOffers({
            page: 0,
            rowsPerPage: 10,
            partner_id
        }))
    }, []);

    const columns = [{
        label: 'Дата создания',
        name: 'created_at',
        selector: (value) => format(new Date(Number(value)), 'dd.MM.yyyy')
    }, {
        label: 'Наименование',
        name: 'name',
        align: 'left',
    }, {
        label: 'Стратегия',
        name: 'strategy',
        align: 'left',
    }, {
        label: 'План',
        name: 'schedule_plan',
        align: 'left',
    }, {
        label: 'Статус',
        name: 'is_active',
        align: 'right',
        selector: (value) => value ? 'Активен' : 'В Архиве'
    }];

    const offers = useSelector((state) => state.offers.partner);
    const group = useSelector((state) => state.offers.group);

    const { handleSubmit, register, reset } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: {
            name: "",
        }
    });

    const onSubmit = (store) => {
        const params = {
            partner_id,
            offer_id: store.offer_id
        }
        if (params.offer_id) {
            dispatch(setPostGroupOffer(params, () => {
                reset()
                dispatch(setGetGroupOffers({
                    page: 0,
                    rowsPerPage: 10,
                    partner_id
                }))
            }))
        }
    }


    return (
        <div>
            <div style={{
                display: 'flex',
                justifyContent: 'flex-start'
            }}>
                <div className={classes.wrapBtn}>
                    <form onSubmit={handleSubmit(onSubmit)} style={{display: 'flex', alignItems: 'center', columnGap: 10}}>
                        <select
                            className={classes.select}
                            {...register('offer_id')}
                        >
                            <option value="">Не выбран</option>
                            {
                                offers?.data?.map((item) => (
                                    <option value={item.id}>{item.name}</option>
                                ))
                            }
                        </select>
                        <button
                            type="submit"
                            className={classes.btn}
                        >
                            Добавить
                        </button>
                    </form>
                    
                </div>
            </div>
            <div>
                <Table
                    columns={columns}
                    data={group?.data || []}
                    handleEventDoubleClick={(v) => {
                        router.history.push(`/affiliate/comission/view/${v.id}`)
                    }}
                    totalPage={offers?.total}
                    handleChangeRowsPerPage={(rowsPerPage) => {
                        dispatch(setGetOffersByPartnerAction({
                            data: [],
                            currentPage: 0,
                            currentRowsPerpage: rowsPerPage,
                            total: offers?.total,
                            totalPage: offers?.totalPage
                        }))
                        dispatch(setGetOffersByPartner({
                            page: 0,
                            rowsPerPage
                        }));
                    }}
                    handleChangePage={({
                        rowsPerPage,
                        page
                    }) => {
                        dispatch(setGetOffersByPartnerAction({
                            data: [],
                            currentPage: page,
                            currentRowsPerpage: rowsPerPage,
                            total: offers?.total,
                            totalPage: offers?.totalPage
                        }))
                        dispatch(setGetOffersByPartner({
                            page,
                            rowsPerPage
                        }));
                    }}
                />
            </div>
        </div>
    )
}

export default Comission;