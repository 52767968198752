import React from 'react';
import Title from '../../../../../../@casino/ui/title';
import { useFieldArray, useForm } from "react-hook-form";
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useRouter } from '../../../../../../@casino/useRouter';
import { useDispatch, useSelector } from 'react-redux';
import { setPostRole } from '../../../../../store/roles/thunk/setPostRole';
import { setGetRoleById } from '../../../../../store/roles/thunk/setGetRoleById';
import { setPutRole } from '../../../../../store/roles/thunk/setPutRole';

const useStyles = makeStyles(() => ({
  helper: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    position: 'relative',
    display: 'inline-block',
    fontSize: '11px',
    color: 'hsla(0,0%,100%,.7)',
    verticalAlign: 'middle',
    fontFamily: "PT Sans, sans-serif",
    userSelect: 'none'
  },
  text: {
    position: 'relative',
    borderRadius: '4px',
    height: '32px',
    overflow: 'hidden',
    boxShadow: '0 1px 0 0 hsl(0deg 0% 100% / 15%), inset 0 1px 0 0 rgb(0 0 0 / 15%)',
    border: 0,
    background: '#1c2542',
    color: '#fff',
    outline: 'none',
    paddingLeft: 8,
  },
  wrapLabel: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 12,
    marginBottom: 24,
  },
  beginBonusLabel: {
    width: '32%',
  },
}))

const ALLOWS = [{
  label: 'Информационная панель',
  name: 'information_panel',
  value: false
}, {
  label: 'Список партнеров',
  name: 'list_partners',
  value: false
}, {
  label: 'Кампании',
  name: 'campaign',
  value: false
}, {
  label: 'Промо',
  name: 'promo',
  value: false
}, {
  label: 'Постбеки логов',
  name: 'postback',
  value: false
}, {
  label: 'Бонус код',
  name: 'bonus_code',
  value: false
}, {
  label: 'Игроки',
  name: 'gamers',
  value: false
}, {
  label: 'Статистика партнеров',
  name: 'statistics_partners',
  value: false
}, {
  label: 'Отчет запрсов',
  name: 'report_request',
  value: false
}, {
  label: 'Отчет по трафику',
  name: 'report_traffic',
  value: false
}, {
  label: 'Коррекция балансов',
  name: 'correction_balance',
  value: false
}, {
  label: 'Вывод средств',
  name: 'bill',
  value: false
}, {
  label: 'Платежи',
  name: 'payment',
  value: false
}, {
  label: 'Платежные методы',
  name: 'payment_method',
  value: false
}, {
  label: 'Курс валют',
  name: 'exchange_rates',
  value: false
}, {
  label: 'Бренд',
  name: 'brand',
  value: false
}, {
  label: 'Лендинги',
  name: 'landing',
  value: false
}, {
  label: 'Медиа',
  name: 'media',
  value: false
}, {
  label: 'Комиссии',
  name: 'comission',
  value: false
}, {
  label: 'Инвайты',
  name: 'invite',
  value: false
}, {
  label: 'Операторы',
  name: 'operators',
  value: false
}, {
  label: 'Роли',
  name: 'roles',
  value: false
}, {
  label: 'События',
  name: 'event',
  value: false
}]

const RolesView = () => {
  const classes = useStyles();
  const router = useRouter();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(setGetRoleById({
      id: router.query.id
    }))
  }, [])

  const { handleSubmit, register, control, reset } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      name: "",
      allow: ALLOWS
    }
  });

  const onSubmit = (store) => {
    const allows = store.allow?.filter((item) => item.value)?.map((item) => item.name).join(',');

    const data = {
      name: store.name, 
      allows,
      id: store.id
    };
    dispatch(setPutRole(data, () => {
      router.history.push('/settings/roles/list')
    }));
  }

  const { fields } = useFieldArray({
    control,
    name: 'allow'
  })

  const view = useSelector(state => state.role.view);
  
  React.useEffect(() => {
    if (view) {
      const active_allow = view?.allows?.split(',');
      const change_allow = [];

      for (let i = 0; i < ALLOWS.length; i++) {
        const allow = ALLOWS[i];
      
        change_allow.push({
          ...allow,
          value: active_allow?.includes(allow.name)
        })
      }

      reset({
        name: view?.name,
        allow: change_allow,
        id: view?.id
      })
    }
   
  }, [view])

  return (
    <div style={{
      padding: '0 20px'
    }}>
      <Title
        label={`Просмотр роли ${view?.name}`}
        labelBtn="Назад"
        handleClick={() => router.history.push('/settings/roles/list')}
      />

      <div style={{
        display: 'flex',
        flexDirection: 'column',
        padding: 20,
        background: '#1b2039',
        borderRadius: 8,
      }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="name" className={clsx(classes.wrapLabel, classes.beginBonusLabel)}>
            <span className={classes.helper}>Наименование</span>
            <input
              {...register('name')}
              type="text"
              id="name"
              className={classes.text}
              disabled={true}
            />
          </label>
          {
            fields.map((item, key) => (
              <label htmlFor={item.name} className={clsx(classes.wrapLabel, classes.beginBonusLabel)} style={{ display: 'flex', flexDirection: 'row', columnGap: 12, alignItems: 'center', cursor: 'pointer' }}>
                <input
                  {...register(`allow[${key}].value`)}
                  type="checkbox"
                  id={item.name}
                  style={{
                    appearance: 'auto',
                    '-webkit-appearance': 'auto'
                  }}
                />
                <span className={classes.helper}>{item.label}</span>
              </label>
            ))
          }
          <button 
            type="submit" 
            style={{
              color: '#141b33',
              cursor: 'pointer',
              padding: '10px',
              background: '#ffc324',
              borderRadius: '4px',
              border: 'none',
              outline: 'none'
            }}
          >
            Сохранить
          </button>
        </form>
      </div>
    </div>
  )
}

export default RolesView;