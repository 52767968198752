import { pathRoutes as Routes } from './pathRoutes';
import { Allow as AllowMap} from './allow';

export const allOpenedCategory = [
    // 'cashier',
    // 'report',
    // 'game',
    // 'replenishment',
    // 'gamer',
    // 'bonuses',
    // 'withdrawal',
    // 'security',
    // 'settings'
]
const Allow = AllowMap();

export const navigationArray = (allows) => [
    {
        id: 1,
        field: 'dashboard',
        label: 'Информационная панель',
        path: Routes.DASHBOARD,
        allow: allows.includes('information_panel')
    },
    {
        id: 12,
        field: 'partners',
        label: 'Партнеры',
        allow: allows.includes('bonus_code') || allows.includes('postback') || allows.includes('promo') || allows.includes('list_partners'),
        children: [
            {
                id: 13,
                field: 'list_partners',
                label: 'Список партнеров',
                path: Routes.PARTNERS_LIST,
                allow: allows.includes('list_partners')
            },
            {
                id: 13,
                field: 'promo',
                label: 'Промо',
                path: Routes.PROMO_LIST,
                allow: allows.includes('promo')
            },
            {
                id: 13,
                field: 'postbacks_log',
                label: 'Постбеки логи',
                path: Routes.POSTBACKS_LOG,
                allow: allows.includes('postback')
            },
            {
                id: 13, 
                field: 'bonus_code',
                label: 'Бонус код',
                path: Routes.BONUS_CODE_LIST,
                allow: allows.includes('bonus_code')
            },
        ]
    },
    {
        id: 1,
        field: 'players',
        label: 'Игроки',
        path: Routes.PLAYERS_LIST,
        allow: allows.includes('gamers')
    },
    // {
    //     id: 12,
    //     field: 'report',
    //     label: 'Отчеты',
    //     allow: allows.includes('report_traffic') || allows.includes('report_request') || allows.includes('statistics_partners'),
    //     children: [
    //         {
    //             id: 13,
    //             field: 'partners_statistics',
    //             label: 'Статистика партнеров',
    //             path: Routes.PARTNERS_STATISTICS,
    //             allow: allows.includes('statistics_partners')
    //         },
    //         {
    //             id: 13,
    //             field: 'requested_report',
    //             label: 'Отчет запросов',
    //             path: Routes.REQUESTED_REPORT,
    //             allow: allows.includes('report_request')
    //         },
    //         {
    //             id: 13,
    //             field: 'traffic_report',
    //             label: 'Отчет по трафику',
    //             path: Routes.TRAFFIC_REPORT,
    //             allow: allows.includes('report_traffic')
    //         },
    //     ]
    // },
    {
        id: 12,
        field: 'finance',
        label: 'Финансы',
        allow: allows.includes('exchange_rates') || allows.includes('payment_method') || allows.includes('payment') || allows.includes('bill') || allows.includes('correction_balance'),
        children: [
            {
                id: 13,
                field: 'balance_correnctions',
                label: 'Коррекция балансов',
                path: Routes.BALANCE_CORRENCTIONS_LIST,
                allow: allows.includes('correction_balance')
            },
            {
                id: 13,
                field: 'bills',
                label: 'Вывод средств',
                path: Routes.BILLS_LIST,
                allow: allows.includes('bill')
            },
            {
                id: 13,
                field: 'payments',
                label: 'Платежи',
                path: Routes.PAYMENTS_LIST,
                allow: allows.includes('payment')
            },
            {
                id: 13,
                field: 'payments_methods',
                label: 'Платежные методы',
                path: Routes.PAYMENTS_METHODS_LIST,
                allow: allows.includes('payment_method')
            },
            {
                id: 13,
                field: 'exchange_rates',
                label: 'Курс валют',
                path: Routes.EXCHANGE_RATES,
                allow: allows.includes('exchange_rates')
            },
        ]
    },
    {
        id: 12,
        field: 'affiliate',
        label: 'Афилейты',
        allow: allows.includes('landing') || allows.includes('media') || allows.includes('comission') || allows.includes('invite'),
        children: [
            // {
            //     id: 13,
            //     field: 'brands',
            //     label: 'Бренд',
            //     path: Routes.BRANDS_LIST,
            //     allow: allows.includes('brand')
            // },
            {
                id: 13,
                field: 'landings',
                label: 'Лендинги',
                path: Routes.LANDINGS_LIST,
                allow: allows.includes('landing')
            },
            {
                id: 13,
                field: 'media',
                label: 'Медиа',
                path: Routes.MEDIA_LIST,
                allow: allows.includes('media')
            },
            {
                id: 13,
                field: 'comission',
                label: 'Комиссии',
                path: Routes.COMISSION_LIST,
                allow: allows.includes('comission')
            },
            {
                id: 13,
                field: 'invite_affilates',
                label: 'Инвайты',
                path: Routes.INVITE_AFFILATES_LIST,
                allow: allows.includes('invite')
            },
        ]
    },
    {
        id: 12,
        field: 'settings',
        label: 'Настройки',
        allow: allows.includes('operators') || allows.includes('roles') || allows.includes('event'),
        children: [
            {
                id: 13,
                field: 'operators',
                label: 'Операторы',
                path: Routes.OPERATORS_LIST,
                allow: allows.includes('operators')
            },
            {
                id: 13,
                field: 'roles',
                label: 'Роли',
                path: Routes.ROLES_LIST,
                allow: allows.includes('roles')
            },
            {
                id: 13,
                field: 'events',
                label: 'События',
                path: Routes.EVENTS_LIST,
                allow: allows.includes('event')
            },
        ]
    },
];