import { isFunction } from 'lodash';
import { getPromoUsersCount } from '../api/getPromoUsersCount';
import { setGetPromoUsersCountAction } from '../action';

export const setGetPromoUsersCount = (params, callback) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await getPromoUsersCount(params);

            dispatch(setGetPromoUsersCountAction(data?.count));
        } catch(error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}