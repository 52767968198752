import React from 'react';
import Title from '../../../../../../@casino/ui/title';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from '../../../../../../@casino/useRouter';
import { setGetPromoById } from '../../../../../store/promo/thunk/setGetPromoById';
import { CircularProgress, Typography } from '@material-ui/core';
import Label from './block/label';
import { format } from 'date-fns';
import Table from '../../../../table';
import { setGetTransactionsList } from '../../../../../store/transaction/thunk/setGetTransactionsList';
import { setGetPromoCheckById } from '../../../../../store/promo/thunk/setGetPromoCheckById';
import { setGetPromoUsersCount } from '../../../../../store/promo/thunk/setGetPromoUsersCount';
import { setPostPromoStatus } from '../../../../../store/promo/thunk/setPostPromoStatus';
import { setPutStatusTransactionById } from '../../../../../store/transaction/thunk/setPutStatusTransactionById';
import { setPostPromoFixed } from '../../../../../store/promo/thunk/setPostPromoFixed';
import { setGetOffersByPartner } from '../../../../../store/offers/thunk/setGetOffersByPartner';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { setPostChangeOffer } from '../../../../../store/promo/thunk/setPostChangeOffer';

const useStyles = makeStyles(() => ({
  wrapBtn: {
    marginTop: 12
  },
  btn: {
    color: '#141b33',
    cursor: 'pointer',
    padding: '10px',
    background: '#ffc324',
    borderRadius: '4px',
    border: 'none',
    outline: 'none'
  },
  select: {
    marginRight: 12,
    height: '32px',
    width: '100%',
    border: 0,
    background: '#293356',
    boxShadow: 'inset 0 1px 0 0 hsl(0deg 0% 100% / 20%)',
    color: '#fff',
    cursor: 'pointer',
    paddingLeft: 15,
    paddingRight: 0,
    overflow: 'hidden',
    textAlign: 'left',
    textDecoration: 'none',
    outline: 'none',
    lineHeight: '18px',
    position: 'relative',
    borderRadius: '4px',
  }
}))

const PartnersPromoView = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const router = useRouter();
  const { id } = router.query;

  const [isChangeOffer, setIsChangeOffer] = React.useState(false);
  const [offerId, setOfferId] = React.useState(undefined);

  React.useEffect(() => {
    dispatch(setGetPromoById({
      id
    }));
    dispatch(setGetTransactionsList({
      promo_id: id
    }));
    dispatch(setGetPromoUsersCount({
      id
    }));
    dispatch(setGetOffersByPartner());
  }, []);

  const offers = useSelector((state) => state.offers.partner);
  const view = useSelector((state) => state.promo.view);

  React.useEffect(() => {
    if (view?.offer?.id) {
      setOfferId(view.offer.id)
    }
  }, [view])

  const isCheck = view?.promo?.status === 'SUCCESS';

  const [isLoading, setIsLoading] = React.useState(false);

  const transactions = useSelector((state) => state.transaction.list);
  const users_count = useSelector((state) => state.promo.users_count);

  const handleClickSuccess = (v) => {
    dispatch(setPutStatusTransactionById({
      status: 'SUCCESS',
      id: v.id,
      promo_id: Number(id)
    }, () => {
      dispatch(setGetTransactionsList({
        promo_id: id
      }))
    }))
  }

  const columns = [{
    label: 'Дата создания',
    name: 'created_at',
    selector: (value) => format(new Date(Number(value)), 'dd.MM.yyyy')
  }, {
    label: 'ID на платформе',
    name: 'platform_id',
    align: 'left',
  }, {
    label: 'Валюта',
    name: 'fiat_currency',
    align: 'left',
  }, {
    label: 'Сумма',
    name: 'fiat_amount',
    align: 'left',
  }, {
    label: 'Тип вознаграждения',
    name: 'reward_type',
    align: 'left',
  }, {
    label: 'Статус',
    name: 'status',
    align: 'left',
  }, {
    label: 'Оплата',
    name: 'id',
    align: 'right',
    multiple: true,
    selector: (v) => (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {
          (v.status === 'VERIFIED' || v.status === 'OLD VERIFIED') && isCheck && (
            <button
              type="button"
              onClick={() => handleClickSuccess(v)}
              style={{
                color: '#141b33',
                cursor: 'pointer',
                padding: '10px',
                background: '#ffc324',
                borderRadius: '4px',
                border: 'none',
                outline: 'none'
              }}
            >
              Оплатить
            </button>
          )
        }
        {
          ((v.status !== 'OLD VERIFIED' && v.status !== 'VERIFIED') || !isCheck) && (
            <Typography>Запрещено</Typography>
          )
        }
      </div>
    )
  }];

  const handleSave = () => {
    dispatch(setPostChangeOffer({
      promo_id: view.promo.id,
      offer_id: offerId
    }, () => {
      setIsChangeOffer(false);
    }))
  };

  return (
    <div style={{
      padding: '0 20px'
    }}>
      <Title
        label="Просмотр промо"
        labelBtn="Назад"
        handleClick={() => router.history.push('/promo/list')}
      />
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        padding: 20,
        background: '#1b2039',
        borderRadius: 8,
        rowGap: 12
      }}>
        <Typography style={{ color: 'hsla(0,0%,100%,.8)', textTransform: 'uppercase', marginBottom: 20 }}>Информация</Typography>

        <Label
          label="Дата создания"
          description={format(new Date(Number(view?.promo?.created_at || 0)), 'dd.MM.yyyy HH:mm')}
        />

        <Label
          label="Дата завершения"
          description={format(new Date(Number(view?.promo?.execute_date || 0)), 'dd.MM.yyyy HH:mm')}
        />

        <Label
          label="Наименование"
          description={view?.promo?.label}
        />

        <Label
          label="Поток"
          description={view?.promo?.stream}
        />

        <Label
          label="Партнер"
          description={view?.partner?.login}
          handleClick={() => router.history.push(`/partners/view/${view?.partner?.id}`)}
        />

        <Label
          label="Менеджер партнёра"
          description={view?.partner?.manager}
        />
        {
          !isChangeOffer && (
            <Label
              label="Оффер"
              description={view?.offer?.name}
              handleClick={() => router.history.push(`/affiliate/comission/view/${view?.offer?.id}`)}
            />
          )
        }
        {
          isChangeOffer && (
            <div style={{display: 'flex'}}>
              <Label
                label="Оффер"
                description=" "
              />
              <select
                className={classes.select}
                value={offerId}
                onChange={(v) => {
                  setOfferId(v.target.value)
                }}
              >
                <option value="">Не выбран</option>
                {
                  offers?.data?.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))
                }
              </select>
            </div>
          )
        }


        <Label
          label="URL"
          description={view?.promo?.url}
          handleClick={() => window.open(view.promo.url, '_blank')}
        />

        <Label
          label="Кол-во пользователей"
          description={users_count}
        />
        {
          !isChangeOffer && (
            <div>
              <button
                type="button"
                onClick={() => {
                  setIsChangeOffer(true)
                }}
                style={{
                  color: '#141b33',
                  cursor: 'pointer',
                  padding: '10px',
                  background: '#ffc324',
                  borderRadius: '4px',
                  border: 'none',
                  outline: 'none'
                }}
              >
                Редактировать Комисию
              </button>
            </div>
          )
        }
        {
          isChangeOffer && (
            <div>
              <button
                type="button"
                onClick={() => {
                  handleSave();
                }}
                style={{
                  color: '#141b33',
                  cursor: 'pointer',
                  padding: '10px',
                  background: '#ffc324',
                  borderRadius: '4px',
                  border: 'none',
                  outline: 'none'
                }}
              >
                Сохранить
              </button>
            </div>
          )
        }

      </div>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        padding: 20,
        background: '#1b2039',
        borderRadius: 8,
        marginTop: 12
      }}>
        {
          !isLoading && (
            <>
              <div>
                <Label
                  label="Результат проверки"
                  description={isCheck ? "Квалификация пройдена" : view?.promo?.status === 'AWAIT' ? "Квалификация не пройдена" : view?.promo?.status === 'ARCHIVE' && "Комиссия выплачена и находится в архиве"}
                  color={isCheck ? "#79CF5B" : view?.promo?.status === 'AWAIT' && "#FF403D"}
                />
              </div>
              {
                view?.promo?.status === 'AWAIT' && (
                  <div style={{ marginTop: 12 }}>
                    <button
                      type="button"
                      onClick={() => {
                        setIsLoading(true);
                        dispatch(setGetPromoCheckById({
                          id: view.promo?.id
                        }, () => {
                          setIsLoading(false)
                        }))
                      }}
                      style={{
                        color: '#141b33',
                        cursor: 'pointer',
                        padding: '10px',
                        background: '#ffc324',
                        borderRadius: '4px',
                        border: 'none',
                        outline: 'none'
                      }}
                    >
                      Проверить
                    </button>
                  </div>
                )
              }
              {
                view?.promo?.status === 'SUCCESS' && (
                  <div style={{ marginTop: 12, display: 'flex', columnGap: 12 }}>
                    <button
                      type="button"
                      onClick={() => {
                        setIsLoading(true);
                        dispatch(setPostPromoStatus({
                          id: view.promo?.id
                        }, () => {
                          setIsLoading(false)
                        }))
                      }}
                      style={{
                        color: '#141b33',
                        cursor: 'pointer',
                        padding: '10px',
                        background: '#ffc324',
                        borderRadius: '4px',
                        border: 'none',
                        outline: 'none'
                      }}
                    >
                      Архивировать
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setIsLoading(true);
                        dispatch(setPostPromoFixed({
                          id: view.promo?.id
                        }, () => {
                          setIsLoading(false)
                        }))
                      }}
                      style={{
                        color: '#141b33',
                        cursor: 'pointer',
                        padding: '10px',
                        background: '#ffc324',
                        borderRadius: '4px',
                        border: 'none',
                        outline: 'none'
                      }}
                    >
                      Фиксировать
                    </button>
                  </div>
                )
              }
            </>
          )
        }

        {
          isLoading && <CircularProgress />
        }
      </div>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        padding: 20,
        background: '#1b2039',
        borderRadius: 8,
        marginTop: 12
      }}>
        <Table
          columns={columns}
          data={transactions.data}
          handleEventDoubleClick={(v) => {
            router.history.push(`/partners/promo/view/${view.promo.id}/transaction/${v.id}/`)
          }}
        />
      </div>
    </div>
  )
}

export default PartnersPromoView;