import { putChangeDeleteMedia } from '../api/putChangeDeleteMedia';
import { setGetMediaById } from './setGetMediaById';

export const setPutChangeDeleteMedia = (params, callback) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await putChangeDeleteMedia(params);
            
            alert('Сохранено')
          
            dispatch(setGetMediaById(params));
        } catch(error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}