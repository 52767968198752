import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex', 
        flexDirection: 'column'
    },
    rootAuth: {
        height: 32, 
        padding: 8, 
        background: '#222f52', 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center'
    },
    wrapAuth: {
        display: 'flex', 
        alignItems: 'center'
    },
    logo: {
        width: 210
    },
    wrapDatePicker: {
        display: 'flex', 
        alignItems: 'center'
    },
    inputDate: {
        padding: 10,
        border: 'none',
        color: '#fff',
        background: '#222f52',
        outline: 'none',
        width: 130,
        userSelect: 'none',
        height: '30px'
    },
    tire: {
        color: '#fff'
    },
    exit: {
        color: '#fff', 
        cursor: 'pointer'
    },
    body: {
        height: 'calc(100vh - 48px)', 
        display: 'flex'
    },
    navigation: {
        height: '100%', 
        minWidth: '272px', 
        background: '#18203a'   
    },
    routes: {
        background: '#141b33', 
        width: '100%',
        overflow: 'scroll'
    },
    menu: {
        width: 30, 
        height: 30, 
        fill: '#fff', 
        cursor: 'pointer',
        display: 'none'
    }
}));

const getDate = (item) => {
    const date = new Date(item);

    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    const month = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
    const year = date.getFullYear();

    const now_date = year+'-'+month+'-'+day;
    return now_date;
}

export {
    useStyles,
    getDate
}