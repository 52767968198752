import { types } from './types';

export const setGetDashboardVisiteAction = (payload) => {
  return {
    type: types.SET_GET_DASHBOARD_VISITE,
    payload
  }
}

export const setGetDashboardReplenishmentAction = (payload) => {
  return {
    type: types.SET_GET_DASHBOARD_REPLENISHMENT,
    payload
  }
}

export const setGetDashboardWithdrawalsAction = (payload) => {
  return {
    type: types.SET_GET_DASHBOARD_WITHDRAWALS,
    payload
  }
}