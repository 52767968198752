import React from 'react';
import Title from '../../../../../../@casino/ui/title';
import { useForm } from "react-hook-form";
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useRouter } from '../../../../../../@casino/useRouter';
import { useDispatch, useSelector } from 'react-redux';
import { setGetBrandsById } from '../../../../../store/brands/thunk/setGetBrandsById';
import { setPutBrands } from '../../../../../store/brands/thunk/setPutBrands';
import { setPutChangeActiveBrands } from '../../../../../store/brands/thunk/setPutChangeActiveBrands';

const useStyles = makeStyles(() => ({
  helper: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    position: 'relative',
    display: 'inline-block',
    fontSize: '11px',
    color: 'hsla(0,0%,100%,.7)',
    verticalAlign: 'middle',
    fontFamily: "PT Sans, sans-serif",
    userSelect: 'none'
  },
  text: {
    position: 'relative',
    borderRadius: '4px',
    height: '32px',
    overflow: 'hidden',
    boxShadow: '0 1px 0 0 hsl(0deg 0% 100% / 15%), inset 0 1px 0 0 rgb(0 0 0 / 15%)',
    border: 0,
    background: '#1c2542',
    color: '#fff',
    outline: 'none',
    paddingLeft: 8,
  },
  wrapLabel: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 12,
    marginBottom: 24,
  },
  beginBonusLabel: {
    width: '32%',
  },
}))

const AffiliateBrandsCreate = () => {
  const classes = useStyles();
  const router = useRouter();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(setGetBrandsById({
      id: router.query.id
    }))
  }, [])

  const brand = useSelector((state) => state.brands.view);

  const { handleSubmit, register, control,reset } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      name: "",
    }
  });

  React.useEffect(() => {
    reset({
      ...brand
    })
  }, [brand])

  const onSubmit = (store) => {
    dispatch(setPutBrands(store, () => {
      router.history.push('/affiliate/brands/list')
    }))
  }

  const handleClickChangeActive = () => {
    dispatch(setPutChangeActiveBrands({
      id: brand.id
    }))
  }

  return (
    <div style={{
      padding: '0 20px'
    }}>
      <Title
        label="Редактирование бренда"
        labelBtn="Назад"
        handleClick={() => router.history.push('/affiliate/brands/list')}
      />

      <div style={{
        display: 'flex',
        flexDirection: 'column',
        padding: 20,
        background: '#1b2039',
        borderRadius: 8,
      }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="name" className={clsx(classes.wrapLabel, classes.beginBonusLabel)}>
            <span className={classes.helper}>Имя</span>
            <input
              {...register('name')}
              type="text"
              id="name"
              placeholder='Введите имя'
              className={classes.text}
            />
          </label>
          <label htmlFor="url" className={clsx(classes.wrapLabel, classes.beginBonusLabel)}>
            <span className={classes.helper}>URL</span>
            <input
              {...register('url')}
              type="text"
              id="name"
              placeholder='Введите url'
              className={classes.text}
            />
          </label>

          <label htmlFor="description" className={clsx(classes.wrapLabel, classes.beginBonusLabel)}>
            <span className={classes.helper}>Описание</span>
            <input
              {...register('description')}
              type="text"
              id="name"
              placeholder='Введите описание'
              className={classes.text}
            />
          </label>

          <label htmlFor="currencies" className={clsx(classes.wrapLabel, classes.beginBonusLabel)}>
            <span className={classes.helper}>Валюты</span>
            <input
              {...register('currencies')}
              type="text"
              id="name"
              placeholder='Введите валюты'
              className={classes.text}
            />
          </label>
          <div style={{display: 'flex', gap: 12}}>
            <button
              type="submit"
              style={{
                color: '#141b33',
                cursor: 'pointer',
                padding: '10px',
                background: '#ffc324',
                borderRadius: '4px',
                border: 'none',
                outline: 'none'
              }}
            >
              Сохранить
            </button>
            <button
              type="button"
              onClick={handleClickChangeActive}
              style={{
                color: '#141b33',
                cursor: 'pointer',
                padding: '10px',
                background: '#ffc324',
                borderRadius: '4px',
                border: 'none',
                outline: 'none'
              }}
            >
              {brand.is_active ? 'Отключить' : 'Включить'}
            </button>
          </div>
          
        </form>
      </div>
    </div>
  )
}

export default AffiliateBrandsCreate;