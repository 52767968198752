import { setGetDashboardVisiteAction } from '../action';
import { getDashboardVisite } from '../api/getDashboardVisite';

export const setGetDashboardVisite = (params, callback) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await getDashboardVisite(params);
            dispatch(setGetDashboardVisiteAction(data.data))
        } catch(error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}