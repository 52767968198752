import React from 'react';
import Title from '../../../../../../@casino/ui/title';
import { useDispatch, useSelector } from 'react-redux';
import { setGetPartners } from '../../../../../store/partners/thunk/setGetPartners';
import Table from '../../../../table';
import { format } from 'date-fns';
import { useRouter } from '../../../../../../@casino/useRouter';
import Tab from './Tab';
import { setGetPartnersAction } from '../../../../../store/partners/action';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  searchGamer: {
    padding: 10,
    background: '#141b33',
    paddingLeft: 10,
    border: '1px solid #141b33',
    color: '#fff',
    outline: 'none',
    margin: 10,
    width: '97.3%'
  },
  wrapBlock: {
    padding: 20,
    background: '#1b2039',
    borderRadius: 8,
    marginBottom: 12,
  },
}));

const PartnersList = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const classes = useStyles();

  const [tab, setTab] = React.useState('await');
  const [search, setSearch] = React.useState("");
  const [timer, setTimer] = React.useState();

  const handleGetUser = () => {
      clearTimeout(timer); 
      dispatch(setGetPartnersAction({
        data: [],
        currentPage: partners?.currentPage,
        currentRowsPerpage: partners?.currentRowsPerPage,
        total: partners?.total,
        totalPage: partners?.totalPage
      }))
      setTimer(setTimeout(function() { 
        dispatch(setGetPartners({
          status: tab,
          page: 0,
          rowsPerPage: 10,
          search
        }));
      }, 800));
  }
  const partners = useSelector((state) => state.partners.list);

  React.useEffect(() => {
    handleGetUser()
  }, [tab, search])


  const columns = [{
    label: 'Дата создания',
    name: 'created_at',
    selector: (value) => format(new Date(Number(value)), 'dd.MM.yyyy HH:mm')
  }, {
    label: 'Статус',
    name: 'status',
    align: 'left',
    selector: (value) => value === 'await' ? <span style={{ color: 'gold' }}>ОЖИДАНИЕ</span> : <span style={{ color: '#2fff00' }}>АКТИВНЫЙ</span>
  }, {
    label: 'Логин',
    name: 'login',
    align: 'left',
  }, {
    label: 'Менеджер',
    name: 'manager',
    align: 'left',
  }, {
    label: 'Имя',
    name: 'name',
    align: 'left',
  }, {
    label: 'Телеграм',
    name: 'telegram',
    align: 'left',
  }, {
    label: 'Skype',
    name: 'skype',
    align: 'left',
  }]

  const title = `Список ${tab === 'await' ? 'ожидающих' : 'активных'} партнеров`

  const handleEventDoubleClick = (v) => {
    router.history.push(`/partners/view/${v.id}`)
  }

  const handleChangeRowsPerPage = (rowsPerPage) => {
    dispatch(setGetPartnersAction({
      data: [],
      currentPage: partners?.currentPage,
      currentRowsPerpage: partners?.currentRowsPerPage,
      total: partners?.total,
      totalPage: partners?.totalPage
    }));
    dispatch(setGetPartners({
      status: tab,
      page: 1,
      rowsPerPage,
      search
    }));
  }

  const handleChangePage = ({
    rowsPerPage,
    page
  }) => {
    dispatch(setGetPartnersAction({
      data: [],
      currentPage: partners?.currentPage,
      currentRowsPerpage: partners?.currentRowsPerPage,
      total: partners?.total,
      totalPage: partners?.totalPage,
    }))
    dispatch(setGetPartners({
      status: tab,
      page,
      rowsPerPage,
      search
    }));
  }

  return (
    <div style={{
      padding: '0 20px'
    }}>
      <Title
        label={title}
      />
      <div style={{ display: 'flex', columnGap: 12, marginBottom: 12 }}>
        <Tab
          tab={tab}
          setTab={setTab}
          title="Активные"
          value="active"
        />
        <Tab
          tab={tab}
          setTab={setTab}
          title="Ожидают"
          value="await"
        />
      </div>
      <div className={classes.wrapBlock}>
        <label htmlFor="search">
          <input
            type="text"
            name="search"
            id="search"
            value={search}
            onChange={(v) => setSearch(v.target.value)}
            className={classes.searchGamer}
            placeholder="Поиск партнера по логину"
          />
        </label>
      </div>
      <div className={classes.wrapBlock}>
        <Table
          columns={columns}
          data={partners?.data}
          handleEventDoubleClick={handleEventDoubleClick}
          totalPage={partners?.total}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleChangePage={handleChangePage}
        />
      </div>
    </div>
  )
}

export default PartnersList;