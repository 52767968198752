import { types } from './types';

export const setGetPlayersAction = (payload) => {
  return {
    type: types.SET_GET_PLAYERS,
    payload,
  }
}

export const setGetPlayersByIdAction = (payload) => {
  return {
    type: types.SET_GET_PLAYERS_BY_ID,
    payload
  }
}