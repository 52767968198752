import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Typography } from 'antd';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        padding: '5px 10px',
        background: '#1b2039',
        borderRadius: 8,
        cursor: 'pointer'
    },
    color_active: {
        color: '#FFFFFF'
    },
    color_no_active: {
        color: "#ACACAC"
    }
}))

const Tab = ({
    tab,
    handleChangeStatus,
    label,
    value
}) => {
    const classes = useStyles();

    return (
        <div
            className={classes.root}
            data-status={value}
            onClick={handleChangeStatus}
        >
            <Typography className={tab === value? classes.color_active : classes.color_no_active}>{label}</Typography>
        </div>
    )
}

export default Tab;