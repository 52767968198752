import React from 'react';
import Title from '../../../../../../@casino/ui/title';
import { useDispatch, useSelector } from 'react-redux';
import { setGetPayments } from '../../../../../store/payments/thunk/setGetPayments';
import Table from '../../../../table';
import { format } from 'date-fns';
import { useRouter } from '../../../../../../@casino/useRouter';
import { setGetPaymentsAction } from '../../../../../store/payments/action';
import { Typography } from 'antd';

const FinancePaymentsList = () => {
  const dispatch = useDispatch();
  const router = useRouter();

  React.useEffect(() => {
    dispatch(setGetPayments({
      page: 0,
      rowsPerPage: 10
    }));
  }, []);

  const payments = useSelector((state) => state.payments.list);

  const handleGetStatus = (status) => {
    const value = status?.toLowerCase();
    switch (value) {
      case "success": return "Подтверждено";
      case "canceled": return "Отменено";
    }
  }

  const columns = [{
    label: 'Дата создания',
    name: 'created_at',
    selector: (value) => format(new Date(Number(value)), 'dd.MM.yyyy')
  }, {
    label: 'Партнер',
    name: 'partner_id',
    align: 'left',
    multiple: true,
    selector: (value) => (
      <Typography
        style={{ cursor: 'pointer', textDecoration: 'underline', color: '#fff' }}
        onClick={() => router.history.push(`/partners/view/${value.partner_id}`)}
      >
        {value.login}
      </Typography>
    )
  }, {
    label: 'Сумма',
    name: 'amount',
    align: 'left',
  }, {
    label: 'Валюта',
    name: 'currency',
    align: 'left',
  }, {
    label: 'Тип операции',
    name: 'operation_type',
    align: 'left',
  }, {
    label: 'Статус',
    name: 'status',
    align: 'left',
    selector: handleGetStatus
  }];

  const handleChangeRowsPerPage = (rowsPerPage) => {
    dispatch(setGetPaymentsAction({
      data: [],
      currentPage: 0,
      currentRowsPerpage: rowsPerPage,
      total: payments?.total,
      totalPage: payments?.totalPage
    }))
    dispatch(setGetPayments({
      page: 0,
      rowsPerPage
    }));
  }

  const handleChangePage = ({
    rowsPerPage,
    page
  }) => {
    dispatch(setGetPaymentsAction({
      data: [],
      currentPage: page,
      currentRowsPerpage: rowsPerPage,
      total: payments?.total,
      totalPage: payments?.totalPage
    }))
    dispatch(setGetPayments({
      page,
      rowsPerPage
    }));
  }

  const handleEventDoubleClick = (value) => {
    router.history.push(`/finance/payments/view/${value.id}`)
  }

  return (
    <div style={{
      padding: '0 20px'
    }}>
      <Title label="Платежи" />
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        padding: 20,
        background: '#1b2039',
        borderRadius: 8,
      }}>
        <Table
          columns={columns}
          data={payments?.data || []}
          handleEventDoubleClick={handleEventDoubleClick}
          totalPage={payments?.total}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleChangePage={handleChangePage}
        />
      </div>
    </div>
  )
}

export default FinancePaymentsList;