import { types } from './types';

export const setGetBalanceCorrectionAction = (payload) => {
  return {
    type: types.SET_GET_BALANCE_CORRECTION,
    payload,
  }
}

export const setGetBalanceCorrectionByIdAction = (payload) => {
  return {
    type: types.SET_GET_BALANCE_CORRECTION_BY_ID,
    payload
  }
}