import React from 'react';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, useWatch } from 'react-hook-form';
import { format } from 'date-fns';
import { useRouter } from '../../../../../../../@casino/useRouter';
import { setGetOperators } from '../../../../../../store/operators/thunk/setGetOperators';
import { setGetPartnersById } from '../../../../../../store/partners/thunk/setGetPartnersById';
import { setPutPartners } from '../../../../../../store/partners/thunk/setPutPartners';
import { setPostToken } from '../../../../../../store/authorization/thunk/setPostTokenUser';

const useStyles = makeStyles(() => ({
    helper: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        position: 'relative',
        display: 'inline-block',
        fontSize: '11px',
        color: 'hsla(0,0%,100%,.7)',
        verticalAlign: 'middle',
        fontFamily: "PT Sans, sans-serif",
        userSelect: 'none'
    },
    text: {
        position: 'relative',
        borderRadius: '4px',
        height: '32px',
        overflow: 'hidden',
        boxShadow: '0 1px 0 0 hsl(0deg 0% 100% / 15%), inset 0 1px 0 0 rgb(0 0 0 / 15%)',
        border: 0,
        background: '#1c2542',
        color: '#fff',
        outline: 'none',
        paddingLeft: 8,
    },
    wrapLabel: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 12,
        marginBottom: 24,
    },
    beginBonusLabel: {
        width: '32%',
    },
}))


const Information = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const router = useRouter();

    const { handleSubmit, register, control, reset } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: {
            name: "",
        }
    });

    React.useEffect(() => {
        dispatch(setGetOperators({
            page: 0,
            rowsPerPage: 210000
        }));
        dispatch(setGetPartnersById({
            id: router.query.id
        }))
    }, [])

    const view = useSelector((state) => state.partners.view);
    const operators = useSelector(state => state.operator.list);

    React.useEffect(() => {
        if (view?.created_at) {
            reset({
                ...view,
                created_at: format(new Date(Number(view.created_at)), 'dd.MM.yyyy HH:mm')
            })
        }
    }, [view])

    const onSubmit = (store) => {
        dispatch(setPutPartners(store))
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <label htmlFor="name" className={clsx(classes.wrapLabel, classes.beginBonusLabel)}>
                <span className={classes.helper}>Аффилиат</span>
                <input
                    {...register('login_partner')}
                    type="text"
                    id="name"
                    placeholder='-'
                    className={classes.text}
                    disabled
                />
            </label>
            <label htmlFor="name" className={clsx(classes.wrapLabel, classes.beginBonusLabel)}>
                <span className={classes.helper}>Дата регистрации</span>
                <input
                    {...register('created_at')}
                    type="text"
                    id="name"
                    placeholder='Введите имя'
                    className={classes.text}
                    disabled
                />
            </label>

            <label htmlFor="name" className={clsx(classes.wrapLabel, classes.beginBonusLabel)}>
                <span className={classes.helper}>Имя</span>
                <input
                    {...register('name')}
                    type="text"
                    id="name"
                    placeholder='Введите имя'
                    className={classes.text}
                />
            </label>
            <label htmlFor="login" className={clsx(classes.wrapLabel, classes.beginBonusLabel)}>
                <span className={classes.helper}>Логин</span>
                <input
                    {...register('login')}
                    type="text"
                    id="name"
                    placeholder='Введите логин'
                    className={classes.text}
                    disabled
                />
            </label>
            <div style={{ display: 'flex', flexDirection: 'column', rowGap: 25 }}>
                <select
                    {...register('status')}
                    style={{ height: '34px', width: '32%' }}
                    className={classes.text}
                >
                    <option value="" style={{ display: 'none' }}>Выбрать статус</option>
                    <option value="await">Ожидание</option>
                    <option value="active">Активный</option>
                </select>

                <select
                    {...register('manager')}
                    style={{ height: '34px', width: '32%' }}
                    className={classes.text}
                >
                    <option value="" style={{ display: 'none' }}>Выбрать оператора</option>
                    {
                        operators?.data?.map((item) => <option value={item.login}>{item.login}</option>)
                    }
                </select>
            </div>


            <label htmlFor="telegram" className={clsx(classes.wrapLabel, classes.beginBonusLabel)}>
                <span className={classes.helper}>Телеграм</span>
                <input
                    {...register('telegram')}
                    type="text"
                    id="name"
                    placeholder='Введите телеграм'
                    className={classes.text}
                />
            </label>

            <label htmlFor="skype" className={clsx(classes.wrapLabel, classes.beginBonusLabel)}>
                <span className={classes.helper}>Skype</span>
                <input
                    {...register('skype')}
                    type="text"
                    id="name"
                    placeholder='Введите skype'
                    className={classes.text}
                />
            </label>
            <div style={{ display: 'flex', columnGap: 12}}>
                <button
                    type="submit"
                    style={{
                        color: '#141b33',
                        cursor: 'pointer',
                        padding: '10px',
                        background: '#ffc324',
                        borderRadius: '4px',
                        border: 'none',
                        outline: 'none'
                    }}
                >
                    Сохранить
                </button>
                
                <button
                    type="button"
                    onClick={() => {
                        dispatch(setPostToken({
                            id: view.user_id
                        }))
                    }}
                    style={{
                        color: '#141b33',
                        cursor: 'pointer',
                        padding: '10px',
                        background: '#ffc324',
                        borderRadius: '4px',
                        border: 'none',
                        outline: 'none'
                    }}
                >
                    Войти под партнером
                </button>
            </div>
        </form>
    )
}

export default Information;