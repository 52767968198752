import { types } from './types';

export const setGetPromoAction = (payload) => {
  return {
    type: types.SET_GET_PROMO,
    payload,
  }
}

export const setGetPromoByIdAction = (payload) => {
  return {
    type: types.SET_GET_PROMO_BY_ID,
    payload,
  }
}

export const setGetPromoUsersCountAction = (payload) => {
  return {
    type: types.SET_GET_PROMO_COUNT_USERS,
    payload
  }
}

export const setGetTransactionByIdAction = (payload) => {
  return {
    type: types.SET_GET_PROMO_TRANSACTION,
    payload
  }
}

export const setGetPromoByPartnerIdAction = (payload) => {
  return {
    type: types.SET_GET_PROMO_BY_PARTNER_ID,
    payload
  }
}