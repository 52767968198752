import React from 'react';
import Title from '../../../../../../@casino/ui/title';
import { useRouter } from '../../../../../../@casino/useRouter';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { setGetBrands } from '../../../../../store/brands/thunk/setGetBrands';
import { setPostBonusCode } from '../../../../../store/bonuscode/thunk/setPostBonusCode';

const useStyles = makeStyles(() => ({
  helper: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    position: 'relative',
    display: 'inline-block',
    fontSize: '11px',
    color: 'hsla(0,0%,100%,.7)',
    verticalAlign: 'middle',
    fontFamily: "PT Sans, sans-serif",
    userSelect: 'none'
  },
  text: {
    position: 'relative',
    borderRadius: '4px',
    height: '32px',
    overflow: 'hidden',
    boxShadow: '0 1px 0 0 hsl(0deg 0% 100% / 15%), inset 0 1px 0 0 rgb(0 0 0 / 15%)',
    border: 0,
    background: '#1c2542',
    color: '#fff',
    outline: 'none',
    paddingLeft: 8,
  },
  wrapLabel: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 12,
    marginBottom: 24,
  },
  beginBonusLabel: {
    width: '32%',
  },
}))

const PartnersBonusCode = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const router = useRouter();

  const { handleSubmit, register, control } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
    }
  });

  const onSubmit = (store) => {
    dispatch(setPostBonusCode(store, () => router.history.push('/partners/bonus-code/list')));
  }

  return (
    <div style={{
      padding: '0 20px'
    }}>
      <Title 
        label="Создание бонус-кода"
        labelBtn="Назад"
        handleClick={() => router.history.push('/partners/bonus-code/list')}  
      />
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        padding: 20,
        background: '#1b2039',
        borderRadius: 8,
      }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="promocode" className={clsx(classes.wrapLabel, classes.beginBonusLabel)}>
            <span className={classes.helper}>Промокод</span>
            <input
              {...register('promocode')}
              type="text"
              id="promocode"
              className={classes.text}
            />
          </label>
          <label htmlFor="bonus_code" className={clsx(classes.wrapLabel, classes.beginBonusLabel)}>
            <span className={classes.helper}>Бонус код</span>
            <input
              {...register('bonus_code')}
              type="text"
              id="bonus_code"
              className={classes.text}
            />
          </label>
          <label htmlFor="partner_id" className={clsx(classes.wrapLabel, classes.beginBonusLabel)}>
            <span className={classes.helper}>ID Партнера</span>
            <input
              {...register('partner_id')}
              type="text"
              id="partner_id"
              className={classes.text}
            />
          </label>
          
          <label htmlFor="description" className={clsx(classes.wrapLabel, classes.beginBonusLabel)}>
            <span className={classes.helper}>Описание</span>
            <textarea
              {...register('description')}
              type="text"
              id="description"
              className={classes.text}
              style={{width: 500, height: 200}}
            />
          </label>
          <button 
            type="submit" 
            style={{
              color: '#141b33',
              cursor: 'pointer',
              padding: '10px',
              background: '#ffc324',
              borderRadius: '4px',
              border: 'none',
              outline: 'none'
            }}
          >
            Сохранить
          </button>
        </form>
      </div>
    </div>
  )
}

export default PartnersBonusCode;