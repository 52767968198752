import { types } from './types';

const initialState = {
    list: {
        data: [],
        currentPage: 0,
        currentRowsPerpage: 0,
        total: 0,
        totalPage: 0
    },
    view: {
        promo: {},
        offer: {},
        partner: {}
    },
    users_count: 0,
    transaction: [],
    partner: {
        data: [],
        currentPage: 0,
        currentRowsPerpage: 0,
        total: 0,
        totalPage: 0
    },
}

export function promoReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_GET_PROMO:
            return { ...state, list: action.payload };
        case types.SET_GET_PROMO_BY_ID:
            return { ...state, view: action.payload };
        case types.SET_GET_PROMO_COUNT_USERS:
            return { ...state, users_count: action.payload };
        case types.SET_GET_PROMO_TRANSACTION:
            return { ...state, transaction: action.payload };
        case types.SET_GET_PROMO_BY_PARTNER_ID:
            return { ...state, partner: action.payload };
        default:
            return state
    }
}