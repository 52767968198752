import { setGetTransactionsListAction } from '../action';
import { getTransactionsList } from '../api/getTransactionsList';

export const setGetTransactionsList = (params, callback) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await getTransactionsList(params);

            dispatch(setGetTransactionsListAction({
                data: data.data,
                current: 0,
                total: 0
            }))

            if (callback) {
                callback();
            }
        } catch(error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}