import React from 'react';
import Title from '../../../../../@casino/ui/title';

const ReportPartnersStatistics = () => {

  return (
    <div>
      <Title label="Статистика партнеров" />
    </div>
  )
}

export default ReportPartnersStatistics;