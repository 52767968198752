import React from 'react';
import Table from '../../../../../table';
import { format } from 'date-fns';
import { useRouter } from '../../../../../../../@casino/useRouter';
import { useDispatch, useSelector } from 'react-redux';
import { setGetPromoByPartnerId } from '../../../../../../store/promo/thunk/setGetPromoByPartnerId';
import { setGetPromoByPartnerIdAction } from '../../../../../../store/promo/action';
import { Typography } from '@material-ui/core';

const PromoPartners = ({
    partner_id
}) => {
    const router = useRouter();
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(setGetPromoByPartnerId({
            page: 0,
            rowsPerPage: 10,
            partner_id
        }))
    }, [])
    const promo = useSelector((state) => state.promo.partner);

    const columns = [{
        label: 'Дата создания',
        name: 'created_at',
        selector: (value) => format(new Date(Number(value)), 'dd.MM.yyyy HH:mm')
    }, {
        label: 'Дата окончания',
        name: 'execute_date',
        selector: (value) => format(new Date(Number(value)), 'dd.MM.yyyy HH:mm')
    }, {
        label: 'Поток',
        name: 'stream',
        align: 'left',
        multiple: true,
        selector: (value) => <Typography
        style={{cursor: 'pointer', textDecoration: 'underline'}}
        onClick={() => router.history.push(`/partners/promo/view/${value.id}`)}>{value.label}</Typography>
    },  {
        label: 'Оффер',
        name: 'offer_id',
        align: 'left',
        multiple: true,
        selector: (value) => <Typography
            style={{cursor: 'pointer', textDecoration: 'underline'}}
            onClick={() => router.history.push(`/affiliate/comission/view/${value.offer_id}`)}>{value.name}</Typography>
    }];

    return (
        <div>
            <Table
                columns={columns}
                data={promo?.data}
                handleEventDoubleClick={(v) => {
                    router.history.push(`/partners/promo/view/${v.id}`)
                }}
                totalPage={promo?.total}
                handleChangeRowsPerPage={(rowsPerPage) => {
                    dispatch(setGetPromoByPartnerIdAction({
                        data: [],
                        currentPage: promo?.pagination?.currentPage,
                        currentRowsPerpage: promo?.pagination?.currentRowsPerPage,
                        total: promo?.pagination?.total,
                        totalPage: promo?.pagination?.totalPage
                    }))
                    dispatch(setGetPromoByPartnerId({
                        page: 1,
                        rowsPerPage,
                        partner_id
                    }));
                }}
                handleChangePage={({
                    rowsPerPage,
                    page
                }) => {
                    dispatch(setGetPromoByPartnerIdAction({
                        data: [],
                        currentPage: promo?.pagination?.currentPage,
                        currentRowsPerpage: promo?.pagination?.currentRowsPerPage,
                        total: promo?.pagination?.total,
                        totalPage: promo?.pagination?.totalPage
                    }))
                    dispatch(setGetPromoByPartnerId({
                        page,
                        rowsPerPage,
                        partner_id
                    }));
                }}
            />
        </div>
    )
}

export default PromoPartners;