import { setGetTransactionByIdAction } from '../action';
import { getTransactionById } from '../api/getTransactionById';

export const setGetPromoTransactionById = (params, callback) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await getTransactionById(params);
            console.log(data)
            dispatch(setGetTransactionByIdAction(data.data))
        } catch(error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}