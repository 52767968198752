import { postTokenUser } from '../api/postTokenUser';

export const setPostToken = (params) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await postTokenUser(params);

            if (data.status === 'success') {
                console.log(data);
                window.open(data.url, '_target')
            }
          
        } catch(error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}