import React from 'react';
import Title from '../../../../../../@casino/ui/title';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from '../../../../../../@casino/useRouter';
import { setGetBalanceCorrectionById } from '../../../../../store/balance-correction/thunk/setGetBalanceCorrectionById';
import Label from './label';
import { format } from 'date-fns';

const FinanceBalanceCorrenction = () => {
  const dispatch = useDispatch();
  const router = useRouter();

  React.useEffect(() => {
    dispatch(setGetBalanceCorrectionById({
      id: router?.query?.id
    }))
  }, []);

  const view = useSelector((state) => state.balanceCorrection.view);

  const handleGetAction = (action) => {
    const value = action?.toString().toLowerCase();
    switch(value) {
      case "add": return "Добавление";
      case "subtract": return "Списание";
    }
  }

  return (
    <div style={{
      padding: '0 20px',
    }}>
      <Title 
        label="Коррекция балансов"
        labelBtn="Назад"
        handleClick={() => router.history.push('/finance/balance_correnctions/list')} 
      />
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        padding: 20,
        background: '#1b2039',
        borderRadius: 8,
        rowGap: 10
      }}>
        <Label
          label="Дата создания"
          description={view?.created_at ? format(new Date(Number(view.created_at)), 'dd.MM.yyyy HH:mm') : '-'}
        />
        <Label
          label="Действие"
          description={handleGetAction(view?.action)}
        />
        <Label
          label="Сумма"
          description={view?.amount}
        />
        <Label
          label="Валюта"
          description={view?.currency}
        />
        <Label
          label="Партнер"
          description={view?.partner_login}
          handleClick={() => router.history.push(`/partners/view/${view?.partner_id}`)}
        />
        <Label
          label="Оператор"
          description={view?.operator_login}
          handleClick={() => router.history.push(`/settings/operators/view/${view?.operator_id}`)}
        />
        <Label
          label="Причина"
          description={view?.reason}
        />
      </div>
    </div>
  )
}

export default FinanceBalanceCorrenction;