import React from 'react';
import Mobile from './mobile';
import Desktop from './desktop';

const App = () => {
    // if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) { 
    //     return (
    //         <Mobile />
    //     )
    // } 
    // else {
        return <Desktop />
    // }
}

export default App