import {types} from './types';

const initialState = {
    list: {
        data: [],
        current: 0,
        total: 0
    },
    view: {}
}

export function inviteReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_GET_INVITE:
            return { ...state, list: action.payload};
        case types.SET_GET_INVITE_BY_ID:
            return { ...state, view: action.payload};
        default:
            return state
    }
}