import { putOffer } from '../api/putOffer';

export const setPutOffer = (params, callback) => {
    return async (dispatch, getState) => {
        try {
            await putOffer(params);

            if (callback) {
                callback();
            }
        } catch(error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}