import { postRole } from '../api/postRole';

export const setPostRole = (params) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await postRole(params);

            alert('Сохранено')
          
        } catch(error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}