import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    helper: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        position: 'relative',
        display: 'inline-block',
        fontSize: '11px',
        color: 'hsla(0,0%,100%,.7)',
        verticalAlign: 'middle',
        fontFamily: "PT Sans, sans-serif",
        userSelect: 'none'
    },
    text: {
        position: 'relative',
        borderRadius: '4px',
        height: '32px',
        overflow: 'hidden',
        boxShadow: '0 1px 0 0 hsl(0deg 0% 100% / 15%), inset 0 1px 0 0 rgb(0 0 0 / 15%)',
        border: 0,
        background: '#1c2542',
        color: '#fff',
        outline: 'none',
        paddingLeft: 8,
    },
    wrapLabel: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 12,
        marginBottom: 24,
    },
    beginBonusLabel: {
        width: '100%',
    },
}))
const FixedPerPlayer = ({
    idx,
    idxRules,
    idxReward,
    register,
}) => {
    const classes = useStyles();

    return (
        <div>
            <label htmlFor={`ruleGroup[${idx}].rules[${idxRules}].rewards[${idxReward}].rule.per_qualified_player_reward`} className={clsx(classes.wrapLabel, classes.beginBonusLabel)}>
                <span className={classes.helper}>Per qualified player reward</span>
                <input
                    {...register(`ruleGroup[${idx}].rules[${idxRules}].rewards[${idxReward}].rule.per_qualified_player_reward`)}
                    type="text"
                    id={`ruleGroup[${idx}].rules[${idxRules}].rewards[${idxReward}].rule.per_qualified_player_reward`}
                    placeholder='Введите per qualified player reward'
                    className={classes.text}
                />
            </label>
            <label htmlFor={`ruleGroup[${idx}].rules[${idxRules}].rewards[${idxReward}].rule.max_fixed_reward`} className={clsx(classes.wrapLabel, classes.beginBonusLabel)}>
                <span className={classes.helper}>max_fixed_reward</span>
                <input
                    {...register(`ruleGroup[${idx}].rules[${idxRules}].rewards[${idxReward}].rule.max_fixed_reward`)}
                    type="text"
                    id={`ruleGroup[${idx}].rules[${idxRules}].rewards[${idxReward}].rule.max_fixed_reward`}
                    placeholder='Введите max_fixed_reward'
                    className={classes.text}
                />
            </label>
        </div>
    )
}

export default FixedPerPlayer;