import { putWithdrawalsStatus } from '../api/putWithdrawalsStatus';
import { setGetWithdrawalsById } from './setGetWithdrawalsById';

export const setPutWithdrawalsStatus = (params, callback) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await putWithdrawalsStatus(params);

            dispatch(setGetWithdrawalsById({
                id: params.id
              }))
            if (callback) {
                callback();
            }
        } catch(error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}