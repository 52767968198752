import { types } from './types';

export const setGetBonusCodeAction = (payload) => {
  return {
    type: types.SET_GET_BONUS_CODE,
    payload,
  }
}

export const setGetBonusCodeByIdAction = (payload) => {
  return {
    type: types.SET_GET_BONUS_CODE_BY_ID,
    payload,
  }
}