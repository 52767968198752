import { Typography } from 'antd';
import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        padding: '5px 10px',
        background: '#1b2039',
        borderRadius: 8,
        cursor: 'pointer'
    },
    tab_active: {
        color: '#FFFFFF'
    },
    tab_no_active: {
        color: '#ACACAC'
    }
}))

const Tab = ({
    tab,
    setTab,
    title,
    value
}) => {
    const classes = useStyles();

    const handleChangeTab = () => {
        setTab(value)
    }

    return (
        <div className={classes.root}
            onClick={handleChangeTab}
        >
            <Typography className={tab === value ? classes.tab_active : classes.tab_no_active}>
                {title}
            </Typography>
        </div>
    )
}

export default Tab;