export const Allow = (values) => {
    return {
    ObjectOrganization: { 
        label: 'Объекты организации', 
        values: values?.ObjectOrganization, 
        allow: 'ObjectOrganization' 
    },
    SecurityAdmin: { 
        label: 'Администраторы', 
        values: values?.SecurityAdmin, 
        allow: 'SecurityAdmin'
    },
    InformationPanel: { 
        label: 'Информационная панель', 
        values: values?.InformationPanel, 
        allow: 'InformationPanel' 
    },
    Statistics: { 
        label: 'Статистика', 
        values: values?.Statistics, 
        allow: 'Statistics' 
    },
    FinanceReport: { 
        label: 'Финансовый отчет', 
        values: values?.FinanceReport, 
        allow: 'FinanceReport' 
    },
    JackpotReport: { 
        label: 'Отчет по джекпотам', 
        values: values?.JackpotReport, 
        allow: 'JackpotReport' 
    },
    BonusesReport: { 
        label: 'Отчет по бонусам', 
        values: values?.BonusesReport, 
        allow: 'BonusesReport' 
    },
    BalanceReport: { 
        label: 'Отчет по балансам', 
        values: values?.BalanceReport, 
        allow: 'BalanceReport' 
    },
    EventReport: { 
        label: 'Отчет по событиям', 
        values: values?.EventReport, 
        allow: 'EventReport' 
    },
    Jackpot: { 
        label: 'Джекпоты', 
        values: values?.Jackpot, 
        allow: 'Jackpot' 
    },
    Tournament: { 
        label: 'Турниры', 
        values: values?.Tournament, 
        allow: 'Tournament' 
    },
    Referral: { 
        label: 'Реферальная программа', 
        values: values?.Referral, 
        allow: 'Referral' 
    },
    ReportProvider: { 
        label: 'Отчет по провайдерам', 
        values: values?.ReportProvider, 
        allow: 'ReportProvider' 
    },
    CreditLine: { 
        label: 'Кредитная линия', 
        values: values?.CreditLine, 
        allow: 'CreditLine' 
    },
    HistoryReplenishment: { 
        label: 'История пополнений', 
        values: values?.HistoryReplenishment, 
        allow: 'HistoryReplenishment' 
    },
    GamesSearch: { 
        label: 'Поиск по играм', 
        values: values?.GamesSearch, 
        allow: 'GamesSearch' 
    },
    PaymentSystem: { 
        label: 'Платежные системы',
        values: values?.PaymentSystem, 
        allow: 'PaymentSystem' 
    },
    Withdrawals: { 
        label: 'Выводы', 
        values: values?.Withdrawals, 
        allow: 'Withdrawals' 
    },
    ReplenishmentReport: { 
        label: 'Отчет по платежам', 
        values: values?.ReplenishmentReport, 
        allow: 'ReplenishmentReport' 
    },
    GamerReport: { 
        label: 'Отчет по игрокам и платежным методам', 
        values: values?.GamerReport, 
        allow: 'GamerReport' 
    },
    Gamer: { 
        label: 'Игроки', 
        values: values?.Gamer, 
        allow: 'Gamer' 
    },
    Kyc: { 
        label: 'КУС', 
        values: values?.Kyc, 
        allow: 'Kyc' 
    },
    Bonuses: { 
        label: 'Бонусы', 
        values: values?.Bonuses, 
        allow: 'Bonuses' 
    },
    PresentConfirmation: { 
        label: 'Подтверждение подарков', 
        values: values?.PresentConfirmation, 
        allow: 'PresentConfirmation' 
    },
    CashierUsers: { 
        label: 'Кассиры', 
        values: values?.CashierUsers, 
        allow: 'CashierUsers' 
    },
    CashierReport: { 
        label: 'Отчет по сменам', 
        values: values?.CashierReport, 
        allow: 'CashierReport' 
    },
    CashierFinance: { 
        label: 'Финансовые операции', 
        values: values?.CashierFinance, 
        allow: 'CashierFinance' 
    },
    CashierGroup: { 
        label: 'Группы', 
        values: values?.CashierGroup, 
        allow: 'CashierGroup' 
    },
    Support: { 
        label: 'Тех поддержка', 
        values: values?.Support, 
        allow: 'Support' 
    },
    WithdrawalsOperations: { 
        label: 'Неподтвержденные операции', 
        values: values?.WithdrawalsOperations, 
        allow: 'WithdrawalsOperations' 
    },
    SecurityHistorySignin: { 
        label: 'История входов', 
        values: values?.SecurityHistorySignin, 
        allow: 'SecurityHistorySignin' 
    },
    SecurityHistoryEvent: { 
        label: 'История действий', 
        values: values?.SecurityHistoryEvent, 
        allow: 'SecurityHistoryEvent' 
    },
    Freespin: { 
        label: 'Фриспины', 
        values: values?.Freespin, 
        allow: 'Freespin' 
    },
    ManagementBalance: { 
        label: 'Управление балансами', 
        values: values?.ManagementBalance, 
        allow: 'ManagementBalance' 
    },
    UserBlock: { 
        label: 'Блокировка пользователей', 
        values: values?.UserBlock, 
        allow: 'UserBlock' 
    },
    ChangePassword: { 
        label: 'Смена паролей у пользователей', 
        values: values?.ChangePassword, 
        allow: 'ChangePassword' 
    },
    JackpotIssue: { 
        label: 'Выдача джекпотов', 
        values: values?.JackpotIssue, 
        allow: 'JackpotIssue' 
    },
    SettingsMessanger: { 
        label: 'Мессенджеры', 
        values: values?.SettingsMessanger, 
        allow: 'SettingsMessanger' 
    },
    SettingsExchange: { 
        label: 'Курсы валют', 
        values: values?.SettingsExchange, 
        allow: 'SettingsExchange' 
    },
    KEYS: { 
        label: 'Ключи', 
        values: values?.KEYS, 
        allow: 'KEYS' 
    },
    Pinsale: {
        label: 'Pinsale',
        values: values?.Pinsale,
        allow: 'Pinsale'
    },
    SettingsCodeCountry: {
        label: 'Код страны',
        values: values?.SettingsCodeCountry,
        allow: 'SettingsCodeCountry'
    },
    SettingsCurrency: {
        label: 'Валюта',
        values: values?.SettingsCurrency,
        allow: 'SettingsCurrency'
    }
}}