import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useWatch } from 'react-hook-form';
import ActivityFrom from '../activity_from';
import SignUpAtFrom from '../sign_up_at_from';
import OmitDuplicates from '../omit_duplicates';
import OmitDisabledPlayers from '../omit_disabled_players';
import OmitSelfExclusions from '../omit_self_exclusions';
import OmitUnverifiedPlayers from '../omit_unverified_players';
import PeriodSinceRegistration from '../period_since_registrationperiod_since_registration';
import WithDeposits from '../with_deposits';
import QualifiedPlayersOnly from '../qualified_players_only';
import NotQualifiedPlayersOnly from '../not_qualified_players_only';
import PlayerCountries from '../player_countries';
import SubaffiliateLevel from '../subaffiliate_level';

const useStyles = makeStyles(() => ({
    helper: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        position: 'relative',
        display: 'inline-block',
        fontSize: '11px',
        color: 'hsla(0,0%,100%,.7)',
        verticalAlign: 'middle',
        fontFamily: "PT Sans, sans-serif",
        userSelect: 'none'
    },
    text: {
        position: 'relative',
        borderRadius: '4px',
        height: '32px',
        overflow: 'hidden',
        boxShadow: '0 1px 0 0 hsl(0deg 0% 100% / 15%), inset 0 1px 0 0 rgb(0 0 0 / 15%)',
        border: 0,
        background: '#1c2542',
        color: '#fff',
        outline: 'none',
        paddingLeft: 8,
    },
    wrapLabel: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 12,
        marginBottom: 24,
    },
    beginBonusLabel: {
        width: '100%',
    },
}))

const ModifierItem = ({
    idxModifier,
    idxCondition,
    idxRules,
    idx,
    control,
    register
}) => {
    const classes = useStyles();

    const conditions = [
        { name: 'activity_from', value: 'activity_from' },
        { name: 'omit_duplicates', value: 'omit_duplicates' },
        { name: 'omit_disabled_players', value: 'omit_disabled_players' },
        { name: 'omit_unverified_players', value: 'omit_unverified_players' },
        { name: 'period_since_registration', value: 'period_since_registration' },
        { name: 'with_deposits', value: 'with_deposits' },
    ];

    const type = useWatch({
        control,
        name: `ruleGroup[${idx}].rules[${idxRules}].conditions[${idxCondition}].modifiers[${idxModifier}].type`
    })

    return (
        <div style={{ paddingLeft: 20 }}>
            <label htmlFor="title" className={clsx(classes.wrapLabel, classes.beginBonusLabel)}>
                <span className={classes.helper}>Тип</span>
                <select
                    {...register(`ruleGroup[${idx}].rules[${idxRules}].conditions[${idxCondition}].modifiers[${idxModifier}].type`)}
                    style={{ height: '34px', width: '100%' }}
                    className={classes.text}
                >
                    <option value="" style={{ display: 'none' }}>Выбрать модификатор</option>
                    {
                        conditions.map((item) => <option value={item.name}>{item.name}</option>)
                    }
                </select>
            </label>
            {
                type === 'activity_from' && (
                    <ActivityFrom 
                        register={register}
                        idx={idx}
                        idxRules={idxRules}
                        idxCondition={idxCondition}
                        idxModifier={idxModifier}
                        control={control}
                    />
                )
            }
            {
                type === 'sign_up_at_from' && (
                    <SignUpAtFrom
                        register={register}
                        idx={idx}
                        idxRules={idxRules}
                        idxCondition={idxCondition}
                        idxModifier={idxModifier}
                        control={control}
                    />
                )
            }
            {
                type === 'omit_duplicates' && (
                    <OmitDuplicates 
                        register={register}
                        idx={idx}
                        idxRules={idxRules}
                        idxCondition={idxCondition}
                        idxModifier={idxModifier}
                        control={control}
                    />
                )
            }
            {
                type === 'omit_disabled_players' && (
                    <OmitDisabledPlayers 
                        register={register}
                        idx={idx}
                        idxRules={idxRules}
                        idxCondition={idxCondition}
                        idxModifier={idxModifier}
                        control={control}
                    />
                )
            }
            {
                type === 'omit_self_exclusions' && (
                    <OmitSelfExclusions 
                        register={register}
                        idx={idx}
                        idxRules={idxRules}
                        idxCondition={idxCondition}
                        idxModifier={idxModifier}
                        control={control}
                    />
                )
            }
            {
                type === 'omit_unverified_players' && (
                    <OmitUnverifiedPlayers
                        register={register}
                        idx={idx}
                        idxRules={idxRules}
                        idxCondition={idxCondition}
                        idxModifier={idxModifier}
                        control={control}
                    />
                )
            }
            {
                type === 'period_since_registration' && (
                    <PeriodSinceRegistration 
                        register={register}
                        idx={idx}
                        idxRules={idxRules}
                        idxCondition={idxCondition}
                        idxModifier={idxModifier}
                        control={control}
                    />
                )
            }
            {
                type === 'with_deposits' && (
                    <WithDeposits 
                        register={register}
                        idx={idx}
                        idxRules={idxRules}
                        idxCondition={idxCondition}
                        idxModifier={idxModifier}
                        control={control}
                    />
                )
            }
            {
                type === 'qualified_players_only' && (
                    <QualifiedPlayersOnly 
                        register={register}
                        idx={idx}
                        idxRules={idxRules}
                        idxCondition={idxCondition}
                        idxModifier={idxModifier}
                        control={control}
                    />
                )
            }
            {
                type === 'not_qualified_players_only' && (
                    <NotQualifiedPlayersOnly 
                        register={register}
                        idx={idx}
                        idxRules={idxRules}
                        idxCondition={idxCondition}
                        idxModifier={idxModifier}
                        control={control}
                    />
                )
            }
            {
                type === 'player_countries' && (
                    <PlayerCountries />
                )
            }
             {
                type === 'subaffiliate_level' && (
                    <SubaffiliateLevel 
                        register={register}
                        idx={idx}
                        idxRules={idxRules}
                        idxCondition={idxCondition}
                        idxModifier={idxModifier}
                        control={control}
                    />
                )
            }
        </div>
    )
}

export default ModifierItem;