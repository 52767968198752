import React from 'react';
import Label from '../../view/block/label';
import { Typography } from 'antd';
import { ReactComponent as ArrowRight } from '../../../../../../../@casino/img/right-arrow.svg';
import { ReactComponent as ArrowDown } from '../../../../../../../@casino/img/down-arrow.svg';

const WithDeposits = (modifier) => {
    const [isOpen, setIsOpen] = React.useState(false);

    return (
        <div>
            <div style={{ display: 'flex', columnGap: 12, alignItems: 'center', width: '100%' }}>
                <Typography style={{ color: modifier.validate && !isOpen ? "#79CF5B" : !isOpen ? "#FF403D" : 'hsla(0,0%,100%,.8)', textTransform: 'uppercase', fontSize: 14, fontWeight: 700 }}>Проверка пользователя на количество депозитов</Typography>
                {
                    isOpen && <ArrowDown style={{ height: 12, width: 12, fill: '#FFF', cursor: 'pointer' }} onClick={() => setIsOpen(false)} />
                }
                {
                    !isOpen && <ArrowRight style={{ height: 12, width: 12, fill: '#FFF', cursor: 'pointer' }} onClick={() => setIsOpen(true)} />
                }
            </div>
            {
                isOpen && (
                    <div>
                        <Label
                            label="Кол-во деп. юзера"
                            description={modifier.current}
                            color={modifier.current >= modifier.value ? "#79CF5B" : "#FF403D"}
                        />
                        <Label
                            label="Мин. кол-во деп. в комиссии"
                            description={modifier.value}
                            color={modifier.current >= modifier.value ? "#79CF5B" : "#FF403D"}
                        />
                        <Label
                            label="Статус"
                            description={modifier.current >= modifier.value ? "Модификатор пройден" : "Модификатор не пройден"}
                            color={modifier.current >= modifier.value ? "#79CF5B" : "#FF403D"}
                        />
                    </div>
                )
            }
        </div>
    )
}

export default WithDeposits;