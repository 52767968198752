import React from 'react';
import { useFieldArray } from 'react-hook-form';
import { ReactComponent as Plus } from './icon/plus.svg';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import Rules from './rules';

const useStyles = makeStyles(() => ({
    helper: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        position: 'relative',
        display: 'inline-block',
        fontSize: '11px',
        color: 'hsla(0,0%,100%,.7)',
        verticalAlign: 'middle',
        fontFamily: "PT Sans, sans-serif",
        userSelect: 'none'
    },
    text: {
        position: 'relative',
        borderRadius: '4px',
        height: '32px',
        overflow: 'hidden',
        boxShadow: '0 1px 0 0 hsl(0deg 0% 100% / 15%), inset 0 1px 0 0 rgb(0 0 0 / 15%)',
        border: 0,
        background: '#1c2542',
        color: '#fff',
        outline: 'none',
        paddingLeft: 8,
    },
    wrapLabel: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 12,
        marginBottom: 24,
    },
    beginBonusLabel: {
        width: '100%',
    },
}))

const RuleGroup = ({
    control,
    register
}) => {
    const classes = useStyles();
    
    const { fields, append } = useFieldArray({
        name: 'ruleGroup',
        control
    })


    const handleAddRuleGroup = () => {
        append({
            currency: "",
            rules: [{
                conditions: [],
                qualifiers: [],
                rewards: [],
            }]
        })
    }

    const currency = [
        {name: 'EUR', value: 'EUR'},
        {name: 'RUB', value: 'RUB'},
        {name: 'USD', value: 'USD'},
        { name: 'UZS', value: 'UZS'}, 
        { name: 'KZT', value: 'KZT' }, 
        { name: 'GEL', value: 'GEL'}, 
        { name: 'TRY', value: 'TRY' }
    ]
    
    return (
        <div>
            <div style={{ display: 'flex', columnGap: 10, alignItems: 'center' }}>
                <span style={{ color: '#fff' }}>Groups</span>
                <Plus style={{ fill: '#fff', width: 20, height: 20, cursor: 'pointer' }} onClick={handleAddRuleGroup} />
            </div>
            {
                fields.map((item, key) => {
                    return (
                        <div style={{paddingLeft: 20, marginTop: 10}}> 
                            <span className={classes.helper} style={{fontSize: 16}}>Группа №{key + 1}</span>
                            <div style={{paddingLeft: 20}}>
                                <label htmlFor="title" className={clsx(classes.wrapLabel, classes.beginBonusLabel)}>
                                    <span className={classes.helper}>Валюта</span>
                                    <select
                                    {...register(`ruleGroup[${key}].currency`)}
                                    style={{ height: '34px', width: '100%' }}
                                    className={classes.text}
                                    >
                                    <option value="" style={{ display: 'none' }}>Выбрать Валюту</option>
                                    {
                                        currency.map((item) => <option value={item.value}>{item.name}</option>)
                                    }
                                    </select>
                                </label>
                            </div>
                            <div style={{paddingLeft: 20}}>
                                {
                                    item.rules.map((data, idxRules) => (
                                        <Rules 
                                            data={data}
                                            idx={key}
                                            idxRules={idxRules}
                                            register={register}
                                            control={control}
                                        />
                                    ))
                                }
                            </div>
                        </div>
                    )
                }
                )
            }
        </div>
    )
}

export default RuleGroup;