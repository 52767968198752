import { setGetTransactionsList } from '../../transaction/thunk/setGetTransactionsList';
import { postPromoFixed } from '../api/postPromoFixed';
import { setGetPromoById } from './setGetPromoById';

export const setPostPromoFixed = (params, callback) => {
    return async (dispatch, getState) => {
        try {
            await postPromoFixed(params);
            dispatch(setGetPromoById(params, callback));
            dispatch(setGetTransactionsList({
                promo_id: params.id
            }))
        } catch (error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}