import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    helper: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        position: 'relative',
        display: 'inline-block',
        fontSize: '11px',
        color: 'hsla(0,0%,100%,.7)',
        verticalAlign: 'middle',
        fontFamily: "PT Sans, sans-serif",
        userSelect: 'none'
    },
    text: {
        position: 'relative',
        borderRadius: '4px',
        height: '32px',
        overflow: 'hidden',
        boxShadow: '0 1px 0 0 hsl(0deg 0% 100% / 15%), inset 0 1px 0 0 rgb(0 0 0 / 15%)',
        border: 0,
        background: '#1c2542',
        color: '#fff',
        outline: 'none',
        paddingLeft: 8,
    },
    wrapLabel: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 12,
        marginBottom: 24,
    },
    beginBonusLabel: {
        width: '100%',
    },
}))
const PokerNgr = ({
    idx,
    idxRules,
    idxCondition,
    register
}) => {
    const classes = useStyles();

    return (
        <label htmlFor={`ruleGroup[${idx}].rules[${idxRules}].conditions[${idxCondition}].rule.min_poker_ngr`} className={clsx(classes.wrapLabel, classes.beginBonusLabel)}>
            <span className={classes.helper}>Min poker NGR</span>
            <input
                {...register(`ruleGroup[${idx}].rules[${idxRules}].conditions[${idxCondition}].rule.min_poker_ngr`)}
                type="text"
                id={`ruleGroup[${idx}].rules[${idxRules}].conditions[${idxCondition}].rule.min_poker_ngr`}
                placeholder='Введите min poker ngr'
                className={classes.text}
            />
        </label>
    )
}

export default PokerNgr;