import React from 'react';
import Title from '../../../../../../@casino/ui/title';
import Table from '../../../../table';
import { format } from 'date-fns';
import { useRouter } from '../../../../../../@casino/useRouter';
import { useDispatch, useSelector } from 'react-redux';
import { setGetRole } from '../../../../../store/roles/thunk/setGetRole';
import { handleGetRoleName } from './helper';
import { setGetRoleAction } from '../../../../../store/roles/action';

const SettingsRoles = () => {
  const router = useRouter();
  const dispatch = useDispatch();

  const columns = [{
    label: 'Дата создания',
    name: 'created_at',
    selector: (value) => format(new Date(Number(value)), 'dd.MM.yyyy')
  }, {
    label: 'Роль',
    name: 'name',
    align: 'left',
  }, {
    label: 'Правила',
    name: 'allows',
    align: 'left',
    // selector: (value) => handleGetRoleName(value)
  }];

  React.useEffect(() => {
    dispatch(setGetRole({
      page: 0,
      rowsPerPage: 10
    }))
  }, [])

  const role = useSelector(state => state.role.roles);

  const handleEventDoubleClick = (v) => {
    router.history.push(`/settings/roles/view/${v.id}`)
  }
  const handleChangeRowsPerPage = (rowsPerPage) => {
    dispatch(setGetRoleAction({
      data: [],
      currentPage: role?.currentPage,
      currentRowsPerpage: role?.currentRowsPerPage,
      total: role?.total,
      totalPage: role?.totalPage
    }))

    dispatch(setGetRole({
      page: 1,
      rowsPerPage
    }));
  }

  const handleChangePage = ({
    rowsPerPage,
    page
  }) => {
    dispatch(setGetRoleAction({
      data: [],
      currentPage: role?.currentPage,
      currentRowsPerpage: role?.currentRowsPerPage,
      total: role?.total,
      totalPage: role?.totalPage
    }))
    dispatch(setGetRole({
      page,
      rowsPerPage
    }));
  }


  return (
    <div style={{
      padding: '0 20px'
    }}>
      <Title
       label="Роли"
       labelBtn="Добавить"
       handleClick={() => router.history.push('/settings/roles/create')} 
      />

      <div style={{
        display: 'flex',
        flexDirection: 'column',
        padding: 20,
        background: '#1b2039',
        borderRadius: 8,
      }}>
        <Table
          columns={columns}
          data={role?.data}
          handleEventDoubleClick={handleEventDoubleClick}
          totalPage={role?.total}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleChangePage={handleChangePage}
        />
      </div>
    </div>
  )
}

export default SettingsRoles;