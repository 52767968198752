import React from 'react';
import {useFormik, FormikContext} from "formik";
import { useDispatch } from 'react-redux';
import {setPostAuthorization} from '../../store/authorization/thunk/setAuthorization';
import {useStyles, initialValues} from './helpers';

const Authorization = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const onSubmit = (store) => {
        dispatch(setPostAuthorization({
            login: store.login,
            password: store.password
        }))        
    }

    const formik = useFormik({onSubmit, initialValues});

    return (
        <div className={classes.rootrootWrap}>
            <div class="Input__container">
            <FormikContext.Provider value={formik}>
                <form onSubmit={formik.handleSubmit} className={classes.root} >
                    <h3 className={classes.title}>Вход</h3>
                    
                    <label htmlFor="login" className={classes.labelRoot}>
                        <span className={classes.labelText}>Логин</span>
                        <input 
                            type="text" 
                            className={classes.inp} 
                            name="login" 
                            id="login"  
                            value={formik.values.login} 
                            onChange={(v) => formik.setFieldValue('login', v.target.value)}
                        />
                    </label>
                    <label htmlFor="password" className={classes.labelRoot}>
                        <span className={classes.labelText}>Пароль</span>
                        <input 
                            type="password" 
                            className={classes.inp} 
                            name="password" 
                            id="password" 
                            value={formik.values.password} 
                            onChange={(v) => formik.setFieldValue('password', v.target.value)}
                        />
                    </label>
                    <button className={classes.btn}>ВОЙТИ</button>
                </form>
            </FormikContext.Provider>
                
            </div>
        </div>
    )
}

export default Authorization;