import React from 'react';
import Title from '../../../../../../@casino/ui/title';
import { useRouter } from '../../../../../../@casino/useRouter';
import { useDispatch, useSelector } from 'react-redux';
import { setGetPartnersById } from '../../../../../store/partners/thunk/setGetPartnersById';
import Label from '../../../finance/payments/view/block/label';
import { useForm } from 'react-hook-form';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import { setPostPartnerBalanceCorrection } from '../../../../../store/partners/thunk/setPostPartnerBalanceCorrection';

const useStyles = makeStyles(() => ({
    helper: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      position: 'relative',
      display: 'inline-block',
      fontSize: '11px',
      color: 'hsla(0,0%,100%,.7)',
      verticalAlign: 'middle',
      fontFamily: "PT Sans, sans-serif",
      userSelect: 'none'
    },
    text: {
      position: 'relative',
      borderRadius: '4px',
      height: '32px',
      overflow: 'hidden',
      boxShadow: '0 1px 0 0 hsl(0deg 0% 100% / 15%), inset 0 1px 0 0 rgb(0 0 0 / 15%)',
      border: 0,
      background: '#1c2542',
      color: '#fff',
      outline: 'none',
      paddingLeft: 8,
    },
    wrapLabel: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 12,
      marginBottom: 24,
    },
    beginBonusLabel: {
      width: '32%',
    },
  }))
  

const CorrectionBalance = () => {
    const router = useRouter();
    const dispatch = useDispatch();
    const classes = useStyles();

    const { handleSubmit, register, control, reset } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: {
          name: "",
          currency: "USD"
        }
      });

    React.useEffect(() => {
        dispatch(setGetPartnersById({
            id: router.query.id
        }))
    }, []);

    const view = useSelector((state) => state.partners.view);
    
    const onSubmit = (store) => {
        const params = {
            ...store,
            id: view.user_id
        }
        dispatch(setPostPartnerBalanceCorrection(params, () => {
            reset({
                amount: "",
                reason: "",
                action: "",
                currency: "USD"
            })
            dispatch(setGetPartnersById({
                id: view.user_id
            }))
        }))
    }

    return (
        <div style={{
            padding: '0 20px'
        }}>
            <Title
                label="Коррекция баланса партнера"
                labelBtn="Назад"
                handleClick={() => router.history.push(`/partners/view/${view.user_id}`)}
            />
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                padding: 20,
                background: '#1b2039',
                borderRadius: 8,
                marginBottom: 12
            }}>
                <span style={{
                    fontWeight: 700,
                    fontSize: 24,
                    color: '#fff'
                }}>Финансовые показатели</span>
                <div>
                    <Label
                        label="Баланс"
                        description={view.balance}
                    />
                    <Label
                        label="Баланс HOLD"
                        description={view.balance_hold}
                    />
                </div>
            </div>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                padding: 20,
                background: '#1b2039',
                borderRadius: 8,
            }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div style={{display: 'flex', alignItems: 'flex-end', columnGap: 12}}>
                        <label htmlFor="name" className={clsx(classes.wrapLabel, classes.beginBonusLabel)}>
                            <span className={classes.helper}>Сумма</span>
                            <input
                                {...register('amount')}
                                type="text"
                                id="name"
                                placeholder='Введите Сумму'
                                className={classes.text}
                            />
                        </label>
                        <label htmlFor="currency" className={clsx(classes.wrapLabel, classes.beginBonusLabel)}>
                            <span className={classes.helper}>Валюта</span>
                            <select
                                {...register('currency')}
                                style={{ height: '34px', width: '32%' }}
                                name="currency"
                                className={classes.text}
                                disabled={true}
                            >
                                <option value="USD" style={{ display: 'none' }}>USD</option>
                            </select>
                        </label>
                    </div>
                    
                    <div style={{ display: 'flex', flexDirection: 'column', rowGap: 25 }}>
                        <select
                            {...register('action')}
                            style={{ height: '34px', width: '32%' }}
                            className={classes.text}
                        >
                            <option value="" style={{ display: 'none' }}>Выбрать действие</option>
                            <option value="add">Добавить</option>
                            <option value="subtract">Списать</option>
                        </select>
                    </div>

                    <label htmlFor="reason" className={clsx(classes.wrapLabel, classes.beginBonusLabel)}>
                        <span className={classes.helper}>Причина</span>
                        <textarea
                        {...register('reason')}
                        type="text"
                        id="reason"
                        className={classes.text}
                        style={{width: 500, height: 200}}
                        />
                    </label>

                    <button
                        type="submit"
                        style={{
                            color: '#141b33',
                            cursor: 'pointer',
                            padding: '10px',
                            background: '#ffc324',
                            borderRadius: '4px',
                            border: 'none',
                            outline: 'none'
                        }}
                    >
                        Сохранить
                    </button>
                </form>
            </div>
        </div>
    )
}

export default CorrectionBalance;