import React from 'react';
import Title from '../../../../../@casino/ui/title';
import { useDispatch, useSelector } from 'react-redux';
import { setGetPlayers } from '../../../../store/players/thunk/setGetPlayers';
import { useRouter } from '../../../../../@casino/useRouter';
import Table from '../../../table';
import { format } from 'date-fns';
import { setGetPlayersAction } from '../../../../store/players/action';

const Players = () => {
  const dispatch = useDispatch();
  const router = useRouter();

  React.useEffect(() => {
    dispatch(setGetPlayers({
      page: 0,
      rowsPerPage: 10
    }))
  }, []);

  const players = useSelector((state) => state.players.list);

  const columns = [{
    label: 'Дата создания',
    name: 'created_at',
    selector: (value) => format(new Date(Number(value)), 'dd.MM.yyyy')
  }, {
    label: 'ID на платформе',
    name: 'platform_id',
    align: 'left',
  }, {
    label: 'Валюта',
    name: 'currency',
    align: 'left',
  }, {
    label: 'Кол-во депозитов',
    name: 'count_deposit',
    align: 'left',
  }, {
    label: 'Сумма депозитов',
    name: 'sum_deposit',
    align: 'left',
  }, {
    label: 'Кол-во выводов',
    name: 'count_withdrawals',
    align: 'left',
  }, {
    label: 'Сумма выводов',
    name: 'sum_withdrawals',
    align: 'left',
  }];

  return (
    <div style={{
      padding: '0 20px'
    }}>
      <Title label="Игроки" />
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        padding: 20,
        background: '#1b2039',
        borderRadius: 8,
      }}>
        <Table
          columns={columns}
          data={players.data}
          handleEventDoubleClick={(v) => {
            router.history.push(`/players/view/${v.id}`)
          }}
          totalPage={players?.total}
          handleChangeRowsPerPage={(rowsPerPage) => {
            dispatch(setGetPlayersAction({
              data: [],
              currentPage: 0,
              currentRowsPerpage: rowsPerPage,
              total: players?.total,
              totalPage: players?.totalPage
            }))
            dispatch(setGetPlayers({
              page: 0,
              rowsPerPage
            }));
          }}
          handleChangePage={({
            rowsPerPage,
            page
          }) => {
            dispatch(setGetPlayersAction({
              data: [],
              currentPage: page,
              currentRowsPerpage: rowsPerPage,
              total: players?.total,
              totalPage: players?.totalPage
            }))
            dispatch(setGetPlayers({
              page,
              rowsPerPage
            }));
          }}
        />
      </div>
    </div>
  )
}

export default Players;