import { isFunction } from 'lodash';
import { getPromoCheckById } from '../api/getPromoCheckById';
import { setGetPromoById } from './setGetPromoById';

export const setGetPromoCheckById = (params, callback) => {
    return async (dispatch, getState) => {
        try {
            await getPromoCheckById(params);
            dispatch(setGetPromoById(params, callback));
        } catch(error) {
            if (isFunction(callback)) {
                callback();
            }
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}