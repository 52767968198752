import { setGetRoleByIdAction } from '../action';
import { getRoleById } from '../api/getRoleById';

export const setGetRoleById = (params, callback) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await getRoleById(params);
            dispatch(setGetRoleByIdAction(data.data))

            if (callback) {
                callback();
            }
        } catch(error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}