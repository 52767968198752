import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
    rootrootWrap: {
        background: '#141b33', 
        height: '100vh', 
        width: '100%', 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
    },
    root: {
        width: 300,
        border: '1px solid rgba(231, 231, 231, .1)',
        borderRadius: 4,
        padding: 12,
        background: 'rgba(248, 248, 248, .1)',
        marginTop: 70,
    },
    inp: {
        border: '1px solid #d3d3d3', 
        fontFamily: 'Calibri, sans-serif', 
        borderRadius: 4,
        outline: 'none',
        paddingLeft: 5
    },
    labelRoot: {
        display: 'flex', 
        flexDirection: 'column',
    },
    labelText: {
        padding: 0, 
        margin: 0, 
        fontFamily: 'Calibri, sans-serif', 
        fontSize: '13px', 
        color: '#7f7f7f',
        marginTop: 8
    },
    title: {
        margin: 0, 
        padding: 0,
        textAlign: 'center', 
        fontFamily: 'Calibri, sans-serif', 
        color: '#fff',
        userSelect: 'none',
        cursor: 'pointer'
    },
    btn: {
        fontWeight: 700,
        cursor: 'pointer',
        marginTop: 12, 
        borderRadius: 12,
        width: '100%', 
        fontSize: 14,
        color: '#24263e',
        background: '#ffce01',
        border: '1px solid #ffce01',
        outline: 'none',
    }
}));

const initialValues = {
    login: '',
    password: '',
    tab: 'input'
}

export {
    useStyles,
    initialValues
}