import { types } from './types';

export const setGetCampaignAction = (payload) => {
  return {
    type: types.SET_GET_CAMPAIGN,
    payload,
  }
}

export const setGetCampaignByIdAction = (payload) => {
  return {
    type: types.SET_GET_CAMPAIGN_BY_ID,
    payload,
  }
}