import React from 'react';
import ModifierItem from './modifierItem';
import { useFieldArray } from 'react-hook-form';
import { ReactComponent as Plus } from '../../icon/plus.svg';
import { ReactComponent as Minus } from '../../icon/minus.svg';

const Modifier = ({
    idx,
    idxRules,
    idxReward,
    register,
    control,
}) => {
    const {fields, append, remove} = useFieldArray({
        control,
        name: `ruleGroup[${idx}].rules[${idxRules}].rewards[${idxReward}].modifiers`
    })

    const handleAddModificator = () => {
        append({
            type: ""
        });
    }

    return (
        <div style={{ paddingLeft: 20, marginTop: 10 }}>
            <div style={{ display: 'flex', columnGap: 10, alignItems: 'center' }}>
                <span style={{ color: '#fff' }}>Модификаторы</span>
                <Plus style={{ fill: '#fff', width: 20, height: 20, cursor: 'pointer' }} onClick={handleAddModificator} />
            </div>
            {
                fields?.map((modifier, idxModifier) => (
                    <div style={{marginTop: 20}}>
                        <div style={{display: 'flex', alignItems: 'center', columnGap: 10}}>
                            <span style={{ color: '#fff' }}>#{idxModifier + 1}</span>
                            <Minus style={{ fill: '#fff', width: 20, height: 20, cursor: 'pointer' }} onClick={() => remove(idxModifier)}/>
                        </div>
                        <ModifierItem
                            idx={idx}
                            idxRules={idxRules}
                            idxReward={idxReward}
                            idxModifier={idxModifier}
                            register={register}
                            control={control}
                        />
                    </div>
                ))
            }
        </div>
    )
}

export default Modifier;