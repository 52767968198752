import {types} from './types';

const initialState = {
    visite: {},
    replenishment: {
        action: [],
        total: []
    },
    withdrawals: {
        total_sum: []
    }
}

export function dashboardReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_GET_DASHBOARD_VISITE:
            return { ...state, visite: action.payload};
        case types.SET_GET_DASHBOARD_REPLENISHMENT:
            return { ...state, replenishment: action.payload };
        case types.SET_GET_DASHBOARD_WITHDRAWALS:
            return { ...state, withdrawals: action.payload };
        default:
            return state
    }
}