import React from 'react';
import Condition from '../condition';
import Qualifiers from '../qualifiers';
import Rewards from '../rewards';

const Rules = ({
    idx,
    idxRules,
    register,
    control,
    data
}) => {
    return (
        <div style={{display: 'flex', flexDirection: 'column', rowGap: 20}}>
            <Condition
                data={data}
                idx={idx}
                idxRules={idxRules}
                register={register}
                control={control}
            />
            <Qualifiers
                data={data}
                idx={idx}
                idxRules={idxRules}
                register={register}
                control={control}
            />
            <Rewards
                data={data}
                idx={idx}
                idxRules={idxRules}
                register={register}
                control={control}
            />
        </div>
    )
}

export default Rules