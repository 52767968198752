import React from 'react';
import Navigation from './navigation';
import Routes from './routes';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import Authorization from './authorization';
import { Redirect } from "react-router-dom";
import { useRouter } from '../../@casino/useRouter';
import Logo from '../../@casino/img/logo3.jpg';
import { useStyles } from './helper';
import { Route, Switch } from 'react-router-dom';
import { setGetProfile } from '../store/authorization/thunk/setGetProfile';

const Desktop = () => {
    const router = useRouter();
    const classes = useStyles();
    const dispatch = useDispatch();

    const token = localStorage.getItem('token');

    axios.defaults.headers.common['Authorization'] = token;

    const isAuth = !!token;

    React.useEffect(() => {
        if (token) {
            dispatch(setGetProfile());
        }
    }, [token]);
    
    const allows = useSelector(state => state.auth.allows);

    return (
        <div className={classes.root}>
            {
                isAuth && router.pathname === '/' &&
                <Redirect to='/dashboard' />
            }
            {
                !isAuth &&
                <>

                    <Switch>
                        <Route component={Authorization} />
                    </Switch>
                </>
            }
            {
                isAuth &&
                <>
                    <div className={classes.rootAuth}>
                        <div className={classes.wrapAuth}>
                            <img src={Logo} className={classes.logo} alt="logo" />
                        </div>
                        <span
                            className={classes.exit}
                            onClick={() => {
                                localStorage.removeItem('token');
                                window.location.replace(window.location.origin)
                            }}>Выход</span>
                    </div>
                    <div className={classes.body}>
                        <div className={classes.navigation} style={{ overflow: 'scroll' }}>
                            <Navigation allows={allows} />
                        </div>
                        <div className={classes.routes}>
                            <Routes allows={allows} />
                        </div>
                    </div>
                </>
            }

        </div>
    )
}

export default Desktop;