import React from 'react';
import Title from '../../../../../../@casino/ui/title';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from '../../../../../../@casino/useRouter';
import { setGetBonusCodeById } from '../../../../../store/bonuscode/thunk/setGetBonusCodeById';
import Label from './block/label';
import { format } from 'date-fns';
import { Typography } from '@material-ui/core';

const PartnersBonusCode = () => {
  const dispatch = useDispatch();
  const router = useRouter();

  React.useEffect(() => {
    dispatch(setGetBonusCodeById({
      id: router.query.id
    }))
  }, []);

  const view = useSelector((state) => state.bonuscode.view);

  return (
    <div style={{
      padding: '0 20px'
    }}>
      <Title
        label="Просмотр бонус-кода" 
        labelBtn="Назад"
        handleClick={() => router.history.push('/partners/bonus-code/list')} 
      />
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        padding: 20,
        background: '#1b2039',
        borderRadius: 8,
        rowGap: 12
      }}>
        <Typography style={{ color: 'hsla(0,0%,100%,.8)', textTransform: 'uppercase', marginBottom: 20 }}>Информация</Typography>
      
        <Label
          label="Статус"
          description={view?.is_active ? 'Активна' : 'Неактивна'}
        />
        <Label
          label="Дата создания"
          description={format(new Date(Number(view?.created_at || 0)), 'dd.MM.yyyy HH:mm')}
        />
        <Label
          label="Бонус код"
          description={view?.bonus_code}
        />
        <Label
          label="Промокод"
          description={view?.promocode}
        />

        <Label
          label="Партнер"
          description={view?.partner_id}
        />

        <Label
          label="Количество пользователей"
          description={view?.players_count}
        />

      </div>
    </div>
  )
}

export default PartnersBonusCode;