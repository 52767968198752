export const pathRoutes = {
    PARTNERS_LIST: '/partners/list',
    PARTNERS_VIEW: '/partners/view/:id',
    PARTNERS_CORRECTION_BALANCE: '/partners/correction/:id',

    PLAYERS_LIST: '/players/list',
    PLAYERS_VIEW: '/players/view/:id',

    OPERATORS_LIST: '/settings/operators/list',
    OPERATORS_CREATE: '/settings/operators/create',
    OPERATORS_VIEW: '/settings/operators/view/:id',
    
    ROLES_LIST: '/settings/roles/list',
    ROLES_CREATE: '/settings/roles/create',
    ROLES_VIEW: '/settings/roles/view/:id',

    BRANDS_LIST: '/affiliate/brands/list',
    BRANDS_VIEW: '/affiliate/brands/view/:id',
    BRANDS_CREATE: '/affiliate/brands/create',

    LANDINGS_LIST: '/affiliate/landings/list',
    LANDINGS_VIEW: '/affiliate/landings/view/:id',
    LANDINGS_CREATE: '/affiliate/landings/create',

    PAYMENTS_METHODS_LIST: '/finance/payments_methods/list',
    PAYMENTS_METHODS_VIEW: '/finance/payments_methods/view/:id',

    CAMPAIGNS_LIST: '/partners/campaigns/list',
    CAMPAIGNS_VIEW: '/partners/campaigns/view/:id',

    PROMO_LIST: '/partners/promo/list',
    PROMO_VIEW: '/partners/promo/view/:id',

    BONUS_CODE_LIST: '/partners/bonus-code/list',
    BONUS_CODE_VIEW: '/partners/bonus-code/view/:id',
    BONUS_CODE_CREATE: '/partners/bonus-code/create',

    MEDIA_LIST: '/affiliate/media/list',
    MEDIA_VIEW: '/affiliate/media/view/:id',
    MEDIA_CREATE: '/affiliate/media/create',

    INVITE_AFFILATES_LIST: '/affiliate/invite_affilates/list',
    INVITE_AFFILATES_CREATE: '/affiliate/invite_affilates/create',
    INVITE_AFFILATES_VIEW: '/affiliate/invite_affilates/view/:id',

    EVENTS_LIST: '/settings/events/list',

    COMISSION_LIST: '/affiliate/comission/list',
    COMISSION_VIEW: '/affiliate/comission/view/:id',
    COMISSION_CREATE: '/affiliate/comission/create',

    BILLS_LIST: '/finance/bills/list',
    BILLS_VIEW: '/finance/bills/view/:id',

    PAYMENTS_LIST: '/finance/payments/list',
    PAYMENTS_VIEW: '/finance/payments/view/:id',

    BALANCE_CORRENCTIONS_LIST: '/finance/balance_correnctions/list',
    BALANCE_CORRENCTIONS_VIEW: '/finance/balance_correnctions/view/:id',

    PROMO_LIST: '/promo/list',
    PROMO_TRANSACTION_VIEW: '/partners/promo/view/:promo_id/transaction/:transaction_id',

    EXCHANGE_RATES: '/finance/exchange_rates',

    POSTBACKS_LOG: '/partners/postbacks-log/list',

    DASHBOARD: '/dashboard',

    PARTNERS_CREATE_COMISSION: '/partners/view/:id/comission/create',
    

    PARTNERS_STATISTICS: '/report/partners-statistics',
    REQUESTED_REPORT: '/report/requested_report',
    TRAFFIC_REPORT: '/report/traffic_report',
}