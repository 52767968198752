import React from 'react';
import Title from '../../../../@casino/ui/title';
import InformationBlock from './informationBlock';
import { useDispatch, useSelector } from 'react-redux';
import { setGetDashboardVisite } from '../../../store/dashboard/thunk/setGetDashboardVisite';
import { setGetDashboardReplenishment } from '../../../store/dashboard/thunk/setGetDashboardReplenishment';
import { setGetDashboardWithdrawals } from '../../../store/dashboard/thunk/setGetDashboardWithdrawals';
import { Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  root: {
    padding: 20,
    color: '#fff',
    paddingTop: 0,
  },
  wrap: {
    padding: 20,
    background: '#1b2039',
    borderRadius: 8,
    marginBottom: 12
  },
  rootTable: {
    width: 'calc(100% - 30px)', borderCollapse: 'inherit', borderSpacing: 0,
    '.trTable': {

    }
  },
  trTable: {
    border: 'none',
    cursor: 'pointer',
    '&:hover': {
      background: 'rgba(255,255,255, 0.15)'
    }
  },
  title: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    height: '48px',
    lineHeight: '48px',
    fontSize: '20px',
    color: '#ffce01',
    fontWeight: 400,
    margin: 0,
    padding: 0
  },
  ggr: {
    display: 'inline-block',
    minWidth: '80px',
    border: '1px solid',
    borderRadius: '3px',
    fontSize: '30px',
    fontWeight: 500,
    padding: '4px 20px',
    margin: '0 4px',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    transition: 'box-shadow .5s linear,text-shadow .5s linear',
    color: '#00cd62',
    borderColor: '#00cd62',
  },
  wrapSwitch: {
    display: 'flex',
    alignItems: 'center',
    width: 165,
  },
  manually: {
    display: 'flex',
    alignItems: 'center',
    width: 165,
    color: '#fff'
  },
  spo: {
    width: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#141b33',
    color: '#fff',
    boxShadow: '4px 4px 8px 0px rgb(34 60 80 / 20%)',
  },
  datetime: {
    border: 'none',
    outline: 'none',
    paddingLeft: 15,
    borderRadius: 4,
    background: '#34488c',
    color: '#fff'
  },
  searchGamer: {
    padding: 10,
    background: '#141b33',
    paddingLeft: 10,
    border: '1px solid #141b33',
    color: '#fff',
    outline: 'none',
    margin: 10,
    width: '97.3%'
  },
  wrapBlock: {
    padding: 20,
    background: '#1b2039',
    borderRadius: 8,
    marginBottom: 12,
  },
}));

const Dashboard = () => {
  const date = new Date();
  date.setDate(1);
  date.setHours(9);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  const current_date = new Date()

  const dispatch = useDispatch();
  const classes = useStyles();
  const [search, setSearch] = React.useState("");
  const [dateFrom, setDateFrom] = React.useState(date.getTime());
  const [dateTo, setDateTo] = React.useState(current_date.getTime());
  const [datePartnerFrom, setDatePartnerFrom] = React.useState();
  const [datePartnerTo, setDatePartnerTo] = React.useState();

  React.useEffect(() => {
    dispatch(setGetDashboardVisite({
      search,
      dateTo,
      dateFrom,
      datePartnerTo,
      datePartnerFrom
    }));
    dispatch(setGetDashboardReplenishment({
      search,
      dateTo,
      dateFrom,
      datePartnerTo,
      datePartnerFrom
    }));
    dispatch(setGetDashboardWithdrawals({
      search,
      dateTo,
      dateFrom,
      datePartnerTo,
      datePartnerFrom
    }))
  }, [search, dateFrom, dateTo, datePartnerTo, datePartnerFrom])

  const visite = useSelector((state) => state.dashboard.visite);
  const replenishment = useSelector((state) => state.dashboard.replenishment);
  const withdrawals = useSelector((state) => state.dashboard.withdrawals);

  const forGroupData = [...replenishment.action, ...withdrawals.total_sum, ...replenishment.total.map((item) => ({ ...item, action: 'total' }))];

  const formatted = Object.entries(forGroupData.reduce((acc, item) => {
    const currency = item.currency;
    if (!acc[currency]) {
      acc[currency] = [];
    }
    acc[currency].push(item);
    return acc;
  }, {})).map(([currency, items]) => ({ currency, items }));

  const getDateForm = (value) => {
    const date = new Date(moment.utc(+value));
    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    const month = +date.getMonth() + 1 < 10 ? '0' + (+date.getMonth() + 1) : date.getMonth() + 1;
    const year = date.getFullYear();
    const hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
    const min = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();

    return `${year}-${month}-${day}T${hours}:${min}`
  }

  return (
    <div style={{
      padding: '0 20px'
    }}>
      <Title label="Информационная панель" />

      <div className={classes.wrapBlock} >
        <div>
          <Typography style={{color: '#fff'}}>Переодичность</Typography>
        </div>
        <div style={{ display: 'flex' }}>
          <span className={classes.spo}>С</span>
          <input
            type={'datetime-local'}
            className={classes.datetime}
            style={{ height: 40 }}
            value={getDateForm(dateFrom)}
            onChange={(v) => {
              const date = new Date(v.target.value);
              setDateFrom(date.getTime());
            }}
          />
          <span className={classes.spo}>ПО</span>
          <input
            type={'datetime-local'}
            style={{ border: 'none', outline: 'none', paddingLeft: 15, borderRadius: 4, background: '#34488c', color: '#fff' }}
            value={getDateForm(dateTo)}
            onChange={(v) => {
              const date = new Date(v.target.value);
              setDateTo(date.getTime());
            }}
          />
        </div>
      </div>
      <div className={classes.wrapBlock} >
        <div>
          <Typography style={{color: '#fff'}}>Переодичность по FD</Typography>
        </div>
        <div style={{ display: 'flex' }}>
          <span className={classes.spo}>С</span>
          <input
            type={'datetime-local'}
            className={classes.datetime}
            style={{ height: 40 }}
            value={getDateForm(datePartnerFrom)}
            onChange={(v) => {
              const date = new Date(v.target.value);
              setDatePartnerFrom(date.getTime());
            }}
          />
          <span className={classes.spo}>ПО</span>
          <input
            type={'datetime-local'}
            style={{ border: 'none', outline: 'none', paddingLeft: 15, borderRadius: 4, background: '#34488c', color: '#fff' }}
            value={getDateForm(datePartnerTo)}
            onChange={(v) => {
              const date = new Date(v.target.value);
              setDatePartnerTo(date.getTime());
            }}
          />
        </div>
      </div>
      <div className={classes.wrapBlock} style={{ padding: 0 }}>
        <label htmlFor="search">
          <input
            type="text"
            name="search"
            id="search"
            value={search}
            onChange={(v) => setSearch(v.target.value)}
            className={classes.searchGamer}
            placeholder="Поиск партнера по логину"
          />
        </label>
      </div>

      <div className={classes.wrapBlock} style={{ display: 'flex', flexDirection: 'column', gap: 10, flexWrap: 'wrap' }}>
        <div>
          <Typography style={{ color: '#fff' }}>Информация о количество</Typography>
          <div style={{ display: 'flex', gap: 10 }}>
            <InformationBlock
              label="Визиты"
              width="170px"
              lineColor="#3cde0b">
              <span style={{
                fontWeight: 400,
                userSelect: 'none',
                color: '#FFFFFF'
              }}>{new Intl.NumberFormat('ru-RU').format(visite.visite)}</span>
            </InformationBlock>
            <InformationBlock
              label="Уникальные визиты"
              width="170px"
              lineColor="#3498db">
              <span style={{
                fontWeight: 400,
                userSelect: 'none',
                color: '#FFFFFF'
              }}>{new Intl.NumberFormat('ru-RU').format(visite.unique_visite)}</span>
            </InformationBlock>
            <InformationBlock
              label="Регистрации"
              width="170px"
              lineColor="#2ecc71">
              <span style={{
                fontWeight: 400,
                userSelect: 'none',
                color: '#FFFFFF'
              }}>{new Intl.NumberFormat('ru-RU').format(visite.registration)}</span>
            </InformationBlock>
            <InformationBlock
              label="Кол-во FD"
              width="170px"
              lineColor="#e74c3c">
              <span style={{
                fontWeight: 400,
                userSelect: 'none',
                color: '#FFFFFF'
              }}>{new Intl.NumberFormat('ru-RU').format(replenishment?.count_fd)}</span>
            </InformationBlock>
            <InformationBlock
              label="Кол-во RD"
              width="170px"
              lineColor="#f39c12">
              <span style={{
                fontWeight: 400,
                userSelect: 'none',
                color: '#FFFFFF'
              }}>{new Intl.NumberFormat('ru-RU').format(replenishment?.count_rd)}</span>
            </InformationBlock>
          </div>
        </div>


        <div style={{
          display: 'flex', flexDirection: 'column'
        }}>


          {
            formatted.map((item) => {

              const replenishment = item?.items?.find((data) => data.action === 'total')?.sum || 0;
              const withdrawals = item?.items?.find((data) => data.action === 'withdrawals')?.sum || 0;

              return (
                <div>
                  <Typography style={{ color: '#fff' }}>{item.currency}</Typography>
                  <div style={{ display: 'flex', gap: 10 }}>
                    {
                      item.items.map((data) => (
                        <>
                          {
                            data.action === 'fd' && (
                              <InformationBlock
                                label={`Сумма FD - ${item.currency}`}
                                width="170px"
                                lineColor="#9b59b6">
                                <span style={{
                                  fontWeight: 400,
                                  userSelect: 'none',
                                  color: '#FFFFFF'
                                }}>{new Intl.NumberFormat('ru-RU').format(data?.sum)}</span>
                              </InformationBlock>
                            )
                          }
                          {
                            data.action === 'rd' && (
                              <InformationBlock
                                label={`Сумма RD - ${item.currency}`}
                                width="170px"
                                lineColor="#1abc9c">
                                <span style={{
                                  fontWeight: 400,
                                  userSelect: 'none',
                                  color: '#FFFFFF'
                                }}>{new Intl.NumberFormat('ru-RU').format(data?.sum)}</span>
                              </InformationBlock>
                            )
                          }
                          {
                            data.action === 'total' && (
                              <InformationBlock
                                label={`FD+RD - ${item.currency}`}
                                width="170px"
                                lineColor="#1abc9c">
                                <span style={{
                                  fontWeight: 400,
                                  userSelect: 'none',
                                  color: '#FFFFFF'
                                }}>{new Intl.NumberFormat('ru-RU').format(data?.sum)}</span>
                              </InformationBlock>
                            )
                          }
                          {
                            data.action === 'withdrawals' && (
                              <InformationBlock
                                label={`Сумма выводов - ${item.currency}`}
                                width="170px"
                                lineColor="#27ae60">
                                <span style={{
                                  fontWeight: 400,
                                  userSelect: 'none',
                                  color: '#FFFFFF'
                                }}>{new Intl.NumberFormat('ru-RU').format(data.sum)}</span>
                              </InformationBlock>
                            )
                          }


                        </>
                      ))
                    }
                    <InformationBlock
                      label={`Сумма GGR - ${item.currency}`}
                      width="170px"
                      lineColor="#34495e">
                      <span style={{
                        fontWeight: 400,
                        userSelect: 'none',
                        color: '#FFFFFF'
                      }}>{new Intl.NumberFormat('ru-RU').format((replenishment - withdrawals) || 0)}</span>
                    </InformationBlock>
                  </div>
                </div>
              )
            }
            )
          }
        </div>

        {/* <InformationBlock
          label="Сумма NGR"
          width="170px"
          lineColor="#d35400">
          <span style={{
            fontWeight: 400,
            userSelect: 'none',
            color: '#FFFFFF'
          }}>10</span>
        </InformationBlock> */}
        {/* <InformationBlock
          label="Сумма бонусов"
          width="170px"
          lineColor="#ecf0f1">
          <span style={{
            fontWeight: 400,
            userSelect: 'none',
            color: '#FFFFFF'
          }}>10</span>
        </InformationBlock> */}

      </div>
    </div>
  )
}

export default Dashboard;