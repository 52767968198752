import React from 'react';
import FirstDeposit from '../first_deposit';
import OmitDublicates from '../omit_dublicates';
import OmitUnverifiedPlayers from '../omit_unverified_players';
import WithDeposits from '../with_deposits';
import PeriodSinceRegistration from '../period_since_registration';
import OmitDisabledPlayers from '../omit_disabled_players';
import { Typography } from '@material-ui/core';
import { ReactComponent as ArrowRight } from '../../../../../../../@casino/img/right-arrow.svg';
import { ReactComponent as ArrowDown } from '../../../../../../../@casino/img/down-arrow.svg';
import DepositsCount from '../deposits_count';
import DepositsSum from '../deposits_sum';
import EffectiveDepositsSum from '../effective_deposits_sum';

const Layout = (item) => {
    const [isOpenModifier, setIsOpenModifier] = React.useState(false);
    const [isOpenRule, setIsOpenRule] = React.useState(false)
    return (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', rowGap: 20 }}>
            {
                item.rule?.type === 'first_deposit' && <FirstDeposit {...item} isOpenRule={isOpenRule} setIsOpenRule={setIsOpenRule} />
            }
            {
                item.rule?.type === 'deposits_count' && <DepositsCount {...item} isOpenRule={isOpenRule} setIsOpenRule={setIsOpenRule} />
            }
            {
                item.rule?.type === 'deposits_sum' && <DepositsSum {...item} isOpenRule={isOpenRule} setIsOpenRule={setIsOpenRule} />
            }
            {
                item.rule?.type === 'effective_deposits_sum' && <EffectiveDepositsSum {...item} isOpenRule={isOpenRule} setIsOpenRule={setIsOpenRule} />
            }
            {
                isOpenRule && (
                    <div style={{ marginLeft: 12, width: '100%' }}>
                        <div>
                            <div style={{ display: 'flex', alignItems: 'center', columnGap: 12 }}>
                                <Typography style={{ color: 'hsla(0,0%,100%,.8)', textTransform: 'uppercase', fontSize: 14 }}>Модификаторы</Typography>
                                {
                                    isOpenModifier && <ArrowDown style={{ height: 12, width: 12, fill: '#FFF', cursor: 'pointer' }} onClick={() => setIsOpenModifier(false)} />
                                }
                                {
                                    !isOpenModifier && <ArrowRight style={{ height: 12, width: 12, fill: '#FFF', cursor: 'pointer' }} onClick={() => setIsOpenModifier(true)} />
                                }
                            </div>

                            {
                                isOpenModifier && (
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        rowGap: 20,
                                        marginTop: 20,
                                        width: '100%'
                                    }}>
                                        {
                                            item.modifiers.map((modifier) => (
                                                <div style={{
                                                    margin: '0 0 0 12px',
                                                    width: '100%'
                                                }}>
                                                    {
                                                        modifier.type === 'omit_duplicates' && <OmitDublicates {...modifier} />
                                                    }
                                                    {
                                                        modifier.type === 'omit_disabled_players' && <OmitDisabledPlayers {...modifier} />
                                                    }
                                                    {
                                                        modifier.type === 'omit_unverified_players' && <OmitUnverifiedPlayers {...modifier} />
                                                    }
                                                    {
                                                        modifier.type === 'with_deposits' && <WithDeposits {...modifier} />
                                                    }
                                                    {
                                                        modifier.type === 'period_since_registration' && <PeriodSinceRegistration {...modifier} />
                                                    }
                                                </div>
                                            ))
                                        }
                                    </div>
                                )
                            }
                        </div>
                    </div>
                )
            }

        </div>
    )
}

export default Layout;