import React from 'react';
import Title from '../../../../../../@casino/ui/title';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from '../../../../../../@casino/useRouter';
import Table from '../../../../table';
import { setGetBalanceCorrection } from '../../../../../store/balance-correction/thunk/setGetBalanceCorrection';
import { setGetBalanceCorrectionAction } from '../../../../../store/balance-correction/action';
import { Typography } from 'antd';

const FinanceBalanceCorrenction = () => {
  const dispatch = useDispatch();
  const router = useRouter();

  React.useEffect(() => {
    dispatch(setGetBalanceCorrection({
      page: 0,
      rowsPerPage: 10,
    }))
  }, []);

  const handleGetAction = (action) => {
    const value = action.toString().toLowerCase();
    switch(value) {
      case "add": return "Добавление";
      case "subtract": return "Списание";
    }
  }

  const columns = [{
    label: 'Дата создания',
    name: 'created_at',
    selector: (value) => format(new Date(Number(value)), 'dd.MM.yyyy')
  }, {
    label: 'Партнер',
    name: 'partner_id',
    align: 'left',
    multiple: true,
    selector: (value) => <Typography
      style={{ cursor: 'pointer', textDecoration: 'underline', color: '#fff' }}
      onClick={() => router.history.push(`/partners/view/${value.partner_id}`)}>{value.partner_login}</Typography>
  }, {
    label: 'Действие',
    name: 'action',
    align: 'left',
    selector: handleGetAction
  }, {
    label: 'Сумма',
    name: 'amount',
    align: 'left',
  }, {
    label: 'Валюта',
    name: 'currency',
    align: 'left',
  }, {
    label: 'Причина',
    name: 'reason',
    align: 'left',
  }, {
    label: 'Оператор',
    name: 'operator_id',
    align: 'left',
    multiple: true,
    selector: (value) => (
      <Typography
        style={{ cursor: 'pointer', textDecoration: 'underline', color: '#fff' }}
        onClick={() => router.history.push(`/settings/operators/view/${value.operator_id}`)}>
          {value.operator_login}
      </Typography>
    )
  }];

  const list = useSelector((state) => state.balanceCorrection.list);

  return (
    <div style={{
      padding: '0 20px'
    }}>
      <Title
        label="Коррекция балансов"
      />
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        padding: 20,
        background: '#1b2039',
        borderRadius: 8,
      }}>
        <Table
          columns={columns}
          data={list?.data || []}
          handleEventDoubleClick={(v) => {
            router.history.push(`/finance/balance_correnctions/view/${v.correction_id}`)
          }}
          totalPage={list?.total}
          handleChangeRowsPerPage={(rowsPerPage) => {
            dispatch(setGetBalanceCorrectionAction({
              data: [],
              currentPage: 0,
              currentRowsPerpage: rowsPerPage,
              total: list?.total,
              totalPage: list?.totalPage
            }))
            dispatch(setGetBalanceCorrection({
              page: 0,
              rowsPerPage
            }));
          }}
          handleChangePage={({
            rowsPerPage,
            page
          }) => {
            dispatch(setGetBalanceCorrectionAction({
              data: [],
              currentPage: page,
              currentRowsPerpage: rowsPerPage,
              total: list?.total,
              totalPage: list?.totalPage
            }))
            dispatch(setGetBalanceCorrection({
              page,
              rowsPerPage
            }));
          }}
        />
      </div>
    </div>
  )
}

export default FinanceBalanceCorrenction;