import { putOperators } from '../api/putOperators';

export const setPutOperators = (params) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await putOperators(params);

            alert('Сохранено')
          
        } catch(error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}