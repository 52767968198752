import React from 'react';
import Title from '../../../../../../@casino/ui/title';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from '../../../../../../@casino/useRouter';
import { format } from 'date-fns';
import Table from '../../../../table';
import { setGetMedia } from '../../../../../store/media/thunk/setGetMedia';

const AffiliateMedia = () => {
  const dispatch = useDispatch();
  const router = useRouter();

  React.useEffect(() => {
    dispatch(setGetMedia());
  }, [])

  const media = useSelector((state) => state.media.list);

  const columns = [{
    label: 'Дата создания',
    name: 'created_at',
    selector: (value) => format(new Date(Number(value)), 'dd.MM.yyyy HH:mm')
  }, {
    label: 'Предпросмотр',
    name: 'image',
    align: 'left',
    selector: (value) => <img src={value} style={{height: 60}} />
  }, {
    label: 'Название',
    name: 'title',
    align: 'left',
  }, {
    label: 'Страна',
    name: 'country',
    align: 'left',
  }, {
    label: 'Оффер',
    name: 'offer',
    align: 'left',
  }, {
    label: 'Статус',
    name: 'status',
    align: 'left',
    selector: (value) => value ? 'Активен' : 'Отключен'
  }]
  return (
    <div style={{
      padding: '0 20px'
    }}>
      <Title 
        label="Медиа"
        labelBtn="Добавить"
        handleClick={() => router.history.push('/affiliate/media/create')}       
      />
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        padding: 20,
        background: '#1b2039',
        borderRadius: 8,
      }}>
        <Table
          columns={columns}
          data={media.data}
          handleEventDoubleClick={(v) => {
            router.history.push(`/affiliate/media/view/${v.id}`)
          }}
        />
      </div>
    </div>
  )
}

export default AffiliateMedia;