import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'scroll'
    },
    arrowDown: {
        width: 12,
        height: 10,
        fill: '#fff',
        stroke: '#fff'
    },

    group: {
        height: 45,
        fontFamily: 'Open-Sans, sans-serif',
        userSelect: 'none',
        padding: '2px 10px',
        fontSize: 12,
        fontWeight: 700,
        color: '#fff',
        width: 'calc(100% - 24px)',
        cursor: 'pointer',
        transition: 'background .3s ease',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        fill: '#fff',
        '&:hover': {
            transition: 'background .3s ease',
            background: 'rgb(255, 255, 255, .03)',
            color: '#ffce01',
            fill: '#ffce01'
        }
    },
    itemGroup: {
        paddingLeft: 10
    },
    groupChildren: {
        paddingLeft: 30
    },
    wrapGroupFields: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
    },
    wrapField: {
        marginLeft: 20,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    wrapIcon: {
        minWidth: 25
    },
    icon: {
        width: 25,
        height: 25
    },
    pl12: {
        paddingLeft: 12
    },
    activeColor: {
        color: '#ffce01',
        fill: '#ffce01'
    }

}))

export {
    useStyles
}