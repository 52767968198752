import React from 'react';
import Title from '../../../../../../@casino/ui/title';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from '../../../../../../@casino/useRouter';
import { setGetBrands } from '../../../../../store/brands/thunk/setGetBrands';
import { format } from 'date-fns';
import Table from '../../../../table';
import { setGetBrandsAction } from '../../../../../store/brands/action';

const AffiliateBrands = () => {
  const dispatch = useDispatch();
  const router = useRouter();

  React.useEffect(() => {
    dispatch(setGetBrands({
      page: 0,
      rowsPerPage: 10
    }));
  }, [])

  const brands = useSelector((state) => state.brands.list);

  const columns = [{
    label: 'Дата создания',
    name: 'created_at',
    selector: (value) => format(new Date(Number(value)), 'dd.MM.yyyy')
  }, {
    label: 'Имя',
    name: 'name',
    align: 'left',
  }, {
    label: 'Url',
    name: 'url',
    align: 'left',
  }, {
    label: 'Активность',
    name: 'is_active',
    align: 'left',
    selector: (value) => value ? "Активный" : "Отключен"
  }]

  return (
    <div style={{
      padding: '0 20px'
    }}>
      <Title 
        label="Бренды" 
        labelBtn="Добавить"
        handleClick={() => router.history.push('/affiliate/brands/create')}   
      />

      <div style={{
        display: 'flex',
        flexDirection: 'column',
        padding: 20,
        background: '#1b2039',
        borderRadius: 8,
      }}>
        <Table
          columns={columns}
          data={brands.data}
          handleEventDoubleClick={(v) => {
            router.history.push(`/affiliate/brands/view/${v.id}`)
          }}
          totalPage={brands?.total}
          handleChangeRowsPerPage={(rowsPerPage) => {
            dispatch(setGetBrandsAction({
              data: [],
              currentPage: 0,
              currentRowsPerpage: rowsPerPage,
              total: brands?.total,
              totalPage: brands?.totalPage
            }))
            dispatch(setGetBrands({
              page: 0,
              rowsPerPage
            }));
          }}
          handleChangePage={({
            rowsPerPage,
            page
          }) => {
            dispatch(setGetBrandsAction({
              data: [],
              currentPage: page,
              currentRowsPerpage: rowsPerPage,
              total: brands?.total,
              totalPage: brands?.totalPage
            }))
            dispatch(setGetBrands({
              page,
              rowsPerPage
            }));
          }}
        />
      </div>
    </div>
  )
}

export default AffiliateBrands;