import React from 'react';
import Title from '../../../../../../@casino/ui/title';
import { useDispatch, useSelector } from 'react-redux';
import { setGetEvents } from '../../../../../store/events/thunk/setGetEvents';
import { format } from 'date-fns';
import Table from '../../../../table';
import { setGetEventsAction } from '../../../../../store/events/action';

const SettingsEvents = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(setGetEvents({
      page: 0,
      rowsPerPage: 10
    }));
  }, [])

  const events = useSelector(state => state.events.list);

  const columns = [{
    label: 'Дата создания',
    name: 'created_at',
    selector: (value) => format(new Date(Number(value)), 'dd.MM.yyyy')
  }, {
    label: 'Логин',
    name: 'login',
    align: 'left',
  }, {
    label: 'IP',
    name: 'ip',
    align: 'left',
  }, {
    label: 'Событие',
    name: 'event',
    align: 'left',
  }, {
    label: 'Путь',
    name: 'route',
    align: 'left',
  }, {
    label: 'JSON',
    name: 'description',
    align: 'left',
  }]


  return (
    <div style={{
      padding: '0 20px'
    }}>
      <Title label="События" />
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        padding: 20,
        background: '#1b2039',
        borderRadius: 8,
      }}>
        <Table
          columns={columns}
          data={events.data}
          totalPage={events?.total}
          handleChangeRowsPerPage={(rowsPerPage) => {
            dispatch(setGetEventsAction({
              data: [],
              currentPage: 0,
              currentRowsPerpage: rowsPerPage,
              total: events?.total,
              totalPage: events?.totalPage
            }))
            dispatch(setGetEvents({
              page: 0,
              rowsPerPage
            }));
          }}
          handleChangePage={({
            rowsPerPage,
            page
          }) => {
            dispatch(setGetEventsAction({
              data: [],
              currentPage: page,
              currentRowsPerpage: rowsPerPage,
              total: events?.total,
              totalPage: events?.totalPage
            }))
            dispatch(setGetEvents({
              page,
              rowsPerPage
            }));
          }}
        />
      </div>
    </div>
  )
}

export default SettingsEvents;